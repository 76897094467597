<template>
<section :class="{'use-map':warehouse !== null}">
    <b-overlay :show="showOverlay" rounded="lg" no-center="true">
        <template #overlay>
            <div class="text-center" style="margin-top: 300px;">
                <b-spinner v-if='csvUploadMessage === $t("csv_upload_loading")' style="width: 3rem; height: 3rem;margin-bottom:1rem" label="Large Spinner"></b-spinner>
                <p id="cancel-label">{{ csvUploadMessage }}</p>
                <b-button v-if='csvUploadMessage !== $t("csv_upload_loading")' class="btn green-rflocus" size="sm" @click="showOverlay = false">
                    OK
                </b-button>
            </div>
        </template>
        <b-form-group v-if="this.isGuest === 'true'" style="padding-top: 0.5rem" label-cols="10" label-cols-lg="1" label-size="sm" label="マップ:" label-for="warehouse">
            <b-form-select style="width:200px" id="warehouse" v-model="selected" :options="options" class="mb-3" size="sm" v-on:change="changeWarehouse" />
        </b-form-group>

        <floor-map-legacy :floormap="warehouse" mode="location_select" :location-id="selected_row!==null?selected_row.locationId:null" :initSelectLocations="initSelectLocations" :max-height="400" v-if="title === 'Stocks' && warehouse !== null" @selectlocation="selectLocation" @deselectall="selectLocation" :showZoom="true" :searchLocationSet="searchLocationSet" />
        <!-- Form Component -->
        <page-header :title="displayTitle" style="-webkit-user-select: none; /* Safari */">
            <app-form ref="appform" :title="title" :items="formItems" :working="working" :csvWorking="csvWorking" :deleteWorking="deleteWorking" @fetchSearch="submitFormSearch" :form_select_options="form_select_options" @fetchCsv="downloadCSV" @deleteStocksFun="deleteStocksFun" :location-override="locationOverride" :deleteStocks="deleteStocks && isGuest !== 'true'" />
        </page-header>

        <app-table v-if="!tableItems" :loading="working" :headers="fields" :sort="sortBy" :paginate="paginate" :elements="currentData" :title="title" :stockCount="stockCount" :value="count" @setModalItem="setModalItem" @setModalCreate="setModalCreate" @updatePaginateCount="updatePaginateCount" @sortChange="sortChange" @row-selected="selectRow" @un-row-selected="unSelectRow" :selectable="jobUseMap" :rowClickAble="functionId !== 6 && functionId !== 8" @showRobotPathFun="showRobotPathFun">

            <template v-slot:paginate>
                <paginate :paginate="paginate" v-model="page" @updatePage="updatePage">
                </paginate>
            </template>

            <template v-if="title !== 'Stocks' && functionId !== 6 && functionId !== 8" v-slot:button>
                <button-new @setModalCreate="setModalCreateNew" class="mr-1"></button-new>

                <b-button size="sm" :variant="working ? '' : 'primary'" pill :disabled="working">
                    <label class="upload_csv_label" for="file">
                        <i class="fa fa-cloud-upload m-r-5"></i>
                        <span>csvファイル一括登録</span>
                    </label>
                </b-button>
                <input :disabled="working" type="file" id="file" ref="file" accept=".csv, .xlsx, .xlsm" v-on:change="handleFileUpload()" hidden />
            </template>

        </app-table>

        <modal-detail-job v-else :infos="modalDetailInfos" :tenantId="tenantId" :title="title" :url="url" :form="form" :formItems="formItems" @backToMainPage="backToMainPage" @row-selected="selectRow" @un-row-selected="unSelectRow">
        </modal-detail-job>

        <modal-create-job :infos="modalCreateInfos" :headers="fields" :url="url" :tenantId="tenantId" @refetchData="fetchAll" :modalCreateJob="modalCreateJob" @hideModalCreateJob="hideModalCreateJob" :msgModal="msgModal" @changeMsgModal="changeMsgModal" :work_properties="work_properties">
        </modal-create-job>

        <b-modal title="Delete Stocks" v-model="modalAlertDeleteStocks" size="s" @ok="modalAlertDeleteStocksFun">
            <p>検索結果を一括出荷しても宜しいでしょうか？</p>
        </b-modal>
        <b-modal title="Robot Path" v-model="showRobotPathFlag" size="xl" @ok="showRobotPathFlag = false" @hidden="resetRobotPathModal">
            <floor-map-legacy :floormap="warehouse" mode="temi_mode" :location-id="selected_row!==null?selected_row.locationId:null" :initSelectLocations="initSelectLocations" v-if="warehouse !== null" @selectlocation="selectLocation" @deselectall="selectLocation" :showZoom="true" :max-width="1100" :max-height="600" :showRobotPathFlag="showRobotPathFlag" :currentJobId="currentJobId" :url="url" :showRobotPathBodyFlag="showRobotPathBodyFlag" />
        </b-modal>

    </b-overlay>

</section>
</template>

<script>
// Libraries
import {
    BModal
} from 'bootstrap-vue-next'

// Components
import PageHeader from '../layout/PageHeader.vue'
import AppTable from '../../components/table/TablePage.vue'
import Form from '../../components/form/Form.vue'
import ButtonNew from '../../components/buttons/ButtonNew.vue';
import Paginate from '../../components/ThePaginate.vue'
import {
    Warehouse
} from "../../components/modal/Warehouse";

import * as queries from "../../graphql/locus_queries";
import * as mutations from "../../graphql/locus_mutations";
import global from "../../Global.vue";

import ModalCreateJob from "../../components/modal/ModalCreateJob.vue";
import ModalDetailJob from "../../components/modal/ModalDetailJob.vue";
import AwsApi from "../../Api"
import {
    onUpdateProcessJob
} from "../../graphql/locus_subscriptions";

import {
    API,
    graphqlOperation,
    Storage
} from "aws-amplify";

export default {
    props: [
        'settings',
        'title',
        'translate',
        'modalItemId',
        'modalCreateId',
        'useMap',
    ],
    components: {
        PageHeader,
        AppTable,
        ButtonNew,
        Paginate,
        'app-form': Form,
        'b-modal': BModal,
        ModalCreateJob,
        ModalDetailJob
    },
    data() {
        return {
            jobUseMap: false,
            tenantId: "",
            functionId: 0,
            isGuest: false,
            menuConfigs: [],
            fields: [],
            url: null,
            allData: [],
            currentData: [],
            listLocations: [],
            listWarehouses: [],
            formItems: [],
            form_select_options: {},
            limit: 1000,
            offset: 0,
            paginate: {},
            page: 1,
            count: 30,
            modalCreateInfos: {},
            modalDetailInfos: {},
            sortBy: [],
            displayTitle: null,

            warehouse: null,
            selected_row: null,
            selecting: false,
            locationOverride: null,

            tableItems: false,
            reader: new FileReader(),
            initSelectLocations: [],
            isFirstShowPage: true,
            working: false,
            csvWorking: false,
            deleteWorking: false,
            stockCount: 0,
            updateCsvPath: "",
            selected: null,
            options: [],
            showOverlay: false,
            csvUploadMessage: this.$t("csv_upload_loading"),
            modalCreateJob: false,
            msgModal: false,
            work_properties: [],
            csvDetailOffset: 0,
            csvAllData: [],
            subscription: null,
            uploadCSVTimer: null,
            modalAlertDeleteStocks: false,
            deleteStocks: false,
            hasRobotLogFlag: false,
            showRobotPathFlag: false,
            showRobotPathBodyFlag: 0,
            currentJobId: null,
            isSearchFlag: false,
            searchLocationSet: new Set()
        }
    },
    async created() {
        this.functionId = parseInt(this.$route.params.functionId);
        this.tenantId = sessionStorage.getItem(global.tenantId);
        this.isGuest = sessionStorage.getItem(global.isGuest);
        this.jobUseMap = this.useMap
        await this.fetchAll();
    },
    watch: {
        // call again the method if the route changes
        '$route': 'routeChangeFetchAll',
    },
    mounted() {
        this.addSubscription()
        this.reader.addEventListener("load", function (event) {
            this.showOverlay = true
            this.csvUploadMessage = this.$t("csv_upload_loading")
            this.uploadCSV(this.reader.result).then((response) => {
                if (response === undefined) {
                    this.csvUploadMessage = this.$t("csv_upload_error")
                    return
                }
                var callApiTime = new Date().getTime()
                this.uploadCSVTimer = setInterval(() => {
                    if (new Date().getTime() - callApiTime >= global.timeout) {
                        clearInterval(this.uploadCSVTimer)
                        this.csvUploadMessage = this.$t("csv_upload_timeout")
                    }
                }, 1000)
                this.clearInput()
            })
        }.bind(this), false);
        if (this.isGuest === 'true') {
            this.fetchWarehouse()
        }

    },
    unmounted() {
        console.log("unsubscribe");
        if (this.subscription !== null) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        addSubscription() {
            console.log("subscription");
            this.subscription = API.graphql(graphqlOperation(onUpdateProcessJob)).subscribe({
                next: (eventData) => {
                    clearInterval(this.uploadCSVTimer)
                    if (eventData !== undefined && eventData.value !== undefined && eventData.value.data.onUpdateProcessJob !== null &&
                        eventData.value.data.onUpdateProcessJob.status === 1) {
                        if (this.$refs.appform) {
                            let f = this.$refs.appform.beforeSubmit()
                            this.defaultFormSearch({
                                form: f
                            });
                        }
                        this.csvUploadMessage = this.$t("csv_upload_success")
                    } else {
                        this.csvUploadMessage = this.$t("csv_upload_error")

                    }
                },
            });
        },
        async listWarehouse() {
            const response = await AwsApi.graphql({
                query: queries.listWarehouses,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);

            if (response === undefined) {
                return
            }
            this.listWarehouses = response.data.listWarehouses.items
        },
        async fetchWarehouse() {
            this.listWarehouses = []
            await this.listWarehouse()
            this.options = this.listWarehouses.map((item) => ({
                value: item.id,
                text: `${item.name}`,
            }));
            if (this.options !== undefined && this.options.length !== 0) {
                this.selected = this.options[0].value
                this.changeWarehouse(this.options[0].value);
            }
        },
        changeWarehouse(value) {
            this.$router.push(`${this.$route.path}?warehouse=${value}`).catch(error => error);
        },
        routeChangeFetchAll() {
            this.isFirstShowPage = true
            this.initSelectLocations = []
            this.locationOverride = undefined
            this.selected_row = null
            this.selecting = false
            this.page = 1
            this.fetchAll()
        },
        async fetchAll() {
            if (this.$route.query.warehouse === undefined) {
                return
            }
            this.setUrlData();
            await this.fetchMenuConfigs();
            await this.fetchWarehouseData();
            // search using the form defaults
            if (this.$refs.appform) {
                let f = this.$refs.appform.beforeSubmit()
                this.defaultFormSearch({
                    form: f
                });
            }
        },
        // ----- Settings

        selectLocation(location) {
            this.locationOverride = location !== undefined ? location.id : null
            this.selected_row = null
        },
        getType(sourceDefine) {
            // get the type
            if (sourceDefine === null || sourceDefine.inputType === null) {
                return 'input'
            }
            switch (sourceDefine.inputType) {
                case 1:
                    return 'multi_select'
                case 2:
                    return 'date_range'
                case 3:
                case 31:
                    return 'select'
                case 4:
                    return 'datetime_range'
                case 5:
                    return 'checkbox'
                case 6:
                    return 'checkbox_and_date'
                default:
                    return 'input'
            }
        },
        async getOption() {
            this.formItems.forEach(async item => {
                if (item.sourceDefine === null) {
                    return null
                }
                var options = []
                if (item.sourceDefine.inputType === 3) {
                    this.listLocations = []
                    await this.getListLocations()
                    options = this.listLocations.map(item => ({
                        text: item.label,
                        value: item.id
                    }))
                    options.unshift({
                        text: "-- 選択してください --",
                        value: null
                    })
                } else if (item.sourceDefine.inputType === 31) {
                    this.listWarehouses = []
                    await this.listWarehouse()
                    options = this.listWarehouses.map(item => ({
                        text: item.name,
                        value: item.id
                    }))
                    options.unshift({
                        text: "-- 選択してください --",
                        value: null
                    })
                } else {
                    if (item.sourceDefine.items !== null) {
                        options = Object.entries(JSON.parse(item.sourceDefine.items)).map(([key, value]) => ({
                            text: value,
                            value: key
                        }))
                    }
                }

                const status = {
                    [item.options]: options
                };
                this.form_select_options = {
                    ...this.form_select_options,
                    ...status
                }
            })

        },
        selectRow(row, event) {
            // there is an intermittent problem with double-selects
            // this timer makes sure that additional selects are ignored

            if (this.selecting !== false) return
            let _this = this
            setTimeout(_ => {
                _this.selecting = false
            }, 100)

            this.selecting = true

            // if (!Array.isArray(row)) {
            //     this.selected_row = row
            //     return
            // }

            // console.log(row[0])
            if (!this.jobUseMap || row === this.selected_row) return

            if (row === undefined) {
                this.selected_row = null
                return
            }
            if (this.selected_row === row) {
                this.selected_row = null
                return
            }
            this.selected_row = row
        },
        unSelectRow(row, event) {
            this.selected_row = null
        },
        setFormFields(menuConfigs) {
            this.formItems =
                menuConfigs
                .filter(item => item.searchOrder > 0)
                .sort((a, b) => a.searchOrder - b.searchOrder)
                .map(item => {

                    return ({
                        'id': item.id,
                        'title': item.name,
                        'type': this.getType(item.sourceDefine),
                        'options': item.source,
                        'sourceDefine': item.sourceDefine
                    })
                })
        },
        async setTableFields(menuConfigs) {
            this.fields =
                menuConfigs
                .filter(item => item.listOrder && item.listOrder !== -1)
                .sort((a, b) => a.listOrder - b.listOrder)
            //TODO add flag
            await this.setRobotMapItem()
            // this.sortBy = [{
            //     direction: 'asc',
            //     source: this.fields[0].source
            // }];

        },
        async setRobotMapItem() {
            const results = await Storage.list(this.tenantId + "/robot/" + this.url.warehouse_id + "/" + this.url.menu_id + "/", {
                pageSize: 'ALL'
            })
            if (results !== undefined && results.results.length > 0) {
                this.hasRobotLogFlag = true
                this.fields.push({
                    id: 9999,
                    name: "Replay",
                    source: "temiPath",
                    searchOrder: 9999,
                    listOrder: 9999,
                    detailOrder: 9999,
                    sourceDefine: null
                })
            } else {
                this.hasRobotLogFlag = false
            }
        },
        // ----- Data
        setData() {
            this.sortData()
            this.currentData = this.allData.slice((this.page - 1) * this.count, (this.page) * this.count)
            this.currentData = this.currentData.map(item => {
                return {
                    ...item,
                    locationId: item['Stock.locationId'] === undefined ? item['JobEpc.locationId'] : item['Stock.locationId'],
                    'Stock.locationId': item['locationName'],
                    'JobEpc.locationId': item['locationName'],
                    'Stock.warehouseId': item['warehouseName'],
                    'Job.warehouseId': item['warehouseName'],
                    'JobEpc.warehouseId': item['warehouseName'],
                    'ItemMaster.warehouseId': item['warehouseName']
                }
            })

            if(this.hasRobotLogFlag){
                this.currentData.forEach(async item => {
                    const results = await Storage.list(this.tenantId + "/robot/" + this.url.warehouse_id + "/" + this.url.menu_id + "/" + item["Job.id"], { pageSize: 'ALL'})
                    if(results !== undefined && results.results.length > 0){
                        item.hasRobotLogFlag = true
                    } else {
                        item.hasRobotLogFlag = false
                    }
                    
                })

            }
            this.paginate = {
                total: this.allData.length,
                per_page: this.count,
                current_page: this.page
            };
        },
        setUrlData() {
            const url = {
                function_id: this.$route.name,
                menu_id: parseInt(this.$route.params.id),
                warehouse_id: this.$route.query.warehouse
            }
            return this.url = url
        },
        async setWorkProperties(menuConfigs) {

            const response = await AwsApi.graphql({
                query: queries.queryItemDefines,
                variables: {
                    sourceTable: {
                        eq: "Job"
                    },
                    tenantId: this.tenantId
                }
            }, this);
            if (response === undefined) {
                return
            }
            const filterList = response.data.queryItemDefines.items.filter(item => !item.source.includes('updatedAt') &&
                !item.source.includes('createdAt') &&
                !item.source.includes('id') &&
                !item.source.includes('status') &&
                !item.source.includes('type'))

            this.work_properties = menuConfigs.filter(itemA => filterList.some(itemB => itemA.source === itemB.source)).sort((a, b) => a.listOrder - b.listOrder)
                .map(item => {
                    return ({
                        'name': item.name,
                        'inputType': item.sourceDefine.inputType,
                        'items': item.sourceDefine.items
                    })
                });

        },
        async fetchMenuConfigs() {
            const response = await AwsApi.graphql({
                query: queries.getMenu,
                variables: {
                    tenantId: this.tenantId,
                    id: this.url.menu_id
                }
            }, this);
            if (response === undefined) {
                return
            }
            this.displayTitle = response.data.getMenu.name
            this.deleteStocks = response.data.getMenu.showDeleteButton
            this.menuConfigs = response.data.getMenu.configs.items
            this.jobUseMap = true
            if (this.menuConfigs.length === 0) {
                return
            }
            this.setFormFields(this.menuConfigs);
            await this.setTableFields(this.menuConfigs);
            this.setWorkProperties(this.menuConfigs);
        },
        async fetchWarehouseData() {
            if (this.jobUseMap) {
                this.warehouse = null
                const response = await AwsApi.graphql({
                    query: queries.getWarehouse,
                    variables: {
                        tenantId: this.tenantId,
                        id: this.url.warehouse_id
                    }
                }, this);
                if (response === undefined) {
                    return
                }
                if (response.data.getWarehouse !== null && parseInt(response.data.getWarehouse.width)) {
                    this.listLocations = []
                    this.listLocationTags = []
                    await this.getListLocations()
                    await this.getListLocationTags()
                    response.data.getWarehouse.locations = this.listLocations
                    response.data.getWarehouse.tags = this.listLocationTags
                    this.warehouse = new Warehouse(response.data.getWarehouse)
                } else {
                    this.warehouse = null
                }
            } else {
                this.warehouse = null
            }
        },

        updateData() {
            this.getListData()
            this.tableItems = false
        },
        setRequestParam() {
            const myInit = {
                menuId: this.url.menu_id,
                limit: this.limit,
                offset: this.offset,
                warehouseId: this.url.warehouse_id,
            };
            for (const key in this.form) {
                const hasKey = this.formItems.some(i => i.options === key)
                const isDate = this.formItems.some(i => i.sourceDefine && (i.sourceDefine.inputType === 2 || i.sourceDefine.inputType === 4 || i.sourceDefine.inputType === 6) && i.options === key)
                if (hasKey) {
                    if (this.form[key] !== null && this.form[key] !== '') {
                        if (typeof (this.form[key]) === "boolean") {
                            if (this.form[key] === true) {
                                myInit[key] = this.form[key]
                                this.isSearchFlag = true

                            }
                        } else if (typeof (this.form[key]) == "string" && !isDate) {
                            myInit[key] = "%" + this.form[key] + "%"
                            this.isSearchFlag = true
                        } else {
                            if(this.form[key] !== undefined){
                                myInit[key] = this.form[key]
                                this.isSearchFlag = true
                            }
                        }

                    }
                }
            }
            if (this.warehouse !== null && this.form['Stock.locationId'] !== undefined && this.form['Stock.locationId'] !== null) {
                myInit['Stock.locationId'] = this.form['Stock.locationId']
                this.isSearchFlag = true
            }
            return myInit
        },
        async getListData() {
            this.working = true
            const myInit = this.setRequestParam()
            if (this.functionId === global.functionIdHISTORY) {
                const response = await AwsApi.graphql({
                    query: queries.searchHistory,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    this.working = false
                    return
                }

                const convertData = response.data.searchHistory.map(item => {
                    return JSON.parse(item)
                })
                this.allData = this.allData.concat(convertData);
                this.setData();
                this.working = false

            } else if (this.title === "Stocks") {
                const response = await AwsApi.graphql({
                    query: queries.searchStocks,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                const responseCount = await AwsApi.graphql({
                    query: queries.getStockAmount,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    this.working = false
                    return
                }

                if (responseCount !== undefined && responseCount.data !== undefined) {
                    this.stockCount = responseCount.data.getStockAmount
                }

                this.stockData(response)
                this.setData();
                this.setSearchLocation()
                this.working = false

            } else {
                const response = await AwsApi.graphql({
                    query: queries.searchJobs,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    this.working = false
                    return
                }
                const convertData = response.data.searchJobs.map(item => {
                    return JSON.parse(item)
                })
                this.allData = this.allData.concat(convertData);
                this.setData();
                this.working = false
            }

        },
        setSearchLocation(){
            this.searchLocationSet.clear()
            this.searchLocationSet = new Set(this.searchLocationSet);
            if(this.isSearchFlag) {
                this.isSearchFlag = false
            } else {
                return
            }
            this.allData.forEach(item=>{
                this.searchLocationSet.add(item['Stock.locationId'] === undefined ? item['JobEpc.locationId'] : item['Stock.locationId'])  
            })
            this.searchLocationSet = new Set(this.searchLocationSet);
        },
        stockData(response) {
            const convertData = response.data.searchStocks.map(item => {
                return JSON.parse(item)
            })
            if (this.initSelectLocations.length === 0 && !this.isFirstShowPage) {
                convertData.forEach(item => {
                    if (this.initSelectLocations.indexOf(item['Stock.locationId']) === -1) {
                        this.initSelectLocations.push(item['Stock.locationId'])
                    }
                })
                if (this.warehouse !== null && this.form['Stock.locationId'] !== undefined && this.form['Stock.locationId'] !== null) {
                    this.initSelectLocations.push(this.form['Stock.locationId'])
                }
            } else {
                if (response.data.searchStocks.length !== 1000) {
                    this.isFirstShowPage = false
                }
            }
            this.allData = this.allData.concat(convertData);
        },
        async getListLocations(nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: this.url.warehouse_id
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }

            this.listLocations = this.listLocations.concat(response.data.queryLocationsByWarehouseId.items);
            if (response.data.queryLocationsByWarehouseId.nextToken !== null) {
                await this.getListLocations(response.data.queryLocationsByWarehouseId.nextToken)
            }
        },
        async getListLocationTags(nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationTagsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: this.url.warehouse_id
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }

            this.listLocationTags = this.listLocationTags.concat(response.data.queryLocationTagsByWarehouseId.items);
            if (response.data.queryLocationTagsByWarehouseId.nextToken !== null) {
                await this.getListLocationTags(response.data.queryLocationTagsByWarehouseId.nextToken)
            }
        },
        updatePage(page) {
            this.page = page;
            if (this.page * this.count >= this.limit + this.offset) {
                this.offset += this.limit
                this.getListData()
            } else {
                this.setData()
            }
        },
        updatePaginateCount(value) {
            if (value === '') {
                value = 1
            }
            // send back to page 1 when changing the count
            this.page = 1;
            this.count = value;
            this.updatePage(this.page)
        },

        // ----- Sort

        sortChange(value) {
            //check if value
            const exist = this.sortBy.map(item => item.source).includes(value);
            // store the new value if not exist or change direction
            this.sortBy = exist ?
                this.sortBy
                .map(item => {
                    if (item.source === value) {
                        item.direction = item.direction === 'asc' ? 'desc' : 'asc'
                    }
                    return item
                }) : [{
                    source: value,
                    direction: 'asc'
                }];
            this.setData();
        },

        sortData() {
            if (this.sortBy.length === 0) {
                return
            }
            this.allData.sort((a, b) => {
                const sort = this.sortBy[0];

                const valA = a[sort.source];
                const valB = b[sort.source];

                if (valA == null && valB == null) return 0;
                if (valA == null) return sort.direction === "asc" ? 1 : -1;
                if (valB == null) return sort.direction === "asc" ? -1 : 1;

                if (typeof valA === "number") {
                if (sort.direction === "asc") {
                    return valA < valB ? -1 : 1;
                } else {
                    return valA > valB ? -1 : 1;
                }
                } else {
                if (sort.direction === "asc") {
                    return valA.localeCompare(valB);
                } else {
                    return valB.localeCompare(valA);
                }
                }
            })
        },
        setModalCreateNew(item) {
            this.setModalCreate(item)
        },
        setModalCreate(item) {
            if (this.functionId === global.functionIdHISTORY || this.functionId === global.functionIdPUBLISH_REGISTER) {
                return
            }
            // get the item informations if 'update' or open with a new empty one
            this.setModalCreateInfo(item);
            this.modalCreateJob = true
        },
        hideModalCreateJob() {
            this.modalCreateJob = false
        },
        changeMsgModal(value) {
            this.msgModal = value
        },
        setModalCreateInfo(item) {
            this.modalCreateInfos = item ? this.getModalCreateInfosPerPage(item) : {
                'work_number': null,
            };
        },
        getModalCreateInfosPerPage(item) {
            return {
                'work_number': item['Job.id'] ? item['Job.id'] : null,
                'status': item['Job.status_value'] !== undefined ? item['Job.status_value'] : undefined,
                'work_properties': item['Job.properties']
            }
        },
        getModalItemInfosPerPage(item) {
            // build the items for the header of the modal items
            switch (this.title) {
                case 'Stocks':
                    return {
                        'Stock.itemCode': item['Stock.itemCode'],
                            'Stock.locationId': item.locationId
                    };
                case 'Job':
                    const input = {}
                    if (item['Job.id']) {
                        input['Job.id'] = item['Job.id']
                        input['JobDetail.jobId'] = item['Job.id']
                    }
                    if (item['JobDetail.itemCode']) {
                        input['JobDetail.itemCode'] = item['JobDetail.itemCode']
                    }
                    if (item['ItemMaster.itemCode']) {
                        input['JobDetail.itemCode'] = item['ItemMaster.itemCode']
                    }
                    if (item['JobEpc.epc']) {
                        input['JobEpc.epc'] = item['JobEpc.epc']
                    }
                    if (item['JobEpc.locationId']) {
                        input['JobEpc.locationId'] = item.locationId
                    }

                    return input;

            }
        },
        setModalItem(item) {
            
            this.modalDetailInfos = item ? this.getModalItemInfosPerPage(item) : infos;
            this.tableItems = true;
        },
        async uploadCSV(csvStr) {
            if (/\.(xlsx)$/i.test(this.$refs.file.files[0].name)) {
                this.updateCsvPath = `${this.tenantId}/job/${this.url.warehouse_id}/${this.url.menu_id}/${global.formatLongDate()}.xlsx`
                return await Storage.put(this.updateCsvPath, this.$refs.file.files[0]);
            } else if (/\.(xlsm)$/i.test(this.$refs.file.files[0].name)) {
                this.updateCsvPath = `${this.tenantId}/job/${this.url.warehouse_id}/${this.url.menu_id}/${global.formatLongDate()}.xlsm`
                return await Storage.put(this.updateCsvPath, this.$refs.file.files[0]);
            } else {
                this.updateCsvPath = `${this.tenantId}/job/${this.url.warehouse_id}/${this.url.menu_id}/${global.formatLongDate()}.csv`
                return await Storage.put(this.updateCsvPath, csvStr, {
                    contentType: "text/csv",
                });
            }
        },
        backToMainPage() {
            this.tableItems = false
            this.selected_row = null
        },
        downloadCSV() {
            this.csvWorking = true
            if (this.tableItems === true) {
                this.csvDetailOffset = 0
                this.csvAllData = []
                this.getDetailListData().then(() => {
                    var csv = ""
                    const headers =
                        this.menuConfigs
                        .filter(item => item.detailOrder && item.detailOrder !== -1)
                        .sort((a, b) => a.detailOrder - b.detailOrder)
                    headers.forEach((item) => {
                        if (item.name === undefined || item.name === null) {
                            item.name = ""
                        }
                        if (csv === '') {
                            csv = csv + item.name
                        } else {
                            csv = csv + ',' + item.name
                        }
                    })
                    csv = csv + '\n'
                    this.csvAllData.forEach((item) => {
                        headers.forEach((key, index) => {
                            if (key.source.indexOf('BARCODE(') !== -1) {
                                key.source = key.source.replace('BARCODE(', '').replace(')', '')
                            }
                            var value = item[key.source]
                            if (key.source === 'Stock.locationId' || key.source === 'JobEpc.locationId') {
                                value = item['locationName']
                            }
                            if (key.source === 'Stock.warehouseId' || key.source === 'JobEpc.warehouseId' || key.source === 'Job.warehouseId' ||
                                key.source === 'ItemMaster.warehouseId') {
                                value = item['warehouseName']
                            }
                            if (value === undefined || value === null) {
                                value = ""
                            }
                            if (index === 0) {
                                csv = csv + value
                            } else {
                                csv = csv + ',' + value
                            }
                        })
                        csv = csv + '\n'

                    })
                    this.downloadCSVFun(csv, `${this.title+'_detail_'+this.url.warehouse_id}.csv`);
                    this.csvWorking = false
                })
            } else {
                this.getCsvALlListData().then(() => {
                    var csv = ""
                    this.fields.forEach((item) => {
                        if (item.name === undefined || item.name === null) {
                            item.name = ""
                        }
                        if (csv === '') {
                            csv = csv + item.name
                        } else {
                            csv = csv + ',' + item.name
                        }
                    })
                    csv = csv + '\n'
                    this.allData.forEach((item) => {
                        this.fields.forEach((key, index) => {
                            if (key.source.indexOf('BARCODE(') !== -1) {
                                key.source = key.source.replace('BARCODE(', '').replace(')', '')
                            }
                            var value = item[key.source]
                            if (key.source === 'Stock.locationId' || key.source === 'JobEpc.locationId') {
                                value = item['locationName']
                            }
                            if (key.source === 'Stock.warehouseId' || key.source === 'JobEpc.warehouseId' || key.source === 'Job.warehouseId' ||
                                key.source === 'ItemMaster.warehouseId') {
                                value = item['warehouseName']
                            }

                            if (value === undefined || value === null) {
                                value = ""
                            }
                            if (index === 0) {
                                csv = csv + value
                            } else {
                                csv = csv + ',' + value
                            }
                        })
                        csv = csv + '\n'

                    })
                    this.downloadCSVFun(csv, `${this.title+'_'+this.url.warehouse_id}.csv`);
                    this.csvWorking = false
                })
            }

        },
        async getDetailListData() {
            var myInit = {};
            for (const key in this.form) {
                const hasKey = this.formItems.some(i => i.options === key)
                const isDate = this.formItems.some(i => i.sourceDefine && (i.sourceDefine.inputType === 2 || i.sourceDefine.inputType === 4 || i.sourceDefine.inputType === 6) && i.options === key)
                if (hasKey) {
                    if (this.form[key] !== null && this.form[key] !== '') {
                        //myInit.body[key] = this.form[key]
                        if (typeof (this.form[key]) === "boolean") {
                            if (this.form[key] === true) {
                                myInit[key] = this.form[key]
                            }
                        } else if (typeof (this.form[key]) == "string" && !isDate) {
                            myInit[key] = "%" + this.form[key] + "%"
                        } else {
                            myInit[key] = this.form[key]
                        }

                    }
                }
            }
            myInit = {
                ...myInit,
                menuId: this.url.menu_id,
                limit: this.limit,
                offset: this.csvDetailOffset,
                warehouseId: this.url.warehouse_id,
                searchType: 1,
                ...this.modalDetailInfos
            };
            if (this.functionId === global.functionIdHISTORY) {
                const response = await AwsApi.graphql({
                    query: queries.searchHistory,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }
                if (response.data.searchHistory.length !== 0) {
                    const convertData = response.data.searchHistory.map(item => {
                        return JSON.parse(item)
                    })
                    this.csvAllData = this.csvAllData.concat(convertData);
                    if (response.data.searchHistory.length === 1000) {
                        this.csvDetailOffset += this.limit
                        await this.getDetailListData()
                    }
                }
            } else if (this.title === "Stocks") {
                const response = await AwsApi.graphql({
                    query: queries.searchStocks,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }
                if (response.data.searchStocks.length !== 0) {
                    const convertData = response.data.searchStocks.map(item => {
                        return JSON.parse(item)
                    })
                    this.csvAllData = this.csvAllData.concat(convertData);
                    if (response.data.searchStocks.length === 1000) {
                        this.csvDetailOffset += this.limit
                        await this.getDetailListData()
                    }
                }
            } else {
                const response = await AwsApi.graphql({
                    query: queries.searchJobs,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }
                if (response.data.searchJobs.length !== 0) {
                    const convertData = response.data.searchJobs.map(item => {
                        return JSON.parse(item)
                    })
                    this.csvAllData = this.csvAllData.concat(convertData);
                    if (response.data.searchJobs.length === 1000) {
                        this.csvDetailOffset += this.limit
                        await this.getDetailListData()
                    }
                }
            }

        },
        downloadCSVFun(csv, csvName) {
            let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            let blob = new Blob([bom, csv], {
                type: 'text/csv'
            })
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = csvName
            downloadLink.click();

        },
        async getCsvALlListData() {
            this.offset += this.limit
            const myInit = this.setRequestParam()

            if (this.title === "Stocks") {
                const response = await AwsApi.graphql({
                    query: queries.searchStocks,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }

                if (response.data.searchStocks.length !== 0) {
                    const convertData = response.data.searchStocks.map(item => {
                        return JSON.parse(item)
                    })
                    this.allData = this.allData.concat(convertData);
                    this.paginate = {
                        total: this.allData.length,
                        per_page: this.count,
                        current_page: this.page
                    };
                    if (response.data.searchStocks.length === 1000) {
                        await this.getCsvALlListData()
                    }
                }
            } else {
                const response = await AwsApi.graphql({
                    query: queries.searchJobs,
                    variables: {
                        conditions: JSON.stringify(myInit)
                    }
                }, this)
                if (response === undefined) {
                    return
                }
                if (response.data.searchJobs.length !== 0) {
                    const convertData = response.data.searchJobs.map(item => {
                        return JSON.parse(item)
                    })
                    this.allData = this.allData.concat(convertData);
                    this.paginate = {
                        total: this.allData.length,
                        per_page: this.count,
                        current_page: this.page
                    };
                    if (response.data.searchJobs.length === 1000) {
                        await this.getCsvALlListData()
                    }
                }
            }

        },
        // ----- Modal

        resetPage() {
            this.initSelectLocations = []
            this.page = 1;
            this.allData = [];
            this.offset = 0
            this.paginate = {
                total: 1,
                per_page: 1,
                current_page: 1
            };
            this.selected_row = null
        },
        async submitFormSearch(form) {
            this.resetPage()
            this.form = form.form;
            this.updateData()
            this.form_select_options = {
                ...this.form_select_options
            }
        },
        async defaultFormSearch(form) {
            this.resetPage()
            this.form = form.form;
            await this.getOption()
            this.updateData()

        },
        clearInput() {
            const input = this.$refs.file
            input.type = 'text'
            input.type = 'file'
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];

            /*
              Check to see if the file is not empty.
            */
            if (this.file) {
                if (/\.(csv)$/i.test(this.file.name)) {
                    this.reader.readAsText(this.file);
                }
                if (/\.(xlsx|xlsm)$/i.test(this.file.name)) {
                    this.reader.readAsArrayBuffer(this.file);
                }
            }

        },
        deleteStocksFun() {
            if (this.currentData.length === 0) {
                return
            }
            this.modalAlertDeleteStocks = true
        },
        async modalAlertDeleteStocksFun() {
            this.deleteWorking = true
            const myInit = this.setRequestParam()
            const response = await AwsApi.graphql({
                query: mutations.deleteStocks,
                variables: {
                    conditions: JSON.stringify(myInit)
                }
            }, this)
            if (response !== undefined && response.data.deleteStocks === -1) {
                this.showOverlay = true
                this.csvUploadMessage = this.$t("delete_stocks_error")
            }
            if (this.$refs.appform) {
                let f = this.$refs.appform.beforeSubmit()
                this.defaultFormSearch({
                    form: f
                });
            }
            this.deleteWorking = false

        },
        showRobotPathFun(jobId) {
            this.currentJobId = jobId
            this.showRobotPathFlag = true
            setTimeout(() => {
                this.showRobotPathBodyFlag = this.showRobotPathBodyFlag + 1;
            }, 0);
        },
        resetRobotPathModal() {
            this.showRobotPathBodyFlag = 0;
        }

    }
}
</script>

<style scoped>
.upload_csv_btn {
    cursor: default;
    padding: 0 8px;
}

.upload_csv_label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 4px 0;
}
</style>
