<template>
<section>
    <floor-map :floormap="warehouse" v-if="warehouse !== null" @saved="reloadWarehouse" :max-height="600"></floor-map>

</section>
</template>

<script>
import FloorMap from "../../../components/map/FloorMap.vue";
import AwsApi from "../../../Api"
import * as queries from "../../../graphql/locus_queries";
import global from "../../../Global.vue";
import {
    Warehouse
} from "../../../components/modal/Warehouse";

export default {
    components: {
        FloorMap
    },
    data() {
        return {
            tenantId: null,
            warehouse: null,
            listLocations: [],
            listLocationTags: []
        };
    },
    mounted() {
        this.tenantId = sessionStorage.getItem(global.tenantId)
        this.reloadWarehouse()
    },
    created() {

    },
    methods: {
        async reloadWarehouse() {
            this.warehouse = null
            const response = await AwsApi.graphql({
                query: queries.getWarehouse,
                variables: {
                    tenantId: this.tenantId,
                    id: this.$route.params.id
                }
            }, this);
            if (response === undefined) {
                return
            }
           
            if (response.data.getWarehouse !== null) {
                this.listLocations = [],
                this.listLocationTags = [],
                await this.getListLocations()
                await this.getListLocationTags()
                response.data.getWarehouse.locations = this.listLocations
                response.data.getWarehouse.tags = this.listLocationTags
                this.warehouse = new Warehouse(response.data.getWarehouse)
                
            } else {
                this.warehouse = null
            }
        },
        async getListLocations(nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: this.$route.params.id
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }
            this.listLocations = this.listLocations.concat(response.data.queryLocationsByWarehouseId.items);
            if (response.data.queryLocationsByWarehouseId.nextToken !== null) {
                await this.getListLocations(response.data.queryLocationsByWarehouseId.nextToken)
            }
        },
        async getListLocationTags(nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationTagsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: this.$route.params.id
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }
            this.listLocationTags = this.listLocationTags.concat(response.data.queryLocationTagsByWarehouseId.items);
            if (response.data.queryLocationTagsByWarehouseId.nextToken !== null) {
                await this.getListLocationTags(response.data.queryLocationTagsByWarehouseId.nextToken)
            }
        },
    }
};
</script>

<style>

</style>
