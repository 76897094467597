import {
    API,Auth
} from "aws-amplify";

export default class AwsApi {
    static graphql(queries, _this) {
        try {
            return Auth.currentSession()
            .then((data) => {
                return API.graphql(queries).catch((error) =>{ 
                    console.log(error)
                    return undefined
                })
            })
            .catch(async (error) =>{ 
                await this.sessionExpired(error, _this, queries)
                
        });
        } catch (error) {
             this.sessionExpired(error, _this, queries)
        }
    }

    static checkSession(callBackFun, _this) {
        try {
            return Auth.currentSession()
            .then((data) => {
                callBackFun()
            })
            .catch(async (error) => { 
                await this.sessionExpiredFun(error, callBackFun, _this)
            });
        } catch (error) {
             this.sessionExpiredFun(error, callBackFun, _this)
        }
    }

    static async sessionExpired(error, _this, queries){
        console.log(error)
        const isGuest = sessionStorage.getItem("isGuest");
        if(isGuest === "true"){
            const localUserName = sessionStorage.getItem("localUserName");
            const localPassword = sessionStorage.getItem("localPassword");
            await Auth.signIn(localUserName, localPassword)
            return API.graphql(queries).catch((error) =>{ 
                console.log(error)
                return undefined
            })
        } else {
            _this.$router.push(`/`).catch(error => error)
            return undefined
        }
    }

    static async sessionExpiredFun(error, callBackFun, _this){
        console.log(error)
        const isGuest = sessionStorage.getItem("isGuest");
        if(isGuest === "true"){
            const localUserName = sessionStorage.getItem("localUserName");
            const localPassword = sessionStorage.getItem("localPassword");
            await Auth.signIn(localUserName, localPassword)
            callBackFun()
        } else {
            _this.$router.push(`/`).catch(error => error)
            return undefined
        }
    }
}
