<template>
<div class="left side-menu">
    <img src="/icon-left.png" width="24" height="24" @click="$emit('hideMenu')" style="float:right;" />
    <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto">
        <div class="sidebar-inner slimscrollleft" style="overflow: hidden; width: auto">
            <!--- Divider -->
            <div id="sidebar-menu" class="menu-container">
                <ul id="menu-infos">
                    <li class="warehouse-select mx-4 my-4 py-3 border-bottom">
                        <h5 class="row py-2">
                            <span class="col-5">ユーザ</span>
                            <span class="col-6"><strong>{{ userInfo.username }}</strong></span>
                        </h5>
                        <h5 class="row">
                            <span class="col-5">テナント</span>
                            <span class="col-6"><strong>{{ userInfo.tenantId }}</strong></span>
                        </h5>
                    </li>
                    <li class="warehouse-select mx-4 my-2 py-2">
                        <h6>マップ</h6>
                        <b-form-select :disabled="disabled" v-model="selected" :options="options" class="mb-3" size="sm" v-on:change="changeWarehouse" />
                    </li>
                </ul>

                <ul id="menu-list">
                    <!-- for each display -->
                    <li v-bind:key="menu.id" v-for="menu in menus">
                        <RouterLink :to="getMenuLink(menu)" class="waves-effect waves-primary">
                            <i v-if="menu.icon && menu.icon !== '0'" v-bind:class="$t(`function_id.${menu.functionId}.${menu.icon}.logo_class`)"></i>
                            <i v-else v-bind:class="$t(`function_id.${menu.functionId}.logo_class`)"></i>
                            <span> {{ menu.name }} </span>
                        </RouterLink>
                    </li>
                </ul>

                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="slimScrollBar" style="
          background: rgb(152, 166, 173);
          width: 5px;
          position: absolute;
          top: 0px;
          opacity: 0.4;
          display: none;
          border-radius: 7px;
          z-index: 99;
          right: 1px;
          height: 537.357px;
          visibility: visible;
        "></div>
        <div class="slimScrollRail" style="
          width: 5px;
          height: 100%;
          position: absolute;
          top: 0px;
          display: none;
          border-radius: 7px;
          background: rgb(51, 51, 51);
          opacity: 0.2;
          z-index: 90;
          right: 1px;
        "></div>
    </div>
</div>
</template>

<script>
// import Utils from "../utils/Utils";
import * as queries from "../../graphql/locus_queries";
import global from "../../Global.vue";
import AwsApi from "../../Api"
import { Auth } from 'aws-amplify';

export default {
    data: function () {
        return {
            selected: null,
            options: [],
            userInfo: {
                username: "",
                tenantId: ""
            },
            menus: [],
            warehouse_id: null,
            disabled: false
        };
    },
    mounted() {

        if (this.$route.meta.withoutDefaultWarehouse) {
            this.disabled = true
        }
        //         // set the page to the current warehouse from the url.
        this.setSelected();
        this.fetchUserInfo();
        this.fetchWarehouse();
        this.fetchMenuSettings();
        //         //listen event if menuSettings are updated
        this.$bus.on('patchMenuSettings', () => {
            this.fetchMenuSettings()
        })
        this.$bus.on('fetchWarehouse', () => {
            this.fetchWarehouse()
        })
    },
    watch: {
        // set the page to the current warehouse when switching history.
        $route(to, from) {
            // react to route changes...
            if (to.matched.some(record => record.meta.withoutDefaultWarehouse)) {
                this.disabled = true
                this.selected = null
            } else {
                this.disabled = false
                this.selected = this.$route.query.warehouse
            }
        },
    },
    methods: {
        getMenuLink(menu) {

            if (menu.functionId === global.functionIdMASTER) {
                return `/${menu.functionId}/${menu.id}/0?warehouse=${this.warehouse_id}`;
            }
            if (menu.functionId === global.functionIdSETTINGS) {
                return `/${menu.functionId}/${menu.id}`;
            }
            if (menu.functionId === global.functionIdUSERMANAGE) {
                return `/${global.functionIdUSERMANAGE}`
            }
            return `/${menu.functionId}/${menu.id}?warehouse=${this.warehouse_id}`;
        },
        fetchUserInfo() {
            this.userInfo.username = sessionStorage.getItem(global.userName);
            this.userInfo.tenantId = sessionStorage.getItem(global.tenantId);
        },
        async fetchWarehouse() {
                    const user = await Auth.currentAuthenticatedUser();
            console.log(user)
            const userResponse = await AwsApi.graphql({
                query: queries.listTenants,
                variables: {
                    tenantId: this.userInfo.tenantId
                }
            }, this);

            const response = await AwsApi.graphql({
                query: queries.listWarehouses,
                variables: {
                    tenantId: this.userInfo.tenantId
                }
            }, this);
            if (response !== undefined) {
                this.options = response.data.listWarehouses.items.map((item) => ({
                    value: item.id,
                    text: `${item.name}`,
                }));
            }

            if (userResponse !== undefined && userResponse.data.listTenants.items.length > 0 && userResponse.data.listTenants.items[0].show_all_warehouse === true) {
                this.options.unshift({
                    text: "すべて",
                    value: 0
                })
            }
            if (this.options === undefined || this.options.length === 0) {
                this.warehouse_id = 0;
            } else {
                this.warehouse_id = this.options[0].value
            }
            this.changeWarehouse(this.warehouse_id);

        },
        async fetchMenuSettings() {

            const response = await AwsApi.graphql({
                query: queries.listMenus,
                variables: {
                    tenantId: this.userInfo.tenantId
                }
            }, this);
            if (response === undefined) {
                return
            }
            this.menus = response.data.listMenus.items.filter(item => {
                return item.showSide == null || item.showSide != global.showAPP
            }).sort(function (a, b) {
                return a.showOrder - b.showOrder
            });
            // UserManage
            if(global.userManageFlag){
                if (this.userInfo.username === this.userInfo.tenantId) {
                    this.menus.push({
                        functionId: global.functionIdUSERMANAGE,
                        icon: "",
                        id: global.functionIdUSERMANAGE,
                        jobStep: null,
                        locationFlag: false,
                        mapMode: false,
                        name: 'ユーザー管理',
                        pickingRssi: null,
                        sendFinishFlag: false,
                        showDeleteButton: null,
                        showOrder: 9999,
                        showSide: 1,
                        stockStatus: 0
                    })
                } else {
                    await this.getFunctionIdOption()
                }
            }
        },
        async getFunctionIdOption() {
            const response = await AwsApi.graphql({
                query: queries.getUserManage,
                variables: {
                    tenantId: this.userInfo.tenantId,
                    username: this.userInfo.username,
                }
            });

            if (response === undefined || response.data.getUserManage === null) {
                this.menus = []
                return
            }
            this.menus = this.menus.filter(item => response.data.getUserManage.menuId.includes(item.id))

        },
        setSelected() {
            if (this.$route.query.warehouse !== undefined) {
                this.selected = this.$route.query.warehouse
            }
        },
        changeWarehouse(value) {
            this.warehouse_id = value;
            if (!this.disabled) {
                this.$router.push(`${this.$route.path}?warehouse=${value}`).catch(error => error);
            }
        }
    },
};
</script>

<style scoped>
.warehouse-select {
    padding: 12px 20px;
}
</style><style>
.menu-container {
    height: 100%;
}

#menu-infos {
    height: 206px;
}

#menu-list {
    height: calc(100% - 206px);
    overflow-y: hidden;
}

#menu-list:hover {
    overflow-y: auto;
}
</style>
