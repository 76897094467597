<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="warehouse">
    <div @mousemove="mouseevent(handleDragOldShelf, $event, null)" @mouseup="handleStopDragOldShelf($event, null)" @keyup.stop="handleNudge" id="map-container">

        <div class="d-flex justify-content-between" v-if="capabilities.save">
            <h2>{{ warehouse.name }}</h2>
            <div>
                <b-button style="margin-right:10px" :disabled="working" :variant="working ? `` : `outline-primary ml-auto`" @click="$router.push(`/3/${this.$route.params.menuId}/1?warehouse=${this.$route.query.warehouse}`)">戻る</b-button>
                <b-button :disabled="working || warehouse._checkpoints.length === 0 " :variant="working || warehouse._checkpoints.length === 0  ? `` : `outline-primary ml-auto`" @click="serialize">保存</b-button>
            </div>
        </div>

        <b-button-toolbar class="map-btn-toolbar" aria-label="Toolbar with button groups" v-if="capabilities.buttons || capabilities.zoom_buttons">
            <b-button-group class="mx-1" v-if="capabilities.buttons" >
                <b-button variant="light" :disabled="warehouse._checkpoints.length === 0" @click="undo"><i class="mdi mdi-undo"></i></b-button>
            </b-button-group>
            <b-button-group class="mx-1" v-if="capabilities.buttons">
                <drag variant="light"
                    @dragstart="handleDragNewShelf($event, `shelf`)"
                    @dragend="handleStopDragNewShelf"
                      :effect-allowed="['none']"
                      drop-effect="none"
                      :image="'none'"
                >
                    <b-button variant="light"><i class="mdi mdi-select"></i></b-button>
                </drag>
                <b-button
                    :variant="drag_will_trash ? `danger`:`light`"
                    @mouseup="handleOldShelfDelete"
                    @mouseover="handleOldShelfDeleteIndicator(false, $event)"
                    @mouseout="handleOldShelfDeleteIndicator(true, $event)"
                ><i :class="`mdi mdi-delete`"></i></b-button>
            </b-button-group>
            <b-button-group class="mx-1" v-if="capabilities.buttons">
                <drag variant="light"
                      @dragstart="handleDragNewShelf($event, `item`)"
                      @dragend="handleStopDragNewShelf"
                      :effect-allowed="['none']"
                      drop-effect="none"
                      :image="'none'"
                >
                    <b-button variant="light" @click="toggleItemMode"><i :class="`mdi mdi-tag`+(item_mode?` text-danger`:``)"></i></b-button>

                </drag>
            </b-button-group>
            <b-button-group class="mx-1" v-if="capabilities.zoom_buttons">
                <b-button variant="light" ref="zoom-in" @mousedown="handleZoom(`in`,true)" @mouseup="handleZoom(`in`,false)"><i class="mdi mdi-magnify-plus"></i></b-button>
                <b-button variant="light" ref="zoom-out" @mousedown="handleZoom(`out`,true)" @mouseup="handleZoom(`out`,false)"><i class="mdi mdi-magnify-minus"></i></b-button>
            </b-button-group>
            <b-button-group class="mx-1" v-if="capabilities.buttons">
                <b-button variant="light" @click="copy" :disabled="active_selection===null" title="コピー"><i class="mdi mdi-content-copy"></i></b-button>
                <b-button variant="light" @click="paste" :disabled="copy_objects.length===0" title="ペースト"><i class="mdi mdi-content-paste"></i></b-button>
            </b-button-group>
            <b-button-group class="mx-1" v-if="capabilities.buttons">
                <button class="btn btn-link" disabled>Default (m)</button>
                <b-form-input v-model="warehouse._default_shelf_width" @input="changeDefaultSizes" style="width: 60px"></b-form-input>
                <button class="btn btn-link" disabled>x</button>
                <b-form-input v-model="warehouse._default_shelf_height" @input="changeDefaultSizes" style="width: 60px"></b-form-input>
            </b-button-group>
            <b-button-group class="mx-1 snap-check" v-if="capabilities.buttons">
                <b-form-checkbox
                        id="checkbox-1"
                        v-model="capabilities.snap"
                        name="checkbox-1"
                        :value="true"
                        :unchecked-value="false"
                >&nbsp;Snap
                </b-form-checkbox>
            </b-button-group>
            <b-button-group class="mx-1" v-if="capabilities.counts">
                <button class="btn btn-link" disabled>{{ count_location }}棚</button>
                <button class="btn btn-link" disabled>{{ count_tag }}タグ</button>
                
                <router-link :to="`/map/${this.warehouse.id}/create/${this.$route.params.menuId}?warehouse=${this.$route.query.warehouse}`" replace>
                    <b-button style="margin-left: 16px">画像を入れ替え</b-button>
                </router-link>
            </b-button-group>
            <!-- <b-button-group class="mx-1" v-if="capabilities.buttons">
                <b-button @click="toggleHistory" :variant="history_display?'primary':'secondary'">履歴表示</b-button>
            </b-button-group> -->
            <b-button-group class="mx-1" v-if="capabilities.buttons && item_mode">
                <button class="btn btn-link" disabled>タグ番号</button>
                <b-form-input :value="item_selected_label.label" @input="updateLabel" style="width: 60px"></b-form-input>
                <b-button @click="changeLabel" :disabled="item_selected_label.disabled">変更</b-button>
                <b-button @click="searchLabel" :disabled="item_selected !== null && ''+item_selected_label.label===''+item_selected.label">検索</b-button>
            </b-button-group>
        </b-button-toolbar>
        <div class="map-data-container">
            <div class="map-editor" :style="'flex: 1 1 '+(history_display?50:100)+'%'">
                <div style="position: relative; width: 100%; height: 0px; display: block;">
                    <div v-if="info_text" style="position: absolute; right: 0; background-color:#f5f5f5; padding: 0 0.5rem 0.5rem; border-bottom-left-radius: 1rem;">{{ info_text }}</div>
                </div>
                <div v-if="drag_trash" :style="`z-index: 1; position: absolute; top:`+drag_oob_y+`px; left:`+drag_oob_x+`px;`" style="pointer-events: none"><i class="mdi mdi-select"></i></div>
                <div v-if="shelf_selecting !== null" :style="`z-index: 1; position: absolute; top:`+shelf_selecting.y+`px; left:`+shelf_selecting.x+`px;`" style="pointer-events: none">
                    <div class="spinner-border text-primary m-2" style="width: 40px; height: 40px;" role="status"></div>
                </div>
                <div v-if="map_selecting !== null" :style="`z-index: 1; position: absolute; top:`+map_selecting.y+`px; left:`+map_selecting.x+`px;`" style="pointer-events: none">
                    <div class="spinner-border text-success m-2" style="width: 40px; height: 40px;" role="status"></div>
                </div>
                <template
                    v-if="shelf_selected"
                >
                        <i class="mdi mdi-target"
                            :style="`z-index: 3; pointer-events: none; font-size: 10px; position: absolute; top:`+(shelf_selected_controls.c.y-5)+`px; left:`+(shelf_selected_controls.c.x-5)+`px;`" />

                        <div
                             :style="`z-index: 3; pointer-events: all; font-size: 20px; pointer-events: all; user-select: none; background: rgba(255,255,255,0.7); position: absolute; top:`+(shelf_selected_controls.input_size.y)+`px; left:`+(shelf_selected_controls.input_size.x)+`px;`"
                        >
                            <label style="position: absolute; top: -14px; left: 25px; font-size: 16px; user-select: none">w</label>
                            <label style="position: absolute; top: -14px; left: 72px; font-size: 16px; user-select: none">h</label>
                            (<input class="shelf-input" type="text" pattern="[0-9.]*" v-model="shelf_selected._user_w" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">,<input type="text" class="shelf-input" pattern="\d*" v-model="shelf_selected._user_h" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">)</div>
                        <div
                            :style="`z-index: 3; pointer-events: all; font-size: 20px; pointer-events: all; white-space: nowrap; background: rgba(255,255,255,0.7); user-select: none; position: absolute; top:`+(shelf_selected_controls.input_position.y)+`px; left:`+(shelf_selected_controls.input_position.x)+`px;`"
                        >
                            <label style="position: absolute; top: -14px; left: 25px; font-size: 16px; user-select: none">x</label>
                            <label style="position: absolute; top: -14px; left: 72px; font-size: 16px; user-select: none">y</label>
                            (<input class="shelf-input" type="text" pattern="[0-9]*" v-model="shelf_selected._user_x" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">,<input class="shelf-input" type="text" pattern="[0-9]*" v-model="shelf_selected._user_y" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">)</div>
                        <div
                                :style="`z-index: 3; pointer-events: all; font-size: 20px; pointer-events: all; white-space: nowrap; background: rgba(255,255,255,0.7); user-select: none; position: absolute; top:`+(shelf_selected_controls.input_rotation.y)+`px; left:`+(shelf_selected_controls.input_rotation.x)+`px;`"
                        >
                            <label style="position: absolute; top: -14px; left: 5px; font-size: 16px; user-select: none">rotation</label>
                            (<input class="shelf-input" type="text" pattern="[0-9]*" v-model="shelf_selected._user_r" v-on:input="updateShelfFromManualInput" style="width: 40px; background-color: unset; border: none; text-align: center; user-select: text;">°)</div>

                        <div
                            v-if="drag_mode !== `shelf_rotate`"
                            :style="`z-index: 3; pointer-events: all; cursor: se-resize; font-size: 1.5em; pointer-events: all; position: absolute; top:`+(shelf_selected_controls.control_resize.y)+`px; left:`+(shelf_selected_controls.control_resize.x)+`px;`"
                            @mousedown="drag_mode = `shelf_edge`"
                            @mouseup="drag_mode = null"
                        >   <div style="position: relative;">
                                <i class="mdi mdi-drag" style="font-weight: bold; position: absolute; top: -17px; left: -17px;"></i>
                            </div>
                        </div>

                    <div
                            :style="`z-index: 3; pointer-events: all; cursor: grab; font-size:16px; pointer-events: all; background-color: #FFF; border-radius: 1em; width: 0px; height: 0px; text-align:center; position: absolute; top:`+(shelf_selected_controls.control_rotate.y)+`px; left:`+(shelf_selected_controls.control_rotate.x)+`px;`"
                            @mousedown="drag_mode = `shelf_rotate`"
                            @mouseup="drag_mode = null"
                    >
                        <div :style="`position: relative;
                            width:  `+shelf_selected_controls.control_rotate.s+`px;
                            height: `+shelf_selected_controls.control_rotate.s+`px;
                            top:   -`+shelf_selected_controls.control_rotate.s/2+`px;
                            left:  -`+shelf_selected_controls.control_rotate.s/2+`px;
                            background-color: rgb(67, 69, 138);
                            border-radius: 1em;
                            line-height: `+shelf_selected_controls.control_rotate.s+`px;
                            color: white;
                            font-size: `+(shelf_selected_controls.control_rotate.s-1)+`px;
                        `">
                            <i class="mdi mdi-rotate-left" style="font-weight: bold"></i>
                        </div>
                    </div>
                </template>
                
                <panZoom selector="#scene" id="panzoom" @init="initPanzoom" @zoom="updateLines" @pan="updateVisible" @transform="regenerateShelfSelectedControls" :options="{minZoom: 1, maxZoom: 2000, bounds:true,boundsPadding:0, filterKey: _=> {return true}, onTouch: _=> {return false}}">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        ref="svg"
                        :width="width*(history_display?0.5:1)"
                        :height="height"
                        id="svg-map"
                        style="z-index: 2"
                        @keyup.stop="handleNudge"
                        @dragover="handleDragover"
                        @dragout="drag_x = drag_y = 0"
                        @mousemove="mouseevent(handleDragOldShelf, $event, null)" @mouseup="handleStopDragOldShelf($event, null)"
                    >
                            <g id='scene'>
                                <g id="svg-fix" :transform="`scale(`+warehouse.real_width/warehouse.width+`,`+warehouse.real_height/warehouse.height+`)`">
                                    <image
                                    @mousemove="mouseevent(handleDragOldShelf, $event, null)"
                                        @mouseup="handleStopDragOldShelf($event, null)"
                                        @keyup.stop="handleNudge"
                                        @click="handleDeselectAll"
                                        @mousedown="handleMapSelect"
                                        :xlink:href="signedURL" :width="warehouse.width" :height="warehouse.height" />
                                </g>

                                <g id='elements' :transform="`translate(`+warehouse.real_width * (warehouse.border_left_x+warehouse._user_border_left_x)+`,`+warehouse.real_height * (warehouse.border_left_y+warehouse._user_border_left_y)+`)`">
                                    <rect v-if="active_selection"
                                          :transform="`translate(`+(active_selection.bounds.x + active_selection.delta.x) * warehouse._scale_x+`,`+(active_selection.bounds.y + active_selection.delta.y) * warehouse._scale_y+`)`"
                                          :width="(active_selection.bounds.width) * warehouse._scale_x"
                                          :height="(active_selection.bounds.height) * warehouse._scale_y"
                                          :stroke="`red`"
                                          :fill="`white`"
                                          :style="`opacity:0.2`"
                                          :stroke-width="line_width * 2"
                                          class="selec"
                                    />
                                    <template v-for="(location, index) in warehouse.locations">
                                    <g
                                        v-if="(location._status !== undefined && location._status !== `delete` && location._status !== `ignore`) && (serializing || (
                                        visible_area.top < (location.y + location._batch_y + location.height/2) && visible_area.left < (location.x + location._batch_x + location.width/2)
                                        && visible_area.bottom > (location.y + location._batch_y + location.height/2) && visible_area.right > (location.x + location._batch_x + location.width/2)
                                        ))
                                        "
                                        v-bind:key="location._element_id"
                                    >
                                        <rect
                                            :id="location._element_id"

                                            @mousedown="handleSelectOldShelf($event, location)"
                                            @mouseup="handleStopDragOldShelf($event, location)"
                                            @mousemove="mouseevent(handleDragOldShelf, $event, location)"
                                            @keyup.stop="handleNudge"

                                            :transform="`rotate(`+(parseFloat(location.rotation)+parseFloat(location._batch_r)+360%360)+`,`+(location.x + location._batch_x + (location.width + location._batch_w)/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + (location.height + location._batch_h)/2) * warehouse._scale_y+`) translate(`+(location.x + location._batch_x) * warehouse._scale_x+`,`+(location.y + location._batch_y) * warehouse._scale_y+`)`"
                                            :width="(location.width + location._batch_w) * warehouse._scale_x"
                                            :height="(location.height + location._batch_h) * warehouse._scale_y"
                                            :fill="(location._selected && active_selection.color !== undefined ? active_selection.color :  (location === shelf_selected ? `lightblue` : (location === shelf_selected_nudge ? `pink` : location._fill)))"
                                            :stroke="item_target === location ? `red` : (location._border ? location._border.stroke : `black`)"
                                            :stroke-width="item_target === location ? line_width * 2 : (location._border ? location._border.line_width * line_width : line_width)"
                                            :stroke-dasharray="item_target === location ? `` : (line_width*2 + ` ` +line_width*2)"
                                            :fill-opacity="warehouse.shelf_transparency"
                                            class="shelf"
                                            :data-tabindex="index"
                                        />

                                        <text
                                                v-if="(!drag_mode || drag_obj === location) && item_scale < 0.03 || serializing "
                                                @mousedown="handleSelectOldShelf($event, location)"
                                                @mouseup="handleStopDragOldShelf($event, location)"
                                                @mousemove="mouseevent(handleDragOldShelf, $event, location)"
                                                @keyup.stop="handleNudge"
                                                :transform="`translate(`+(location.x + location._batch_x + location.width/2) * warehouse._scale_x+`,`+(location.y + location._batch_y + location.height/2 + item_scale * 7) * warehouse._scale_y+`) scale(`+item_scale+`,`+item_scale+`)`"
                                                :style="`font-size: 0.5rem; pointer-events: none;`+capabilities.location_select_only?`opacity:0.2`:``"
                                                text-anchor="middle"
                                        >
                                            {{ location.label }}
                                        </text>
                                    </g>
                                    </template>
                                    <template v-if="!capabilities.location_select_only && item_scale < 0.06">
                                    <template v-for="tag in warehouse.tags" v-bind:key="tag._element_id">
                                    <g
                                        v-if="item_selected === tag || tag._status !== `delete` && tag._status !== `ignore` && (serializing || ( visible_area.top < (tag.y + tag._batch_y) && visible_area.left < (tag.x + tag._batch_x) && visible_area.bottom > (tag.y + tag._batch_y) && visible_area.right > (tag.x + tag._batch_x)))"
                                        :transform="`translate(`+((tag.x + tag._batch_x) * warehouse._scale_x - item_scale*12)+`,`+(tag.y + tag._batch_y) * warehouse._scale_y+`)`"
                                        @mousedown="handleSelectOldItem($event, tag)"
                                        @mouseup="handleStopDragOldShelf($event, tag)"
                                        @mousemove="mouseevent(handleDragOldShelf, $event, tag)"
                                        @keyup.stop="handleNudge"
                                        :style="item_mode || tag._location === shelf_selected ? `pointer-events: all;`:`pointer-events: none;`"
                                        :id="tag._element_id"
                                    >
                                        <location-tag
                                            :transform="`scale(`+item_scale+`,`+item_scale+`) rotate(45 12.00000000000001,14.499771118164059)`"
                                            :fill="item_selected === tag ? `red`:`black`"
                                            :_style="item_mode || tag._location === shelf_selected ? `pointer-events: all;`:`pointer-events: none;`"
                                        />
                                        <text
                                            :transform="`scale(`+item_scale+`,`+item_scale+`) translate(12.5,35)`"
                                            style="font-size: 0.8rem; pointer-events: none;"
                                            text-anchor="middle"
                                        >
                                            {{ tag.label }}
                                        </text>
                                    </g>
                                    </template>
                                    </template>


                                    <rect
                                        v-if="drag_mode===`shelf` && drag_obj===null"
                                        :transform="`translate(`+drag_x * warehouse._scale_x +`,`+drag_y * warehouse._scale_y +`)`"
                                        :width="warehouse.default_shelf_width * warehouse._scale_x"
                                        :height="warehouse.default_shelf_height * warehouse._scale_y"
                                        :fill="drag_trash?`red`:`lightgreen`"
                                        stroke="black"
                                        :stroke-width="line_width"
                                        :stroke-dasharray="line_width*2 + ` ` +line_width*2"
                                        style="fill-opacity:0.8;stroke-opacity:0.9"
                                        class="no-panzoom shelf"
                                    />

                                    <g
                                        v-if="drag_mode===`item` && drag_obj===null && drag_x > 0 && drag_y > 0"
                                        :transform="`translate(`+(drag_x * warehouse._scale_x - this.item_scale*12)+`,`+(drag_y * warehouse._scale_y)+`)`"
                                    >
                                        <location-tag
                                            :transform="`scale(`+item_scale+`,`+item_scale+`) rotate(45 12.00000000000001,14.499771118164059)`"
                                        />
                                        <text
                                            :transform="`scale(`+item_scale+`,`+item_scale+`) translate(12.5,35)`"
                                            style="font-size: 0.8rem"
                                            text-anchor="middle"
                                        >
                                            {{ item_next_index }}
                                        </text>
                                    </g>
                                    <rect
                                        v-if="map_selected"
                                        :width="warehouse._scale_fit_x"
                                        :height="warehouse._scale_fit_y"
                                        stroke="#ffcf4c"
                                        fill="#ffcf4c"
                                        fill-opacity="0.4"
                                        :stroke-width="line_width * 3"
                                        @click="handleDeselectMap"
                                        @mouseup="handleStopDragOldShelf($event, null)"
                                    />
                                    <g
                                        v-if="map_selected"
                                    >
                                        <path
                                            v-if="map_selected"
                                            :transform="`translate(-`+line_width+`,-`+line_width+`)`"
                                            :d="`m0,0 l`+handlebar_width+`,0 0,`+(handlebar_width*0.2)+` `+(-handlebar_width*0.8)+`,0 0,`+(handlebar_width*0.8)+` `+(-handlebar_width*0.2)+`,0 0,-`+handlebar_width"
                                            stroke="#ffcf4c"
                                            fill="#fff"
                                            fill-opacity="0.9"
                                            :stroke-width="line_width*2"
                                            @mousemove="handleDragOldShelf($event, null)"
                                            @mouseup="handleStopDragOldShelf($event, null)"
                                            @mousedown="handleMapDrag(`left`)"
                                        />
                                    </g>

                                    <g
                                        v-if="map_selected"
                                        :transform="`translate(`+(warehouse._scale_fit_x+line_width)+`,`+(warehouse._scale_fit_y+line_width)+`)`"
                                    >
                                        <path
                                            :transform="`rotate(180 0,0)`"
                                            :d="`m0,0 l`+handlebar_width+`,0 0,`+(handlebar_width*0.2)+` `+(-handlebar_width*0.8)+`,0 0,`+(handlebar_width*0.8)+` `+(-handlebar_width*0.2)+`,0 0,-`+handlebar_width"
                                            fill="#fff"
                                            fill-opacity="0.9"
                                            stroke="#ffcf4c"
                                            :stroke-width="line_width*2"
                                            @mousemove="handleDragOldShelf($event, null)"
                                            @mouseup="handleStopDragOldShelf($event, null)"
                                            @mousedown="handleMapDrag(`right`)"
                                        />
                                    </g>

                                </g>
                            </g>

                    </svg>
                </panZoom>
            </div>
            <div v-if="history_display" class="map-diff-container" :style="'flex: 1 1 '+(history_display?50:0.000001)+'%'">
                <div class="map-diff" >
                    <div style="height: 42px;">
                        <div class="d-flex mb-2">
                            <!-- <treeselect
                                    v-model="history_value"
                                    :multiple="true" :options="history_data" :load-options="loadHistoryItem"
                                    :auto-load-root-options="false"
                                    :allowSelectingDisabledDescendants="true"
                                    :allowClearingDisabled="true"
                                    @select="selectHistoryItem"
                                    @deselect="deselectHistoryItem"
                                    @close="updateHistoryMap"
                                    ref="treeselect"
                                    :style="'flex: 1 0 '+(width/2-160)+'px;'"
                            /> -->
                            <b-button @click.self="loadSelectedHistory" :disabled="working" variant="secondary" style="flex: 0 0 70px; height: 38px;" class="ml-2">決定</b-button>
                            <div class="snap-check1">
                            <b-form-checkbox
                                    id="compare-checkbox"
                                    v-model="history_compare"
                                    name="checkbox-1"
                                    :value="true"
                                    :unchecked-value="false"
                                    style="flex: 1 0 70px;"
                            >&nbsp;比較
                            </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <floor-map-read-only
                            v-if="warehouse_diff !== null"
                            :floormap="warehouse_diff"
                            :width="width/2-8"
                            :height="height-46"
                    ></floor-map-read-only>
                </div>
            </div>
        </div>
    
    </div>
    
        
    <b-modal v-model="modalErrorAlert" ok-only hideHeader >
        <p>{{modalErrorMsg}}</p>
    </b-modal>
    </div>
</template>


<script>
    import { Warehouse, Location, Tag, Checkpoint, Selection } from '../modal/Warehouse'
    import { Drag, Drop } from 'vue3-drag-drop';
    import LocationTag from "./LocationTag.vue";
    import FloorMapReadOnly from "./FloorMapLegacy.vue";
    import { v1 as uuidv1 } from 'uuid';
    // import Treeselect from 'vue3-treeselect'
    // import 'vue3-treeselect/dist/vue3-treeselect.css'
    // import { LOAD_CHILDREN_OPTIONS } from 'vue3-treeselect'
    import { Storage } from 'aws-amplify';
    import AwsApi from "../../Api"
    import { API } from "aws-amplify";
    import * as mutations from "../../graphql/locus_mutations";
    import global from "../../Global.vue";

    export default {
        components: {
            FloorMapReadOnly,
            LocationTag, Drag, Drop
        },
        props: {
            floormap: Warehouse,
            mode: String,
            bottomId: String,
            bottomShift: {
                type: Number,
                default: 0,
            },
            maxHeight:{
                type: Number,
                default: null,
            },
            locationId:{
                type: String,
                default: null,
            },
        },
        data() {
            return {
                capabilities: {
                    save: true,
                    buttons: true,
                    zoom: true,
                    boundary_only: false,
                    location_select_only: false,
                    zoom_buttons: true,
                    snap: true,
                    counts: true,
                },

                warehouse: null,
                width: 250,
                height: 250,
                offset_x: 0,
                offset_y: 0,
                zoom: null,
                zoom_active: false,
                zoom_timer: null,
                zoom_mode: null,
                line_width: 0.01,
                handlebar_width: 0.01,
                item_scale: 0.001,
                svg: null,
                local: null,
                working: false,
                timer: new Date().getTime(),
                serializing: false,
                count_location: 0,
                count_tag: 0,

                // history
                history_display: false,
                history_data: null,
                history_value: [],
                history_disable_leaf: null,
                history_ignore_deselect: false,
                history_compare: false,
                warehouse_diff: null,

                // this value later gets set with the default zoom level so that we can calculate changes in zoom by percent
                zoom_fit: 1,
                visible_area: {
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                },

                // current #elements coordinates (in meters) of mouse position
                svg_x: 0,
                svg_y: 0,

                // drag_mode determines how changes in the svg mouse position will affect objects
                drag_mode: null,
                drag_obj: null,
                drag_trash: false,
                drag_will_trash: false,
                drag_oob_x: 0,
                drag_oob_y: 0,
                drag_x: 0,
                drag_y: 0,
                drag_item_x: 0,
                drag_item_y: 0,
                drag_offset_x: 0,
                drag_offset_y: 0,
                drag_obj_origin_x: 0,
                drag_obj_origin_y: 0,
                drag_corner: null,
                drag_bounds: null,

                shelf_selected: null,
                shelf_selected_controls: {
                    x: 0,
                    y: 0,
                    w: 0,
                    h: 0,
                    r: 0,
                    c: {x: 0, y: 0},
                },
                shelf_selecting: null,
                shelf_selected_nudge: null,

                map_selecting: null,
                map_selected: false,
                map_select_flag: false,

                // item controls
                item_mode: false,
                item_next_index: 0,
                item_target: null,
                item_selected: null,
                item_selected_label: {label: '', old_label: '', disabled: true, not_found: false, item: null},

                shared_batch: null,
                shared_batch_target: null,
                shared_batch_operation: null,
                shared_batch_on_end: null,

                allow_drag_new: false,
                info_text: null,

                active_selection: null,
                mouse_status: false,
                _timer: null,
                copy_objects: [],
                after_render: [],
                signedURL:"",
                tenantId: "",
                modalErrorAlert: false,
                modalErrorMsg: "",
                maxZoom: 2000
            };
        },
        mounted() {
            this.tenantId = sessionStorage.getItem(global.tenantId);
            // prepare the data for map usage
            this.warehouse = this.floormap
            this.getMap()
            this.warehouse.registerChangeCallback(this.regenerateTotals);
            let _this = this;
            setInterval(function () {
                _this.allow_drag_new = true
            },10)
            if (this.mode === 'boundary') {
                this.capabilities.buttons = false
                this.capabilities.save = false
                this.capabilities.zoom = false
                this.capabilities.boundary_only = true
                this.capabilities.zoom_buttons = true
                this.capabilities.counts = false
            } else if (this.mode === `location_select`) {
                this.capabilities.buttons = false
                this.capabilities.save = false
                this.capabilities.zoom = true
                this.capabilities.zoom_buttons = true
                this.capabilities.location_select_only = true
                this.item_mode = true
                this.capabilities.counts = false
            }
            this.working = true
            setTimeout(this.loadWarehouse, 20)
        },
        watch: {
            locationId: {
                handler(newVal, oldVal) {
                    if (!newVal) newVal = ''
                    newVal = newVal.split(',')
                    this.warehouse.locations.map(_ => {
                        console.log (_, _.id.toString(), newVal)
                        _._search_select = (newVal === _.id)
                    })
                }
            },
            history_compare: {
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.warehouse.diff(this.warehouse_diff)
                    } else if (this.warehouse_diff !== null) {
                        this.warehouse.diff(null)
                        this.warehouse_diff.diff(null)
                    }
                }
            }
        },
        methods: {
            async getMap(){
                if(this.warehouse.upload !== undefined && this.warehouse.upload !== null){
                    if(this.warehouse.upload.includes("private/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(8), { level: 'private' }); 
                    } else if(this.warehouse.upload.includes("protected/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(10), { level: 'protected' }); 
                    } else if(this.warehouse.upload.includes("public/")){
                        this.signedURL = await Storage.get(this.warehouse.upload.slice(7)); 
                    }
                }
            },
            loadSelectedHistory() {
                if (this.history_value.length === 0) {
                    this.warehouse.diff(null)
                    this.warehouse_diff.diff(null)
                    this.warehouse_diff = null
                    return
                }
                let _this = this
                let history_latest = null
                let history_locked = false
                let append = []
                let request = this.history_value.map(_=> {
                    switch (_.substr(0, 1)) {
                        case 'h':
                            if (!history_locked && parseInt(_.substr(1)) > history_latest) {
                                history_latest = parseInt(_.substr(1))
                            }
                            // we need to add all this items to the selected items
                            let hitem = this.history_data.filter(i => i.id === _)[0]
                            // special handling for basis item
                            if (hitem.children.length === 1 && hitem.children[0].id.substr(0,1) === 'b') {
                                return {"type": "history", "id": parseInt(_.substr(1))}
                            }
                            console.log(hitem)

                            hitem.children.map(hchild => {
                                let item = hchild.id.substr(1).split('_')
                                if (!parseInt(item[1])) return
                                append.push({
                                    "type": "location",
                                    "history_id": parseInt(item[0]),
                                    "id": parseInt(item[1]),
                                    "append": true
                                })
                            })
                            return null
                        case 'b':
                            if (history_locked && parseInt(_.substr(1)) > history_latest) {
                                history_latest = parseInt(_.substr(1))
                            } else if (!history_locked) {
                                history_locked = true
                                history_latest = parseInt(_.substr(1))
                            }
                            return null
                            //return {"type": "basis", "id": parseInt(_.substr(1))}
                        case 'l':
                            if (!history_locked && parseInt(_.substr(1)) > history_latest) {
                                history_latest = parseInt(_.substr(1))
                            }
                            let item = _.substr(1).split('_')
                            return {"type": "location", "history_id": parseInt(item[0]), "id": parseInt(item[1])}
                    }
                }).filter(_=>_ !== null)
                if (history_latest) {
                    request.push({"type": history_locked?"history":"basis", "id": history_latest})
                }
                // merge the appended locations
                append.map(_=>request.push(_))
                // axios.post('/api/warehouse/'+this.$route.params.id+'/history', request).then(_=> {
                //     _this.warehouse_diff = new Warehouse(_.data.delta)
                //     if (_this.history_compare) {
                //         _this.warehouse.diff(_this.warehouse_diff)
                //     } else {
                //         _this.warehouse.diff(null)
                //         _this.warehouse_diff.diff(null)
                //     }
                // })
            },
            toggleHistory() {
                this.history_display = !this.history_display
                // setTimeout(this.fitWarehouseToZoom, 10)

                if (this.history_data === null) {
                    // let _this = this
                    // this.working = true
                    // axios
                    //     .get(`/api/warehouse/`+this.warehouse.id+`/history`)
                    //     .then(_=> {
                    //         _this.history_data = _.data.data.map(item => {
                    //             return {
                    //                 id: 'h' + item.id,
                    //                 label: moment(item.created_at).format("YYYY-MM-DD HH:mm"), //+' '+_.data.operation_label.warehouse[item.op.toString()]+' Δ'+ item.id,
                    //                 children: null,
                    //             }
                    //         })
                    //         // This is how to load the first item automatically
                    //         // if (_this.history_data.length > 0) {
                    //         //     _this.history_value = [_this.history_data[0].id]
                    //         //     setImmediate(_=> _this.checkAndLoadChildren(_this.history_data[0]) )
                    //         // }
                    //         _this.working = false
                    //     })
                }

                this.history_compare = false
            },
            loadHistoryItem({ action, parentNode, callback }) {
                // Typically, do the AJAX stuff here.
                // Once the server has responded,
                // assign children options to the parent node & call the callback.

                if (action === LOAD_CHILDREN_OPTIONS) {
                    // axios
                    //     .get(`/api/warehouse/`+this.warehouse.id+`/history/`+parentNode.id.substr(1))
                    //     .then(_=> {
                    //         parentNode.children = _.data.data.location.map(item => {
                    //             console.log(item, _.data.operation_label.location)
                    //             return {
                    //                 id: 'l' + parentNode.id.substr(1) + '_'+ item.id,
                    //                 label: item.label, //+' '+_.data.operation_label.location[item.op.toString()]+' Δ'+parentNode.id.substr(1),
                    //                 // children: item.tag.map(tag => {
                    //                 //     return {
                    //                 //         id: 't' + parentNode.id.substr(1) + '_'+ tag.id,
                    //                 //         label: tag.label, //+' '+_.data.operation_label.tag[tag.op.toString()],
                    //                 //         isDisabled: true,
                    //                 //     }
                    //                 // }),
                    //             }
                    //         });
                    //         if (_.data.data.op === 2) {
                    //             parentNode.children.unshift({
                    //                 id: 'b' + parentNode.id.substr(1),
                    //                 label: '画像入れ替え',// + parentNode.id.substr(1),
                    //             })
                    //         }
                    //         callback()
                    //     })
                    //     .catch(_=>{
                    //         console.log(_)
                    //         callback(new Error('Failed to load options: network error.'))
                    //     })

                }
            },
            checkAndLoadChildren(data_node) {
                // if (data_node.children === null) {
                //     let treeselect = this.$refs.treeselect;
                //     let node = treeselect.getNode(data_node.id)
                //     console.log('load children')
                //     var id = node.id,
                //         raw = node.raw;
                //     treeselect.callLoadOptionsProp({
                //         action: LOAD_CHILDREN_OPTIONS,
                //         args: {
                //             parentNode: raw
                //         },
                //         isPending: function isPending() {
                //             return treeselect.getNode(id).childrenStates.isLoading;
                //         },
                //         start: function start() {
                //             treeselect.getNode(id).childrenStates.isLoading = true;
                //             treeselect.getNode(id).childrenStates.loadingError = '';
                //         },
                //         succeed: function succeed() {
                //             treeselect.getNode(id).childrenStates.isLoaded = true;
                //         },
                //         fail: function fail(err) {
                //             treeselect.getNode(id).childrenStates.loadingError = getErrorMessage(err);
                //         },
                //         end: function end() {
                //             treeselect.getNode(id).childrenStates.isLoading = false;
                //         }
                //     });
                // }
            },
            deselectHistoryItem(node, instanceId) {
                // let type = node.id.substr(0, 1)
                // if (type === 'b' && !this.history_ignore_deselect) {

                //     let _this = this
                //     let treeselect = _this.$refs.treeselect
                //     let fnode = treeselect.getNode(node.id)

                //     console.log('attempt to deselect b')
                //     setImmediate(_=> treeselect.select(fnode) )
                // }
            },
            selectHistoryItem(node, instanceId) {
                // let type = node.id.substr(0,1)
                // let _this = this
                // let treeselect = _this.$refs.treeselect;
                // console.log('selectHistoryItem',node.id, node, this.history_value.length)
                // switch (type) {
                //     case 'h': // history item - deselect other h and b and set this item to h
                //         this.history_value.filter(_=>_!=node.id).map(_=>{
                //             console.log('deselect other', _)
                //             treeselect.select(treeselect.getNode(_))
                //         })
                //         this.history_data.filter(_=>_.id==node.id).map(_=>{
                //             _this.checkAndLoadChildren(_)
                //         })

                //         break;
                //     default:
                //         let letter = type == 'b' ? 'b' : 'l'
                //         this.history_ignore_deselect = true
                //         this.history_value.map(mapped_id=>{
                //             if (mapped_id != node.id &&
                //                 (mapped_id.split('_')[1] === node.id.split('_')[1] && letter == 'l') ||
                //                 (mapped_id.substr(0, 1) === node.id.substr(0, 1) && letter == 'b')
                //             ) {
                //                 if ( mapped_id.substr(0,1)!='h') {
                //                     console.log('deselect cousin', mapped_id)
                //                     this.$refs.treeselect.select(this.$refs.treeselect.getNode(mapped_id))
                //                 }
                //             }
                //             else {
                //                 if (mapped_id.substr(0,1)=='h') {
                //                     // scan the source data
                //                     let hitem = this.history_data.filter(hd=>hd.id == mapped_id)[0]
                //                     let leaf_id = letter == 'b' ? 'b'+hitem.id.substr(1) : 'l'+hitem.id.substr(1)+'_'+node.id.split('_')[1]
                //                     let leaf = treeselect.getNode(leaf_id)
                //                     console.log('deselect leaf ', leaf.id)
                //                     treeselect.select(leaf)
                //                 }
                //             }
                //         })

                //         break;
                // }
                // this.history_ignore_deselect = false
            },
            updateHistoryMap({value, instanceId}) {


            },
            regenerateTotals() {
                this.count_location = this.warehouse.locations.filter(_=>_._status !== `ignore` && _._status !== `delete`).length
                this.count_tag = this.warehouse.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).length

                if (this.history_compare && this.warehouse_diff !== null) {
                    this.warehouse.diff(this.warehouse_diff)
                }
            },
            mouseevent(method, ...parameters) {
                if (new Date().getTime() - this.timer > 30) {
                    this.timer = new Date().getTime()
                    method(...parameters)
                }
            },
            loadWarehouse() {
                let _this = this
                // this.warehouse.shelves.map(_s=>{
                //     _s.locations.map(_l=> {
                //         _l.warehouse = _this.warehouse
                //         _l.shelf = _s
                //         _this.warehouse.locations.push(_l)
                //     })
                // })
                this.regenerateItemNextIndex()
                this.regenerateTotals()
                this.resizeCanvas()
                this.svg = document.getElementById('svg-map')
                this.local = document.getElementById('elements')
                document.onkeydown = function (e) {
                    if (this.shelf_selected_nudge !== null && e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.view.event.preventDefault();
                    }
                }
                if (!this.capabilities.zoom)
                    this.zoom.pause()

                this.map_selected = this.capabilities.boundary_only
                this.working = false

            },
            undo() {
                if (this.active_selection instanceof Selection) {
                    this.active_selection.deselectAll()
                    this.active_selection = null
                }
                Checkpoint.undo(this.warehouse._checkpoints)
                this.regenerateItemNextIndex()
                this.item_selected_label = {label: '', old_label: '', disabled: true, not_found: false, item: null}
                // this.regenerateItemSelectedLabel()
            },
            regenerateItemSelectedLabel(item = null) {
                item = item !== null ? item : this.item_selected
                if (this.item_selected) {
                    this.item_selected_label = {item: item, label: item.label, old_label: item.label, disabled: true, not_found: false}
                }
            },
            toggleItemMode(event) {
                this.item_mode = !this.item_mode
                this.item_selected_label = {label: '', old_label: '', disabled: true, not_found: false, item: null}
                this.handleDeselectAll(event)
            },
            serialize() {
                this.working = true
                let _this = this
                this.serializing = true
                this.handleDeselectAll()
                this.history_compare = false
                this.warehouse_diff = false
                this.map_selected = false
                setTimeout(_=>{
                    let output = this.shallowCopy(this.warehouse, [`id`, `name`,`real_width`,`real_height`,`border_left_x`,`border_left_y`,`border_right_x`,`border_right_y`,`default_shelf_height`,`default_shelf_width`,`number`])
                    output.locations = []
                    output.tags = []
                    this.warehouse.locations.filter(_=>_._status !== `ignore`).map(_=>output.locations.push(_this.shallowCopy(_, [`id`,`x`,`y`,`width`,`height`,`rotation`,`_element_id`,`label`,`number`])))
                    this.warehouse.tags.filter(_=>_._status !== `ignore` && _._status !== `saved`).map(_=>output.tags.push(_this.shallowCopy(_, [`id`,`x`,`y`,`label`,`_location_id`])))
                    output.svg_src = _this.$refs["svg"].outerHTML;
                    AwsApi.checkSession(async () => {
                        
                        const api = [];
                        const tagApi = [];
                        api.push(API.graphql({
                            query: mutations.updateWarehouse,
                            variables: {
                                input: {
                                    tenantId: this.tenantId,
                                    id: this.warehouse.id,
                                    name: this.warehouse.name,
                                    width: this.warehouse.width,
                                    height: this.warehouse.height,
                                    upload: this.warehouse.upload,
                                    real_width: this.warehouse.real_width,
                                    real_height: this.warehouse.real_height,
                                    default_shelf_width: this.warehouse.default_shelf_width,
                                    default_shelf_height: this.warehouse.default_shelf_height
                                }
                            }
                        }));

                        const element_to_shelf = {}
                        output.locations.forEach(item =>{
                            switch(item._status){
                                case 'delete':
                                    api.push(API.graphql({
                                        query: mutations.deleteLocation,
                                        variables: {
                                            input: {
                                                tenantId: this.tenantId,
                                                id: item.id,
                                            }
                                        }
                                    }));
                                    break;
                                case 'new':
                                    api.push(API.graphql({
                                        query: mutations.generateAutoNumber,
                                        variables: {
                                            numberKey: "location"
                                        }
                                    }).then(response => {
                                        var maxLocationId = 1
                                        if(response !== undefined){
                                            maxLocationId = response.data.generateAutoNumber
                                        }
                                        const createParam = {
                                                    id: maxLocationId,
                                                    group: this.tenantId,
                                                    tenantId: this.tenantId,
                                                    height: parseFloat(item.height.toFixed(4)),
                                                    label: item.label, 
                                                    rotation: parseFloat(item.rotation.toFixed(1)), 
                                                    warehouseId: this.warehouse.id, 
                                                    width: parseFloat(item.width.toFixed(4)), 
                                                    x: parseFloat(item.x.toFixed(4)), 
                                                    y: parseFloat(item.y.toFixed(4)),
                                                    number: item.number
                                                }
                                        API.graphql({
                                            query: mutations.createLocation,
                                            variables: {
                                                input: createParam
                                            }
                                        });
                                        element_to_shelf[item._element_id] = maxLocationId
                                    }))
                                    break;
                                case 'change':
                                    api.push(API.graphql({
                                        query: mutations.updateLocation,
                                        variables: {
                                            input: {
                                                tenantId: this.tenantId,
                                                id: item.id, 
                                                width: parseFloat(item.width.toFixed(4)), 
                                                height: parseFloat(item.height.toFixed(4)),
                                                label: item.label, 
                                                rotation: parseFloat(item.rotation.toFixed(1)), 
                                                x: parseFloat(item.x.toFixed(4)), 
                                                y: parseFloat(item.y.toFixed(4))
                                            }
                                        }
                                    }));
                                    element_to_shelf[item._element_id] = item.id
                                    break;
                                case 'saved':
                                    element_to_shelf[item._element_id] = item.id
                                    break;

                            }
                        })
                        
                        Promise.all([...api]).then(() => {
                            output.tags.forEach(async item =>{
                            switch(item._status){
                                case 'delete':
                                    tagApi.push(API.graphql({
                                        query: mutations.deleteLocationTag,
                                        variables: {
                                            input: {
                                                tenantId: this.tenantId,
                                                id: item.id
                                            }
                                        }
                                    }));
                                    break;
                                case 'new':
                                    tagApi.push(API.graphql({
                                        query: mutations.generateAutoNumber,
                                        variables: {
                                            numberKey: "locationTag"
                                        }
                                    }).then(responseTag => {
                                        var maxLocationTagId = 1
                                        if(responseTag !== undefined){
                                            maxLocationTagId = responseTag.data.generateAutoNumber
                                        }
                                        
                                        const createParam = {
                                                    id: maxLocationTagId,
                                                    group: this.tenantId,
                                                    tenantId: this.tenantId, 
                                                    warehouseId: this.warehouse.id, 
                                                    location_id: element_to_shelf[item._location_id], 
                                                    x: parseFloat(item.x.toFixed(4)), 
                                                    y: parseFloat(item.y.toFixed(4)),
                                                    epc: this.warehouse.makeLocationTag(this.warehouse.location_tag_filter,item.label)
                                                }
                                        API.graphql({
                                            query: mutations.createLocationTag,
                                            variables: {
                                                input: createParam
                                            }
                                        });
                                    }));
                                    break;
                                case 'change':
                                    tagApi.push(API.graphql({
                                        query: mutations.updateLocationTag,
                                        variables: {
                                            input: {
                                                tenantId: this.tenantId, 
                                                warehouseId: this.warehouse.id, 
                                                id: item.id, 
                                                location_id: element_to_shelf[item._location_id], 
                                                x: parseFloat(item.x.toFixed(4)), 
                                                y: parseFloat(item.y.toFixed(4)),
                                                epc: this.warehouse.makeLocationTag(this.warehouse.location_tag_filter,item.label)
                                            }
                                        }
                                    }));
                                    break;
                            }})
                            Promise.all([...tagApi]).then(() => {
                                this.warehouse._checkpoints = []
                                this.working = false
                                this.serializing = false
                                setTimeout(_=> {
                                    this.$emit('saved')
                                },200)
                            })
                        })
                    }, this)
                }, 1000)
            },
            shallowCopy(source, only) {
                let keys = Object.keys(source)
                let output = {}
                only.push(`_status`)
                for (let i = 0; i < keys.length; i++) {
                    if (only.indexOf(keys[i]) === -1) continue
                    output[keys[i]] = source[keys[i]]
                }
                return output
            },
            updateBounds() {
                this.warehouse.border_left_x  = parseFloat(this.warehouse._user_border_left_x)
                this.warehouse.border_left_y  = parseFloat(this.warehouse._user_border_left_y)
                this.warehouse.border_right_x = parseFloat(this.warehouse._user_border_right_x)
                this.warehouse.border_right_y = parseFloat(this.warehouse._user_border_right_y)
                this.warehouse.calculateScale()
            },
            changeDefaultSizes() {
                this.warehouse.default_shelf_width = parseFloat(this.warehouse._default_shelf_width)
                this.warehouse.default_shelf_height = parseFloat(this.warehouse._default_shelf_height)
            },
            updateLabel(val) {
                console.log('updateLabel', this.item_selected_label.item === null, ''+val === ''+this.item_selected_label.old_label)
                this.item_selected_label.disabled = this.item_selected_label.item === null || ''+val === ''+this.item_selected_label.old_label
                this.item_selected_label.label = val
            },
            changeLabel(e) {
                this.onAdjust(this, true)
            },
            searchLabel(e) {
                console.log("searchLabel")
                let _this = this
                const tag = this.warehouse.tags
                    .filter(_=>_._status !== `ignore` && _._status !== `delete`)
                    .filter(tag=>''+tag.label == this.item_selected_label.label)
                    .map(tag=>{
                        _this.item_selected = tag
                        setTimeout(()=>{
                            _this.zoom.centerOn(document.getElementById(tag._element_id))
                        },100)
                        _this.regenerateItemSelectedLabel()
                        return tag
                    })
                if(tag.length === 0){
                    this.item_selected_label.not_found = true
                    this.modalErrorAlert = true
                    this.modalErrorMsg = "Tag ID not found."
                }
            },
            handleDeselectMap(event) {
                if (!this.capabilities.boundary_only)
                    this.map_selected = false
            },
            handleDeselectAll(event) {
                console.log('deselect')
                this.info_text = null
                this.clearMapSelecting(event)
                this.shelf_selected_nudge = null
                this.drag_obj = null
                this.shelf_selected = null
                this.shelf_selecting = null
                this.item_selected = null

                this.drag_mode = null
                this.drag_trash = false
                this.drag_oob_x = 0
                this.drag_oob_y = 0

                this.clearSharedBatch()
                if (this.capabilities.zoom)
                    this.zoom.resume()

                if (this.active_selection instanceof Selection) {
                    this.commitSelection()
                    this.active_selection = null
                }

                this.$emit('deselectall')
            },
            clearSharedBatch() {
                if (this.shared_batch_on_end !== null && this.shared_batch_on_end !== undefined) {
                    this.shared_batch_on_end(this)
                }

                this.shared_batch_target = null
                this.shared_batch_operation = null
                this.shared_batch_on_end = null
                this.shared_batch = null
            },
            getSharedBatch(target, operation, handler) {
                if (target !== this.shared_batch_target || operation !== this.shared_batch_operation) {
                    if (this.shared_batch_on_end !== null && this.shared_batch_on_end !== undefined) {
                        this.shared_batch_on_end(this)
                    }
                    console.log('Target becomes ', target)
                    this.shared_batch_target = target
                    this.shared_batch_operation = operation
                    this.shared_batch_on_end = handler
                    this.shared_batch = uuidv1()
                }
                return this.shared_batch
            },
            handleMapSelect(event) {
                if (this.capabilities.location_select_only) return
                if (this.map_selected) return
                console.log('handleMapSelect')
                this.map_selecting = {
                    x: event.x - 20,
                    y: event.y - 20
                }

                this.svgPositionListener(event)

                let _this = this
                if (this.warehouse._timer !== null)
                    clearTimeout(this.warehouse._timer)
                this.warehouse._timer = setTimeout(function () {
                    _this.map_selected = true
                    _this.map_select_flag = true
                    _this.map_selecting = null
                    console.log('MapSelected')
                }, 700)
            },
            handleMapDrag(corner) {
                this.drag_corner = corner
                this.drag_mode = `map_resize`
                this.drag_bounds = this.svgRelativeBounds()
                if (this.capabilities.zoom)
                    this.zoom.pause()
            },
            onAdjust(_this, handleLabel = false) {
                console.log('Target is ', this.shared_batch_target)
                let target = this.shared_batch_target
                if (!target) {
                    console.log('Target is null, not ready to commit change')
                    return
                }
                let delta = {
                    _status: `change`,
                    _batch: this.shared_batch,
                    x: target.x + target._batch_x,
                    y: target.y + target._batch_y,
                }
                target._batch_x = 0
                target._batch_y = 0

                if (target instanceof Location) {
                    delta.width = target.width + target._batch_w
                    delta.height = target.height + target._batch_h
                    delta.rotation = (360 + target.rotation + target._batch_r) % 360
                    target._batch_w = 0
                    target._batch_h = 0
                    target._batch_r = 0

                    let location_delta = {
                        _status: `change`,
                        _batch: this.shared_batch,
                    }
                    target.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
                        location_delta.x = tag.x + tag._batch_x
                        location_delta._batch_x = 0
                        location_delta.y = tag.y + tag._batch_y
                        location_delta._batch_y = 0
                        tag.update(location_delta, `adjust`)
                    })
                }

                if (handleLabel && target instanceof Tag && target.label !== this.item_selected_label.label) {
                    let new_label = this.item_selected_label.label.trim()
                    let already_taken = this.warehouse.tags.filter(_=>
                        _.label == new_label && _._element_id !== _this.item_selected_label.item._element_id
                    );
                    if (already_taken.length) {
                        this.modalErrorAlert = true
                        this.modalErrorMsg = "Use a different tag name."
                        return
                    }
                    if (!/^\d+$/.test(new_label)) {
                        this.modalErrorAlert = true
                        this.modalErrorMsg = "Tag name must be an integer."
                        return
                    }
                    delta.label = parseInt(new_label)
                }

                target.update(delta, `adjust`)
                if (delta.label !== undefined) {
                    this.regenerateItemNextIndex()
                    // it might be necessary to clear shared batch in the future but right now probably okay
                    this.regenerateItemSelectedLabel(target)
                }
            },
            handleSelectNeighbors(event, target) {
                console.log('handleSelectNeighbors')
                // if (this.active_selection === null) {
                //     console.log('null')
                //     if (this.event !== null) {
                //         let _this = this
                //         setTimeout(_=>{
                //             _this.handleSelectNeighbors(null, null)
                //         },100)
                //     }
                //     return
                // }
                // console.log('not null')

                let coordinates = this.active_selection.bounds
                let offset = 0.02
                let start = this.active_selection.objects.length
                this.warehouse.locations
                // filter all the neighbors and use only the ones within the proximity of the object we are moving
                // the filter is based on the mouse coordinates and not those of the selection
                    .filter(loc=> {
                        if (
                            loc._status !== `ignore` && loc._status !== `delete`
                            && this.active_selection.objects.indexOf(loc) === -1
                            &&
                            (
                                loc.x > coordinates.x - offset
                                && loc.y > coordinates.y - offset
                                && loc.x < coordinates.x + offset*2 + coordinates.width
                                && loc.y < coordinates.y + offset*2 + coordinates.height
                                ||
                                loc.x + loc.width > coordinates.x - offset
                                && loc.y + loc.height  > coordinates.y - offset
                                && loc.x + loc.width  < coordinates.x + offset*2 + coordinates.width
                                && loc.y + loc.height  < coordinates.y + offset*2 + coordinates.height
                            )
                        ) {
                            return true
                        }
                    })
                    .map(_=> console.log(this.active_selection.add(_)))

                if (start !== this.active_selection.objects.length) this.handleSelectNeighbors(event, target)
            },
            handleNudge(event) {
                let target = this.shelf_selected_nudge || this.item_selected
                if (target === null) {
                    console.log(this.mouse_status,"mouse_status")
                    console.log(this.active_selection,"active_selection")
                    if (this.mouse_status) return // mouse should not be pressed
                    if (this.active_selection === null || this.active_selection.length === 0) return

                    // new selection handler
                    event.preventDefault()

                    // we need to make sure the selection has an offset of 0, 0
                    // let coordinates = this.active_selection.bounds
                    // let bounds = {x: 0, y: 0, width: this.warehouse.real_width, height: this.warehouse.real_height}
                    //
                    // //this.active_selection.check(coordinates, false) // make sure the selection has mouse offset
                    //
                    // // offset must be kept 0
                    // coordinates.x += this.active_selection.offset.x
                    // coordinates.y += this.active_selection.offset.y
                    // this.active_selection.offset.x = 0
                    // this.active_selection.offset.y = 0
                    //
                    //
                    // // coordinates.x += this.active_selection.delta.x
                    // // coordinates.y += this.active_selection.delta.y
                    //
                    // console.log('bounds', this.active_selection.bounds.x, this.active_selection.bounds.y)
                    // console.log('offset', this.active_selection.offset.x, this.active_selection.offset.y)
                    // console.log('delta', this.active_selection.delta.x, this.active_selection.delta.y)
                    // console.log('coordinates', coordinates.x, coordinates.y)

                    this.active_selection.check(this.active_selection.bounds, true)
                    let coordinates = this.active_selection.delta
                    let change = {x: 0, y: 0}
                    // determine keypress delta
                    switch (event.key) {
                        case 'ArrowUp':
                            change.y -= 0.1
                            break;
                        case 'ArrowDown':
                            change.y += 0.1
                            break;
                        case 'ArrowLeft':
                            change.x -= 0.1
                            break;
                        case 'ArrowRight':
                            change.x += 0.1
                            break;
                    }

                    if (this.active_selection.origin.x + change.x < 0) return
                    if (this.active_selection.origin.y + change.y < 0) return
                    if (this.active_selection.origin.x + change.x + this.active_selection.bounds.width > this.warehouse.real_width) return
                    if (this.active_selection.origin.y + change.y + this.active_selection.bounds.height > this.warehouse.real_height) return

                    this.active_selection.delta.x += change.x
                    this.active_selection.delta.y += change.y
                    this.active_selection.bounds.x += change.x
                    this.active_selection.bounds.y += change.y
                    this.active_selection.origin.x += change.x
                    this.active_selection.origin.y += change.y


                    Selection.update_delta(this.active_selection.objects, this.active_selection.delta)
                    this.active_selection.commit(this.getSharedBatch(0,`nudge`))
                    this.active_selection.delta.x = 0
                    this.active_selection.delta.y = 0

                    return

                    // use the translate function because it does all the hard work
                    coordinates = this.translateDrag(coordinates, this.active_selection, bounds)
                    this.active_selection.drag(coordinates)
                    this.active_selection.commit(this.getSharedBatch(0,`nudge`))
                    return
                }
                // old item only handler
                event.preventDefault()

                let container = {
                    x: 0,
                    y: 0,
                    width: this.warehouse.real_width,
                    height: this.warehouse.real_height,
                }
                if (target instanceof Tag) {
                    container = target._location
                }
                this.getSharedBatch(target, `nudge`, this.onAdjust)

                let delta = {
                    x: target.x + target._batch_x,
                    y: target.y + target._batch_y
                }
                if (event.key === 'ArrowUp') {
                    if (target.y - 0.1 < container.y) {
                        delta.y = container.y
                    } else {
                        delta.y = target.y + target._batch_y - 0.1
                    }
                }
                else if (event.key === 'ArrowDown') {
                    if (target.y + 0.1 > container.y + container.height) {
                        delta.y = container.y + container.height
                    } else {
                        delta.y = target.y + target._batch_y + 0.1
                    }
                }
                else if (event.key === 'ArrowLeft') {
                    if (target.x - 0.1 < container.x) {
                        delta.x = container.x
                    } else {
                        delta.x = target.x + target._batch_x - 0.1
                    }
                }
                else if (event.key === 'ArrowRight') {
                    if (target.x + 0.1 > container.x + container.width) {
                        delta.x = container.x + container.width
                    } else {
                        delta.x = target.x + target._batch_x + 0.1
                    }
                }
                target._batch_x = delta.x - target.x
                target._batch_y = delta.y - target.y

                if (target instanceof Location) {
                    target.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
                        tag._batch_x = target._batch_x
                        tag._batch_y = target._batch_y
                    })
                }
                //target.update(delta, 'nudge')
                return false
            },
            handleOldShelfDelete (event) {
                console.log('handleOldShelfDelete')
                this.drag_will_trash = false
                if (this.active_selection === null && this.drag_mode !== `item_drag`) {
                    return
                }
                if (this.active_selection !== null) {
                    this.active_selection.delete(uuidv1())
                    this.active_selection = null

                    this.handleDeselectAll()
                    this.regenerateItemNextIndex()
                    return
                }

                let delta = {
                    _status: `delete`,
                    _batch_x: this.drag_obj._batch_x,
                    _batch_y: this.drag_obj._batch_y,
                    _batch: uuidv1()
                }
                if (this.drag_obj instanceof Tag && this.shelf_selected !== null) {
                    delta._batch_mix = true
                    delta._batch = this.shared_batch || (this.shared_batch = uuidv1())
                }
                this.drag_obj._batch_x = 0
                this.drag_obj._batch_y = 0
                if (this.drag_obj instanceof Location) {
                    this.drag_obj.tags.filter(_=>_._status !== `ignore`).map(_=> {
                        _._batch_x = 0
                        _._batch_y = 0
                        _.update(delta,`trash`)
                    })
                }

                this.drag_obj.update(delta, `trash`)

                this.item_target = null
                this.handleDeselectAll()
                this.regenerateItemNextIndex()
            },
            handleOldShelfDeleteIndicator (cancel, event) {
                if (cancel || this.active_selection === null && this.drag_mode !== `item_drag`) {
                    this.drag_will_trash = false
                    if (this.active_selection !== null)
                        this.active_selection.color = null
                    return
                }

                if (this.active_selection !== null)
                    this.active_selection.color = `red`
                this.drag_will_trash = true
            },
            handleDragNewShelf(event, type) {
                this.handleDeselectAll()
                this.item_mode = false
                this.drag_mode = type
                this.drag_obj = null
                this.drag_x = 0
                this.drag_y = 0
                this.shelf_selected = null
                this.drag_offset_x = this.warehouse.default_shelf_width / 2
                this.drag_offset_y = this.warehouse.default_shelf_height / 2
                if (this.capabilities.zoom)
                    this.zoom.pause()
            },
            handleSelectOldItem(event, item) {
                if (this.capabilities.location_select_only) return
                // only allow this item to be manipulated if in item edit more or the item is in the currently selected shelf
                if (!this.item_mode && item._location !== this.shelf_selected) return
                this.drag_mode = `item_select`

                this.drag_obj = item
                if (this.capabilities.zoom)
                    this.zoom.pause()

                // force the item to appear on the top of all other items
                this.warehouse.tags.splice(this.warehouse.tags.indexOf(item), 1)
                this.warehouse.tags.push(item)

                this.clearSharedBatch()
                this.regenerateItemSelectedLabel(item)
            },
            commitSelection() {
                // we need to close out the object
                this.active_selection.commit(uuidv1())
                this.active_selection.deselectAll()
            },
            handleSelectOldShelf(event, shelf, fast = false) {
                if (shelf instanceof Location && !this.item_mode)
                    this.info_text = shelf.info

                if (this.mouse_status) {
                    //if (event.shiftKey) this.handleSelectNeighbors(null, null)
                    return
                }
                if (fast) return
                this.mouse_status = true
                if (this.item_mode) return

                if (!this.active_selection)
                    this.active_selection = new Selection(this.warehouse, [shelf])
                else if (this.active_selection.objects.indexOf(shelf) !== -1) {
                    // we need to update the offset
                    this.svgPositionListener(event)
                    this.active_selection.drag({x: this.svg_x, y: this.svg_y}, true)
                    return
                }

                console.log('handleSelectOldShelf', event)

                // try to add the objects
                let res = this.active_selection.add(shelf)

                if (res === Selection.RES_COMMIT_ADD) {
                    let objs = this.active_selection.objects
                    this.commitSelection()
                    // make a new object
                    this.active_selection = new Selection(this.warehouse, [shelf, ...objs])
                    objs = null
                } else if (res === Selection.RES_COMMIT_NEW) {
                    this.commitSelection()
                    // make a new object without copying
                    this.active_selection = new Selection(this.warehouse, [shelf])
                }

                console.log('Selection res is '+res)

                if (event.shiftKey && event.type === "mousedown") this.handleSelectNeighbors(null, null)

                // there is no need to do anything else
                if (res !== Selection.RES_OKAY_NO_CHANGE) {

                    // fire the legacy selectlocation event
                    this.$emit('selectlocation', shelf)

                    // determine the origin
                    this.svgPositionListener(event)
                    this.active_selection.drag({x: this.svg_x, y: this.svg_y}, true)

                    // pause the pan functionality
                    if (this.capabilities.zoom)
                        this.zoom.pause()

                    // resort locations to make sure selection is on top
                    this.warehouse.locations.splice(this.warehouse.locations.indexOf(shelf), 1)
                    this.warehouse.locations.push(shelf)
                }
                // skip the legacy code -- it doesnt need to run



                // old batch reset
                this.clearSharedBatch()

                if (event.shiftKey) return

                //old resize selection method
                this.shelf_selecting = {
                    x: event.x - 20,
                    y: event.y - 20
                }
                this.shelf_selected = null
                let _this = this
                this._timer = setTimeout(function () {
                    _this.active_selection.deselectAll()
                    _this.active_selection = null
                    _this.shelf_selected = shelf
                    _this.shelf_selecting = null
                    _this.shelf_selected_nudge = null
                    _this.shelf_selected._user_x = _this.shelf_selected.x + _this.shelf_selected._batch_x
                    _this.shelf_selected._user_y = _this.shelf_selected.y + _this.shelf_selected._batch_y
                    _this.shelf_selected._user_w = _this.shelf_selected.width + _this.shelf_selected._batch_w
                    _this.shelf_selected._user_h = _this.shelf_selected.height + _this.shelf_selected._batch_h
                    _this.shelf_selected._user_r = _this.shelf_selected.rotation + _this.shelf_selected._batch_r
                    _this.regenerateShelfSelectedControls()
                }, 700)



            },
            regenerateShelfSelectedControls() {
                if (this.shelf_selected === null) return
                let ele = document.getElementById(this.shelf_selected._element_id).getBoundingClientRect()
                let rotation = (360 + parseFloat(this.shelf_selected.rotation) + parseFloat(this.shelf_selected._batch_r)) % 360

                let distance = (
                    Math.sqrt(
                        Math.pow((this.shelf_selected.width+this.shelf_selected._batch_w)* this.warehouse._scale_x,2) +
                        Math.pow((this.shelf_selected.height+this.shelf_selected._batch_h)* this.warehouse._scale_y,2)
                    ) * 0.5
                )  / this.line_width


                let center = {x: ele.x + ele.width/2, y: ele.y + ele.height/2}
                let max = distance
                let input_max = max + 32

                let control_rotate = {
                    x: center.x,
                    y: center.y - (this.shelf_selected.height+this.shelf_selected._batch_h) * this.warehouse._scale_y/2/ this.line_width - 16,
                    s: 16, // the w/h of the rotate tool control
                }
                // rotate the control around center
                control_rotate = this.rotate_point(center.x, center.y, rotation * Math.PI / 180, control_rotate)

                let control_resize = {
                    x: center.x + (this.shelf_selected.width+this.shelf_selected._batch_w)/2 * this.warehouse._scale_x / this.line_width,
                    y: center.y + (this.shelf_selected.height+this.shelf_selected._batch_h)/2 * this.warehouse._scale_y / this.line_width,
                }

                let input_size = {x: center.x + input_max, y: center.y - input_max}
                let input_rotation = {x: center.x + input_max, y: center.y - input_max + 40}
                let input_position = {x: center.x - input_max, y: center.y + input_max}

                this.shelf_selected_controls = {
                    // x: ele.x,
                    // y: ele.y,
                    // w: ele.width,
                    // h: ele.height,
                    r: rotation,
                    c: center,
                    m: max,
                    control_rotate: control_rotate,
                    control_resize: control_resize,
                    input_size: input_size,
                    input_rotation: input_rotation,
                    input_position: input_position,
                }
            },
            distance(x1, y1, x2, y2)
            {
                // Calculating distance
                return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
            },

            rotate_point(cx, cy, angle, p) {
                let s = Math.sin(angle)
                let c = Math.cos(angle)

                // translate point back to origin:
                p.x -= cx
                p.y -= cy

                // rotate point
                let xnew = p.x * c - p.y * s
                let ynew = p.x * s + p.y * c

                // translate point back:
                p.x = xnew + cx
                p.y = ynew + cy
                return p
            },
            updateShelfFromManualInput() {
                if (this.capabilities.location_select_only) return
                let _this = this
                this.getSharedBatch(this.shelf_selected, `adjust`, this.onAdjust)
                setTimeout(_=> {
                    if (!isNaN(parseFloat(this.shelf_selected._user_x))) {
                        this.shelf_selected._batch_x = this.shelf_selected._user_x - this.shelf_selected.x
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_y))) {
                        this.shelf_selected._batch_y = this.shelf_selected._user_y - this.shelf_selected.y
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_x))) {
                        this.shelf_selected._batch_w = this.shelf_selected._user_w - this.shelf_selected.width
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_h))) {
                        this.shelf_selected._batch_h = this.shelf_selected._user_h - this.shelf_selected.height
                    }
                    if (!isNaN(parseFloat(this.shelf_selected._user_r))) {
                        this.shelf_selected._batch_r = this.shelf_selected._user_r - this.shelf_selected.rotation
                    }

                    setTimeout(_=> {
                        _this.regenerateShelfSelectedControls()
                        _this.shelf_selected.tags.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(tag => {
                            tag._batch_x = this.shelf_selected._batch_x
                            tag._batch_y = this.shelf_selected._batch_y
                        })
                    }, 20)
                },20)

            },
            clearMapSelecting(event) {
                // clear map selecting

                if (this.warehouse._timer) {
                    console.log('stop MapSelect')
                    clearTimeout(this.warehouse._timer)
                    this.warehouse._timer = null
                }

                if (this._timer) {
                    console.log('stop ShelfSelect')
                    clearTimeout(this._timer)
                    this._timer = null
                }

                this.shelf_selecting = null
                this.map_selecting = null
            },
            handleDragOldShelf(event, shelf) {

                if (this.capabilities.location_select_only) return
                this.clearMapSelecting(event)

                if (this.drag_mode === `map_resize`) {
                    this.svgPositionListenerMap(event)
                    let cursor_x = this.svg_x / this.warehouse._scale_x
                    let cursor_y = this.svg_y / this.warehouse._scale_y
                    let range_x = this.warehouse.real_width / this.warehouse._scale_x
                    let range_y = this.warehouse.real_height / this.warehouse._scale_y

                    let distance_x = 0
                    let distance_y = 0

                    switch(this.drag_corner) {
                        case `left`:
                            // determine percentage distance from edge - constrain to left upper side
                            distance_x = Math.min(Math.max(cursor_x / range_x, 0), 0.49 )
                            distance_y = Math.min(Math.max(cursor_y / range_y, 0), 0.49 )

                            this.warehouse._user_border_left_x = (distance_x - this.warehouse.border_left_x)
                            this.warehouse._user_border_left_y = (distance_y - this.warehouse.border_left_y)
                            break;
                        case `right`:
                            // determine percentage distance from edge - constrain to right lower side
                            distance_x = Math.min(Math.max(1 - cursor_x / range_x, 0), 0.49 )
                            distance_y = Math.min(Math.max(1 - cursor_y / range_y, 0), 0.49 )

                            this.warehouse._user_border_right_x = (distance_x - this.warehouse.border_right_x)
                            this.warehouse._user_border_right_y = (distance_y - this.warehouse.border_right_y)
                            break;
                    }

                    this.warehouse.calculateScale()

                    return
                }
                if (this.drag_mode === `item_select`) {
                    // allow the item to be promoted to drag state
                    this.drag_mode = `item_drag`
                    this.drag_obj_origin_x = this.drag_obj.x
                    this.drag_obj_origin_y = this.drag_obj.y
                }
                if (this.drag_mode === `item_drag`) {
                    this.svgPositionListener(event)
                    this.item_selected = null
                    if (this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                        this.drag_obj._batch_x = 0
                        this.drag_obj._batch_y = 0
                        this.drag_trash = true
                        this.drag_oob_x = event.pageX
                        this.drag_oob_y = event.pageY
                    } else {
                        this.drag_trash = false
                        this.drag_oob_x = 0
                        this.drag_oob_y = 0
                        this.drag_obj._batch_x = this.svg_x - this.drag_obj.x
                        this.drag_obj._batch_y = this.svg_y - this.drag_obj.y
                        let _this = this
                        let _did_set = false
                        this.warehouse.locations.filter(_=>_._status !== `ignore` && _._status !== `delete`).map(s => {
                            if (s.x < _this.svg_x && s.y < _this.svg_y && s.x + s.width > _this.svg_x && s.y + s.height > _this.svg_y) {
                                _this.item_target = s
                                _did_set = true
                            }
                        })
                        if (!_did_set) _this.item_target = null

                    }
                    return
                }
                if (this.item_mode) return
                // document.getElementById('map-container').focus()
                if (this.drag_mode === `shelf_edge`) {
                    this.getSharedBatch(this.shelf_selected, `adjust`, this.onAdjust)
                    this.svgPositionListener(event)
                    let new_x = (this.svg_x - this.shelf_selected.x).toFixed(2)
                    let new_y = (this.svg_y - this.shelf_selected.y).toFixed(2)

                    let max_x = (this.warehouse.real_width - this.shelf_selected.x).toFixed(2)
                    let max_y = (this.warehouse.real_height - this.shelf_selected.y).toFixed(2)

                    this.shelf_selected._batch_w = Math.min(new_x, max_x) - this.shelf_selected.width
                    this.shelf_selected._batch_h = Math.min(new_y, max_y) - this.shelf_selected.height

                    this.shelf_selected._user_w = this.shelf_selected.width + this.shelf_selected._batch_w
                    this.shelf_selected._user_h = this.shelf_selected.height + this.shelf_selected._batch_h

                    this.regenerateShelfSelectedControls()

                    return
                }

                if (this.drag_mode === `shelf_rotate`) {
                    if (window.getSelection) {window.getSelection().removeAllRanges();}
                    else if (document.selection) {document.selection.empty();}

                    this.getSharedBatch(this.shelf_selected, `adjust`, this.onAdjust)
                    this.svgPositionListener(event)

                    if (this.shelf_selected === null) return

                    let center = {
                        x: this.shelf_selected.x + this.shelf_selected._batch_x + (this.shelf_selected.width + this.shelf_selected._batch_w) / 2,
                        y: this.shelf_selected.y + this.shelf_selected._batch_y + (this.shelf_selected.height + this.shelf_selected._batch_h) / 2,
                    }

                    let new_rotation = Math.atan((this.svg_y - center.y) / (this.svg_x - center.x)) * 180 / Math.PI + 90
                    if (this.svg_x - center.x < 0) {
                        new_rotation += 180
                    }

                    new_rotation = Math.round(new_rotation)
                    new_rotation %= 360

                    this.shelf_selected._batch_r = (360 + new_rotation - this.shelf_selected.rotation)%360
                    this.shelf_selected._user_r = (360 + this.shelf_selected.rotation + this.shelf_selected._batch_r)%360

                    this.regenerateShelfSelectedControls()

                    return
                }

                // we need to make sure the selection is compatible
                if (!this.active_selection || !(this.active_selection.objects[0] instanceof Location)) return

                // make sure the selection event was fired by calling the handler in fast mode

                if (!this.mouse_status) return
                if (this.capabilities.zoom)
                    this.zoom.pause()

                if (shelf instanceof Location)
                    this.handleSelectOldShelf(event, shelf)

                //if (shelf == null) return

                this.svgPositionListener(event)
                if (this._timer !== undefined) {
                    clearTimeout(this._timer)
                    this.shelf_selecting = null
                    this._timer = null
                    this.shelf_selected_nudge = null
                }

                let coordinates = {x: this.svg_x, y: this.svg_y}
                let bounds = {x: 0, y: 0, width: this.warehouse.real_width, height: this.warehouse.real_height}
                this.active_selection.check(coordinates, false) // make sure the selection has mouse offset

                coordinates = this.translateDrag(coordinates, this.active_selection, bounds,
                    this.warehouse.locations.filter(_=>
                        _._status !== `ignore`
                        && _._status !== `delete`
                        && this.active_selection.objects.indexOf(_) === -1
                    ))
                this.active_selection.drag(coordinates)

                // if the shelf is out of the map area it needs to be trashable
                if (coordinates.oob < 2) {
                    // enable trashing this item
                    this.drag_trash = true
                    this.drag_oob_x = event.pageX
                    this.drag_oob_y = event.pageY
                } else {
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                }

                // this will update the child objects so that they move with the shelf
                // shelf.tags.map(tag => {
                //     tag._batch_x = shelf._batch_x
                //     tag._batch_y = shelf._batch_y
                // })
            },
            translateDrag(coordinates, selection, bounds, neighbors = []) {
                //console.log(coordinates, selection, bounds)

                // these are the coordinates normalized to the upper left corner of the selection
                // let coord_off = {x: coordinates.x + selection.offset.x + selection.pending_delta.x, y: coordinates.y + selection.offset.y + selection.pending_delta.y}
                let selection_origin = {x: coordinates.x - selection.offset.x, y: coordinates.y - selection.offset.y}

                let testX1 = selection_origin.x < 0
                let testY1 = selection_origin.y < 0
                let testX2 = selection_origin.x + selection.bounds.width > bounds.width
                let testY2 = selection_origin.y + selection.bounds.height > bounds.height

                // console.log(selection.offset.x, selection.offset.y)
                //console.log(`C.O: ${selection_origin.x}, ${selection_origin.y}`)

                //console.log(selection_origin, testX1, testY1, testX2, testY2)


                let snap_proximity = 0.2
                let snap_neighbor_proximity = Math.max(selection.bounds.width, 2) + 0.01
                let closest_right = {x: 0, d: Infinity, shelf: null}
                let closest_left =  {x: bounds.width - selection.bounds.width, d: Infinity, shelf: null}
                let closest_top = {y: 0, d: Infinity, shelf: null}
                let closest_bottom =  {y: bounds.height - selection.bounds.height, d: Infinity, shelf: null}

                if (this.capabilities.snap)
                    neighbors
                        // filter all the neighbors and use only the ones within the proximity of the object we are moving
                        // the filter is based on the mouse coordinates and not those of the selection
                        .filter(loc=> {
                            let d = []
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x - coordinates.x),2) + Math.pow(Math.abs(loc.y - coordinates.y ),2)))
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x + loc.width - coordinates.x),2) + Math.pow(Math.abs(loc.y + loc.height - coordinates.y ),2)))
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x + loc.width - coordinates.x),2) + Math.pow(Math.abs(loc.y - coordinates.y ),2)))
                            d.push(Math.sqrt(Math.pow(Math.abs(loc.x - coordinates.x),2) + Math.pow(Math.abs(loc.y + loc.height - coordinates.y ),2)))
                            return Math.min( ...d ) < snap_neighbor_proximity
                        })
                        .map(loc=>{
                            // X coordinate
                            // calculate regular right snap
                            let right_coord = loc.x + loc.width
                            let right_distance = Math.abs(right_coord - parseFloat((coordinates.x - selection.offset.x).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (right_distance < snap_proximity && right_distance < closest_right.d) {
                                closest_right.x = selection_origin.x - right_coord
                                closest_right.d = right_distance
                                closest_right.shelf = loc
                            }

                            // calculate regular left snap
                            let left_coord = loc.x
                            let left_distance = Math.abs(left_coord - parseFloat((coordinates.x - selection.offset.x + selection.bounds.width).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (left_distance < snap_proximity && left_distance < closest_left.d) {
                                closest_left.x = selection_origin.x - left_coord + selection.bounds.width
                                closest_left.d = right_distance
                                closest_left.shelf = loc
                            }

                            // calculate opposite right snap
                            right_coord = loc.x
                            right_distance = Math.abs(right_coord - parseFloat((coordinates.x - selection.offset.x).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (right_distance < snap_proximity && right_distance < closest_right.d) {
                                closest_right.x = selection_origin.x - right_coord
                                closest_right.d = right_distance
                                closest_right.shelf = loc
                            }

                            // calculate opposite left snap
                            left_coord = loc.x + loc.width
                            left_distance = Math.abs(left_coord - parseFloat((coordinates.x - selection.offset.x + selection.bounds.width).toFixed(2)))

                            // right_distance = right_distance < snap_proximity
                            if (left_distance < snap_proximity && left_distance < closest_left.d) {
                                closest_left.x = selection_origin.x - left_coord + selection.bounds.width
                                closest_left.d = right_distance
                                closest_left.shelf = loc
                            }

                            // Y coordinate
                            // calculate regular top snap
                            let top_coord = loc.y + loc.height
                            let top_distance = Math.abs(top_coord - parseFloat((coordinates.y - selection.offset.y).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (top_distance < snap_proximity && top_distance < closest_top.d) {
                                closest_top.y = selection_origin.y - top_coord
                                closest_top.d = top_distance
                                closest_top.shelf = loc
                            }

                            // calculate regular bottom snap
                            let bottom_coord = loc.y
                            let bottom_distance = Math.abs(bottom_coord - parseFloat((coordinates.y - selection.offset.y + selection.bounds.height).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (bottom_distance < snap_proximity && bottom_distance < closest_bottom.d) {
                                closest_bottom.y = selection_origin.y - bottom_coord + selection.bounds.height
                                closest_bottom.d = top_distance
                                closest_bottom.shelf = loc
                            }

                            // calculate opposite top snap
                            top_coord = loc.y
                            top_distance = Math.abs(top_coord - parseFloat((coordinates.y - selection.offset.y).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (top_distance < snap_proximity && top_distance < closest_top.d) {
                                closest_top.y = selection_origin.y - top_coord
                                closest_top.d = top_distance
                                closest_top.shelf = loc
                            }

                            // calculate opposite bottom snap
                            bottom_coord = loc.y + loc.height
                            bottom_distance = Math.abs(bottom_coord - parseFloat((coordinates.y - selection.offset.y + selection.bounds.height).toFixed(2)))

                            // top_distance = top_distance < snap_proximity
                            if (bottom_distance < snap_proximity && bottom_distance < closest_bottom.d) {
                                closest_bottom.y = selection_origin.y - bottom_coord + selection.bounds.height
                                closest_bottom.d = top_distance
                                closest_bottom.shelf = loc
                            }
                        })
                // calculate optimal snap
                let x_snap = closest_right.d < closest_left.d ? closest_right : closest_left
                let y_snap = closest_top.d < closest_bottom.d ? closest_top : closest_bottom

                let delta = {x: 0, y: 0, oob: 0}

                if (testX1 || testX2) {
                    // out of bounds
                    delta.x = (testX2 ? bounds.width - selection.bounds.width : 0) + selection.offset.x
                } else {
                    delta.x = parseFloat((coordinates.x).toFixed(2))

                    // if snap != Infinity then apply
                    if (x_snap.d < Infinity) {
                        testX1 = selection.bounds.x - x_snap.x < 0
                        testX2 = selection.bounds.x - x_snap.x + selection.bounds.width > bounds.width
                        if (!testX1 && !testX2) {
                            delta.x -= x_snap.x
                            //x_snap.shelf._search_select = true
                        }
                    }
                    delta.oob = 1
                }
                if (testY1 || testY2) {
                    // out of bounds
                    delta.y = (testY2 ? bounds.height - selection.bounds.height : 0) + selection.offset.y
                } else {
                    // we allow the coordinate to pass through unhindered
                    delta.y = parseFloat((coordinates.y).toFixed(2))

                    // if snap != Infinity then apply
                    if (y_snap.d < Infinity) {
                        testY1 = selection.bounds.y - y_snap.y < 0
                        testY2 = selection.bounds.y - y_snap.y + selection.bounds.height > bounds.height
                        if (!testY1 && !testY2) {
                            delta.y -= y_snap.y
                            //y_snap.shelf._search_select = true
                        }
                    }

                    delta.oob += 1
                }

                return delta;
            },
            standardizedShelfDrag(shelf) {
                let oob = 0
                let testX1 = this.svg_x - this.drag_offset_x < 0
                let testX2 = this.svg_x + shelf.width - this.drag_offset_x > this.warehouse.real_width
                let testY1 = this.svg_y - this.drag_offset_y < 0
                let testY2 = this.svg_y + shelf.height - this.drag_offset_y > this.warehouse.real_height

                let snap_proximity = 0.2
                let snap_neighbor_proximity = Math.max(shelf.width, 2) + 0.01
                let closest_right = {x: 0, d: Infinity, shelf: null}
                let closest_left =  {x: this.warehouse.real_width - shelf.width, d: Infinity, shelf: null}
                let closest_top = {y: 0, d: Infinity, shelf: null}
                let closest_bottom =  {y: this.warehouse.real_height - shelf.height, d: Infinity, shelf: null}
                if (this.capabilities.snap)
                this.warehouse.locations
                    .filter(loc=> {
                        if (loc._status === `ignore` || loc._status === `delete`) return false
                        if (loc._element_id === shelf._element_id) return false; // we dont compare to the shelf being dragged
                        loc._search_select = false

                        let d = []
                        d.push(Math.sqrt(Math.pow(Math.abs(loc.x - this.svg_x),2) + Math.pow(Math.abs(loc.y - this.svg_y ),2)))
                        d.push(Math.sqrt(Math.pow(Math.abs(loc.x + loc.width - this.svg_x),2) + Math.pow(Math.abs(loc.y + loc.height - this.svg_y ),2)))
                        d.push(Math.sqrt(Math.pow(Math.abs(loc.x + loc.width - this.svg_x),2) + Math.pow(Math.abs(loc.y - this.svg_y ),2)))
                        d.push(Math.sqrt(Math.pow(Math.abs(loc.x - this.svg_x),2) + Math.pow(Math.abs(loc.y + loc.height - this.svg_y ),2)))

                        return Math.min( ...d ) < snap_neighbor_proximity
                    })
                    .map(loc=>{
                        // X coordinate
                        // calculate regular right snap
                        let right_coord = loc.x + loc.width
                        let right_distance = Math.abs(right_coord - parseFloat((this.svg_x - this.drag_offset_x).toFixed(2)))

                        // right_distance = right_distance < snap_proximity
                        if (right_distance < snap_proximity && right_distance < closest_right.d) {
                            closest_right.x = shelf.x - right_coord
                            closest_right.d = right_distance
                            closest_right.shelf = loc
                        }

                        // calculate regular left snap
                        let left_coord = loc.x
                        let left_distance = Math.abs(left_coord - parseFloat((this.svg_x - this.drag_offset_x + shelf.width).toFixed(2)))

                        // right_distance = right_distance < snap_proximity
                        if (left_distance < snap_proximity && left_distance < closest_left.d) {
                            closest_left.x = shelf.x - left_coord + shelf.width
                            closest_left.d = right_distance
                            closest_left.shelf = loc
                        }

                        // calculate opposite right snap
                        right_coord = loc.x
                        right_distance = Math.abs(right_coord - parseFloat((this.svg_x - this.drag_offset_x).toFixed(2)))

                        // right_distance = right_distance < snap_proximity
                        if (right_distance < snap_proximity && right_distance < closest_right.d) {
                            closest_right.x = shelf.x - right_coord
                            closest_right.d = right_distance
                            closest_right.shelf = loc
                        }

                        // calculate opposite left snap
                        left_coord = loc.x + loc.width
                        left_distance = Math.abs(left_coord - parseFloat((this.svg_x - this.drag_offset_x + shelf.width).toFixed(2)))

                        // right_distance = right_distance < snap_proximity
                        if (left_distance < snap_proximity && left_distance < closest_left.d) {
                            closest_left.x = shelf.x - left_coord + shelf.width
                            closest_left.d = right_distance
                            closest_left.shelf = loc
                        }

                        // Y coordinate
                        // calculate regular top snap
                        let top_coord = loc.y + loc.height
                        let top_distance = Math.abs(top_coord - parseFloat((this.svg_y - this.drag_offset_y).toFixed(2)))

                        // top_distance = top_distance < snap_proximity
                        if (top_distance < snap_proximity && top_distance < closest_top.d) {
                            closest_top.y = shelf.y - top_coord
                            closest_top.d = top_distance
                            closest_top.shelf = loc
                        }

                        // calculate regular bottom snap
                        let bottom_coord = loc.y
                        let bottom_distance = Math.abs(bottom_coord - parseFloat((this.svg_y - this.drag_offset_y + shelf.height).toFixed(2)))

                        // top_distance = top_distance < snap_proximity
                        if (bottom_distance < snap_proximity && bottom_distance < closest_bottom.d) {
                            closest_bottom.y = shelf.y - bottom_coord + shelf.height
                            closest_bottom.d = top_distance
                            closest_bottom.shelf = loc
                        }

                        // calculate opposite top snap
                        top_coord = loc.y
                        top_distance = Math.abs(top_coord - parseFloat((this.svg_y - this.drag_offset_y).toFixed(2)))

                        // top_distance = top_distance < snap_proximity
                        if (top_distance < snap_proximity && top_distance < closest_top.d) {
                            closest_top.y = shelf.y - top_coord
                            closest_top.d = top_distance
                            closest_top.shelf = loc
                        }

                        // calculate opposite bottom snap
                        bottom_coord = loc.y + loc.height
                        bottom_distance = Math.abs(bottom_coord - parseFloat((this.svg_y - this.drag_offset_y + shelf.height).toFixed(2)))

                        // top_distance = top_distance < snap_proximity
                        if (bottom_distance < snap_proximity && bottom_distance < closest_bottom.d) {
                            closest_bottom.y = shelf.y - bottom_coord + shelf.height
                            closest_bottom.d = top_distance
                            closest_bottom.shelf = loc
                        }
                    })
                // calculate optimal snap
                let x_snap = closest_right.d < closest_left.d ? closest_right : closest_left
                let y_snap = closest_top.d < closest_bottom.d ? closest_top : closest_bottom

                if (testX1 || testX2) {
                    // out of bounds
                    this.drag_x = testX2 ? this.warehouse.real_width - shelf.width : 0
                } else {
                    let new_x = parseFloat((this.svg_x - this.drag_offset_x).toFixed(2)) - shelf.x


                    // if snap != Infinity then apply
                    if (x_snap.d < Infinity) {
                        testX1 = shelf.x - x_snap.x < 0
                        testX2 = shelf.x - x_snap.x + shelf.width > this.warehouse.real_width
                        if (!testX1 && !testX2) {
                            new_x = -x_snap.x
                            //x_snap.shelf._search_select = true
                        }
                    }

                    shelf._batch_x = new_x
                    oob = 1
                }
                if (testY1 || testY2) {
                    // out of bounds
                    this.drag_x = testY2 ? this.warehouse.real_height - shelf.height : 0
                } else {
                    let new_y = parseFloat((this.svg_y - this.drag_offset_y).toFixed(2)) - shelf.y

                    // if snap != Infinity then apply
                    if (y_snap.d < Infinity) {
                        testY1 = shelf.x - y_snap.x < 0
                        testY2 = shelf.x - y_snap.x + shelf.width > this.warehouse.real_width
                        if (!testY1 && !testY2) {
                            new_y = -y_snap.y
                            //y_snap.shelf._search_select = true
                        }
                    }

                    shelf._batch_y = new_y
                    oob += 1
                }
                shelf.oob = oob
                return shelf;
            },
            regenerateItemNextIndex() {
                let max = 0
                
                this.warehouse.tags.map(_=> {
                    if (max < _.label && _._status !== `delete` && _._status !== `ignore`)
                        max = _.label
                })
                this.item_next_index = max+1

                const filterTags = this.warehouse.tags.filter(item => item._status !== `delete` && item._status !== `ignore`)
                const duplicates = filterTags.reduce((acc, cur, index) => {
                    if (filterTags.findIndex(item => item.label === cur.label) !== index && acc.findIndex(item => item.label === cur.label) === -1) {
                        acc.push(cur);
                    }
                    return acc;
                }, []);
                if(duplicates.length !== 0){
                    this.modalErrorAlert = true
                    this.modalErrorMsg = "Use a different tag name."
                }
            },
            copy() {
                if (this.active_selection === null) return
                this.copy_objects = []
                for (let i = 0; i < this.active_selection.objects.length; i++) {
                    let obj = this.active_selection.objects[i]

                    this.copy_objects.push({
                        x: obj.x - this.active_selection.bounds.x,
                        y: obj.y - this.active_selection.bounds.y,
                        width: obj.width,
                        height: obj.height,
                        tags: obj.children.map(_=> {return {
                            x: _.x - obj.x,
                            y: _.y - obj.y,
                        }})
                    })
                }
            },
            paste() {
                if (this.copy_objects.length === 0) return

                let basis = { x: this.visible_area.left, y: this.visible_area.top }
                // console.log(basis)
                if (basis.x < 0.5) basis.x = 0.5
                if (basis.y < 0.5) basis.y = 0.5

                this.handleDeselectAll(null)

                let new_selection = []
                let batch_id = uuidv1()

                for (let i = 0; i < this.copy_objects.length; i++) {
                    new_selection.push( this.generateLocationWithTags(this.copy_objects[i], basis, batch_id) )
                }

                this.active_selection = new Selection(this.warehouse, new_selection)

            },
            generateLocationWithTags(location, basis, batch_id) {
                let s = new Location({
                    id: 0,
                    x: location.x + basis.x,
                    y: location.y + basis.y,
                    width: location.width,
                    height: location.height,
                }, `new`)
                s.warehouse = this.warehouse
                this.warehouse.locations.push(s)

                s.update({
                    _status: `new`,
                    _batch: batch_id,
                    x: location.x + basis.x,
                    y: location.y + basis.y,
                    width: location.width,
                    height: location.height,
                    label: `棚`+this.item_next_index,
                    number: this.item_next_index
                }, `add`)

                for (let i = 0; i < location.tags.length; i++) {
                    let loc = new Tag({
                        id: 0,
                        x: location.x + location.tags[i].x + basis.x,
                        y: location.y + location.tags[i].y + basis.y,
                        label: this.item_next_index,
                        _location_id: s._element_id
                    }, `new`)
                    loc.location = s // setting the shelf will trigger offset recalculation
                    loc.warehouse = this.warehouse
                    this.warehouse.tags.push(loc)
                    loc.update({
                        _status: `new`,
                        _batch: batch_id,
                        _location_id: s._element_id,
                        x: loc.x,
                        y: loc.y,
                        label: this.item_next_index
                    }, `add`)
                    this.regenerateItemNextIndex()
                }
                this.regenerateItemNextIndex()

                return s
            },
            handleStopDragNewShelf(e, shelf) {
                if (this.capabilities.location_select_only) return
                if (this.drag_mode === `item`) {
                    if (this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height || this.item_target === null) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                    } else {
                        let tag = new Tag({
                            id: 0,
                            x: this.svg_x,
                            y: this.svg_y,
                            label: this.item_next_index
                        }, `new`)
                        tag.location = this.item_target // setting the shelf will trigger offset recalculation
                        tag.warehouse = this.warehouse
                        this.item_target.tags.push(tag)
                        this.warehouse.tags.push(tag)
                        tag.update({
                            _status: `new`,
                            _batch: uuidv1(),
                            _location_id: this.item_target._element_id,
                            x: this.svg_x,
                            y: this.svg_y,
                            label: this.item_next_index
                        }, `add`)
                        this.regenerateItemNextIndex()
                    }
                    this.item_target = null
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0

                    if (this.capabilities.zoom)
                        this.zoom.resume()


                    return
                }
                if (this.item_mode) return
                if (this.drag_trash) {
                    // out of bounds
                    this.drag_x = 0
                    this.drag_y = 0
                } else {
                    let s = new Location({
                        id: 0,
                        x: this.drag_x,
                        y: this.drag_y,
                        width: this.warehouse.default_shelf_width,
                        height: this.warehouse.default_shelf_height,
                    }, `new`)
                    s.warehouse = this.warehouse
                    this.warehouse.locations.push(s)

                    let uuid = uuidv1()
                    s.update({
                        _status: `new`,
                        _batch: uuid,
                        x: this.drag_x,
                        y: this.drag_y,
                        width: this.warehouse.default_shelf_width,
                        height: this.warehouse.default_shelf_height,
                        label: `棚`+this.item_next_index,
                        number: this.item_next_index
                    }, `add`)

                    let loc = new Tag({
                        id: 0,
                        x: s.x + this.warehouse.default_shelf_width/2,
                        y: s.y + this.warehouse.default_shelf_height/2,
                        label: this.item_next_index,
                        _location_id: s._element_id
                    }, `new`)
                    loc.location = s // setting the shelf will trigger offset recalculation
                    loc.warehouse = this.warehouse
                    this.warehouse.tags.push(loc)
                    loc.update({
                        _status: `new`,
                        _batch: uuid,
                        _location_id: s._element_id,
                        x: loc.x,
                        y: loc.y,
                        label: this.item_next_index
                    }, `add`)
                    this.regenerateItemNextIndex()
                }
                this.drag_mode = null
                this.drag_obj = null
                this.drag_trash = false
                this.drag_oob_x = 0
                this.drag_oob_y = 0
                this.warehouse.locations.map(_=> {
                    _._search_select = false
                })
                if (this.capabilities.zoom)
                    this.zoom.resume()
            },
            handleStopDragOldShelf(e, shelf) {
                if (this.shelf_selecting) {
                    this.clearMapSelecting(e)
                }
                this.mouse_status = false
                if (this.active_selection) {
                    this.active_selection.color = null
                }
                if (this.drag_mode === `shelf_rotate`) {
                    this.drag_mode = null
                }
                if (this.drag_mode === `map_resize`) {
                    console.log('stop drag')
                    this.drag_mode = null
                    this.drag_corner = null
                    this.drag_bounds = null
                    let delta = {
                        _status: `changed`,
                        _batch: this.getSharedBatch(this.warehouse, `map`),
                        border_left_x      : this.warehouse._user_border_left_x + this.warehouse.border_left_x,
                        border_left_y      : this.warehouse._user_border_left_y + this.warehouse.border_left_y,
                        border_right_x     : this.warehouse._user_border_right_x + this.warehouse.border_right_x,
                        border_right_y     : this.warehouse._user_border_right_y + this.warehouse.border_right_y
                    }
                    this.warehouse._user_border_left_x      = 0
                    this.warehouse._user_border_left_y      = 0
                    this.warehouse._user_border_right_x     = 0
                    this.warehouse._user_border_right_y     = 0

                    this.warehouse.update(delta, `map`)
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                if (this.drag_mode === `item_select`) {
                    // allow tag to be selected
                    this.item_selected = this.drag_obj
                    this.regenerateItemSelectedLabel()
                    this.item_target = null
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                else if (this.drag_mode === `item_drag`) {
                    if ((this.drag_obj._batch_x === 0 && this.drag_obj._batch_y === 0) || this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height || this.item_target === null) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                        this.drag_obj.x = this.drag_obj_origin_x
                        this.drag_obj.y = this.drag_obj_origin_y
                    } else {
                        let delta = {
                            _status: 'change',
                            x: this.drag_obj.x + this.drag_obj._batch_x,
                            y: this.drag_obj.y + this.drag_obj._batch_y,
                            _batch_x: 0,
                            _batch_y: 0,
                            _location_id: this.item_target._element_id,
                            _batch: this.shared_batch || (this.shared_batch = uuidv1()),
                        }
                        this.drag_obj._batch_x = 0
                        this.drag_obj._batch_y = 0
                        this.drag_obj.update(delta, `drag`)

                        // do not remove from old parent if old parent doesnt exist
                        if (this.drag_obj._location)
                            this.drag_obj._location.tags.splice(this.drag_obj._location.tags.indexOf(shelf), 1)

                        this.drag_obj._location = this.item_target // setting the shelf will trigger offset recalculation
                        this.drag_obj.warehouse = this.warehouse
                        this.item_target.tags.push(this.drag_obj)
                        this.drag_obj.calculateOffset()
                        this.regenerateItemNextIndex()
                    }
                    this.item_target = null
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                if (this.item_mode) return

                if (this.drag_obj && this.drag_obj._timer) {
                    console.log('select nudge')
                    clearTimeout(this.drag_obj._timer)
                    this.shelf_selecting = null
                    this.shelf_selected_nudge = shelf
                    this.drag_mode = null
                    this.drag_obj = null
                    this.drag_trash = false
                    this.drag_oob_x = 0
                    this.drag_oob_y = 0
                    if (this.capabilities.zoom)
                        this.zoom.resume()
                    return
                }
                if (this.capabilities.zoom)
                    this.zoom.resume()
            },
            handleDragover(event) {
                //if (this.item_mode) return
                if (!this.item_mode && this.drag_mode === `shelf`) {
                    if (!this.allow_drag_new) return
                    this.allow_drag_new = false
                    this.svgPositionListener(event)

                    let dummy_shelf = {
                        x: 0,
                        y: 0,
                        width: this.warehouse.default_shelf_width,
                        height: this.warehouse.default_shelf_height,
                        _batch_x: 0,
                        _batch_y: 0,
                        _element_id: 0,
                    }
                    let shelf_drag = this.standardizedShelfDrag(dummy_shelf)

                    this.drag_x = shelf_drag._batch_x
                    this.drag_y = shelf_drag._batch_y

                    if (shelf_drag.oob < 2 && this.svg_y < 0 || this.svg_x < 0 || this.svg_y > this.warehouse.real_height || this.svg_x > this.warehouse.real_width) {
                        this.drag_trash = true
                        this.drag_oob_x = event.pageX
                        this.drag_oob_y = event.pageY

                    } else {
                        this.drag_trash = false
                        this.drag_oob_x = 0
                        this.drag_oob_y = 0
                    }

                } else if (this.drag_mode === `item`) {
                    this.svgPositionListener(event)
                    if (this.svg_x < 0 || this.svg_y < 0 || this.svg_x > this.warehouse.real_width || this.svg_y > this.warehouse.real_height) {
                        // out of bounds
                        this.drag_x = 0
                        this.drag_y = 0
                        this.drag_trash = true
                        this.drag_oob_x = event.pageX
                        this.drag_oob_y = event.pageY
                    } else {
                        this.drag_trash = false
                        this.drag_oob_x = 0
                        this.drag_oob_y = 0
                        this.drag_x = this.svg_x
                        this.drag_y = this.svg_y
                        let _this = this
                        let _did_set = false
                        this.warehouse.locations.map(s => {
                            if (s.x < _this.drag_x && s.y < _this.drag_y && s.x + s.width > _this.drag_x && s.y + s.height > _this.drag_y) {
                                _this.item_target = s
                                _did_set = true
                            }
                        })
                        if (!_did_set) _this.item_target = null
                    }
                }
            },
            disabledEvent(e) {
                e.preventDefault()
                return false
            },
            initPanzoom(e) {
                this.zoom = e
                //e.pause()
                // document.getElementById('map').removeEventListener('touchstart', onTouch)
            },
            updateVisible(e) {
                let transform = e.getTransform()
                let scene = document.getElementById('scene').getBoundingClientRect()
                
                if(this.width - scene.width >= 0){
                    if(transform.x < 0){
                        transform.x = 0
                    }
                    if(transform.x > this.width - scene.width){
                        transform.x = this.width - scene.width
                    }
                }else {
                    if(transform.x > 0){
                        transform.x = 0
                    }
                    if(transform.x < this.width - scene.width){
                        transform.x = this.width - scene.width
                    }
                }
                if(this.height - scene.height >= 0){
                    if(transform.y < 0){
                        transform.y = 0
                    }
                    if(transform.y > this.height - scene.height){
                        transform.y = this.height - scene.height
                    }
                }else {
                    if(transform.y >= 0){
                        transform.y = 0
                    }
                    if(transform.y < this.height - scene.height){
                        transform.y = this.height - scene.height
                    }
                }
                let bounds = {
                    left: (-this.line_width * transform.x - this.warehouse.real_width * this.warehouse.border_left_x) / this.warehouse._scale_x,
                    top: (-this.line_width * transform.y - this.warehouse.real_height * this.warehouse.border_left_y)  / this.warehouse._scale_y,
                }

                bounds.right = bounds.left + (this.width * this.line_width + this.warehouse.real_width * this.warehouse.border_left_x) / this.warehouse._scale_x
                bounds.bottom = bounds.top + (this.height * this.line_width + this.warehouse.real_height * this.warehouse.border_left_y) / this.warehouse._scale_y

                if (bounds.left < 0) bounds.left = 0
                if (bounds.top < 0) bounds.top = 0
                //console.log(bounds, this.width * this.line_width)
                this.visible_area = bounds
            },
            updateLines(e) { // keeps the lines drawn to 1px
                this.updateVisible(e)
                let currentScale = e.getTransform().scale
                let regularZoom = this.width / (this.warehouse.real_width * (1 + this.warehouse.border_left_x + this.warehouse.border_right_x))
                this.line_width = regularZoom / currentScale * (this.warehouse.real_width * (1 + this.warehouse.border_left_x + this.warehouse.border_right_x)) / this.width
                this.handlebar_width = this.line_width * 40
                this.item_scale = 1/(0.85/this.line_width)
                // console.log(e.getTransform())
                // this.regenerateShelfSelectedControls()
            },
            svgPositionListener(e, disable_scale) {
                let
                    x = e.clientX,
                    y = e.clientY,
                    // svgP = this.svgPoint(this.svg, x, y), // page space
                    svgL = this.svgPoint(this.local, x, y); // local space

                if (disable_scale === true) {
                    this.svg_x = parseFloat(svgL.x.toFixed(2))
                    this.svg_y = parseFloat(svgL.y.toFixed(2))
                } else {
                    this.svg_x = parseFloat((svgL.x / this.warehouse._scale_x).toFixed(2))
                    this.svg_y = parseFloat((svgL.y / this.warehouse._scale_y).toFixed(2))
                }

            },
            svgPositionListenerMap(e) {
                let
                    x = e.clientX,
                    y = e.clientY,
                    // svgP = this.svgPoint(this.svg, x, y), // page space
                    svgL = this.svgPoint(document.getElementById('scene'), x, y); // local space

                    this.svg_x = parseFloat((svgL.x).toFixed(2))
                    this.svg_y = parseFloat((svgL.y).toFixed(2))


            },
            svgRelativeBounds() {
                let scene = document.getElementById('scene').getBoundingClientRect()
                let svg1 = this.svgPoint(this.local, scene.x, scene.y); // local space
                let svg2 = this.svgPoint(this.local, scene.x + scene.width, scene.y + scene.height); // local space

                return {
                    x1: parseFloat((svg1.x / this.warehouse._scale_x).toFixed(2)),
                    y1: parseFloat((svg1.y / this.warehouse._scale_y).toFixed(2)),
                    x2: parseFloat((svg2.x / this.warehouse._scale_x).toFixed(2)),
                    y2: parseFloat((svg2.y / this.warehouse._scale_y).toFixed(2))
                }
            },
            svgPoint(element, x, y) {
                var pt = this.svg.createSVGPoint();
                pt.x = x;
                pt.y = y;
                return pt.matrixTransform(element.getScreenCTM().inverse());
            },
            resizeCanvas() {
                // compares the top of the svg to the top of footer to determine the available screen area for the map
                // if the map has a table below it or something, this will need to be changed a bit
                let _this = this
                let footer = this.bottomId ? document.getElementById(this.bottomId) : document.getElementsByClassName('footer')[0]
                let panzoomObj = document.getElementById('panzoom')
                setTimeout(_=>{
                    _this.width = panzoomObj.offsetWidth
                    _this.height = _this.maxHeight !== null ? _this.maxHeight : footer.getBoundingClientRect().top - panzoomObj.getBoundingClientRect().top + this.bottomShift
                    _this.offset_x = (_this.width - _this.warehouse.width) / 2
                    _this.offset_y = (_this.height - _this.warehouse.height) / 2
                    setTimeout(_this.fitWarehouseToZoom, 10)
                }, 500)
            },
            handleZoom(mode, mousedown) {
                let _this = this
                this.zoom_mode = mode
                let doZoom = (delay = 1000) => {
                    if (!_this.zoom_active) return
                    let transform = document.zoomm.getTransform()
                    let ratio = 0
                    if (_this.zoom_mode === `in`) {
                        ratio =  0.1
                    } else {
                        ratio = -0.1
                    }
                    transform.scale += ratio =_this.zoom_fit * ratio

                    if (transform.scale > 1 && transform.scale < this.maxZoom) {
                        this.zoom.zoomTo(transform.x, transform.y, 1 - ratio / 100 / (transform.scale / this.maxZoom * 100))
                        if (!_this.zoom_active) return
                        setTimeout(doZoom, delay, 100)
                    }
                }
                if (mousedown) {
                    this.zoom_active = true
                    if (this.zoom_timer !== null)
                    clearTimeout(this.zoom_timer)
                    this.zoom_timer = doZoom()
                } else {
                    this.zoom_active = false
                }
            },

            fitWarehouseToZoom() {
                if (this.zoom === null) return setTimeout(this.fitWarehouseToZoom, 100)

                // for some reason need to move twice...
                this.zoom.moveTo(0, 0);
                this.zoom_fit = Math.min(this.width / this.warehouse.real_width, this.height / this.warehouse.real_height)
                this.zoom.zoomTo(0, 0, this.zoom_fit)
                this.zoom.moveTo(0, 0);
                document.zoomm = this.zoom
            },
        }
    };
</script>

<style scoped>
    .map-data-container {
        width: 100%;
        display: flex;
    }
    .map-editor {
        transition: all 200ms ease-in;
        flex: 1;
    }
    .map-diff-container {
        transition: all 200ms ease-in;
        flex: 1;
        background-color: #01b19c;
    }
    .map-diff {
        width: 100%;
        height: 100%;
        background-color: whitesmoke;
        margin-left: 4px;
        padding: 4px;
    }
    .map-btn-toolbar {
        border-top: 1px solid #5e5e5e;
        border-bottom: 1px solid #5e5e5e;
        margin: 1rem 0;
        padding: 0.5rem 0;
        background-color: white;
    }

    svg { pointer-events: none; user-select: none; cursor: pointer;}
    svg * { pointer-events: all; user-select: none; cursor: pointer;}
    .shelf-input {
        font-size: 0.8rem;
        user-select: text !important;
    }
    .snap-check {
        margin-top: 0.75rem !important;
    }
    .snap-check1 {
        margin-top: 0.5rem !important;
    }
    #map-container {
        .btn-group {
            margin-top: 0.25rem;
        }
        .btn-toolbar {
            padding-top: 0.25rem;
        }
    }
    @media (max-width: 425px) {
        #panzoom {
            height: 200px !important;

        }
    }
</style>
