<template>
<div>
    <template v-if="isGuest === 'false'">
        <app-header></app-header>
        <div class="content-page" :style="{'margin-left': sideShow ? '240px':'40px'}">
            <div class="content">
                <div class="container-fluid">
                    <main id="wrapper">
                        <RouterView :key="key" />
                    </main>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
        <aside v-show="sideShow">
            <app-menu @hideMenu="changeMenu()"></app-menu>
        </aside>
        <aside v-show="!sideShow">
            <div class="left side-menu" style="width:40px">
                <img width="24" height="24" style="margin-left:8px" src="/icon-right.png" @click="changeMenu()" />
            </div>
        </aside>
    </template>
    <template v-else>
        <div class="container-fluid">
            <main id="wrapper">
                <div id="app">
                    <RouterView :key="key"/>
                </div>
            </main>
        </div>
    </template>
</div>
</template>

<script>
import AppHeader from "./layout/Header.vue"
import AppFooter from "./layout/Footer.vue"
import AppMenu from "./layout/Menu.vue"
import global from "../Global.vue";
import {
    RouterView
} from 'vue-router'
export default {
    name: "HomeView",
    components: {
        AppHeader,
        AppMenu,
        AppFooter,
        RouterView
    },
    computed: {
        key() {
            return this.$route.path
        },
    },
    created() {
        this.isGuest = sessionStorage.getItem(global.isGuest);
    },
    data() {
        return {
            isGuest: false,
            sideShow: true
        }
    },
    methods: {
        changeMenu() {
            this.sideShow = !this.sideShow
        }
    }
};
</script>
