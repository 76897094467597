<template>
<section>

    <b-table striped responsive borderless :fields="headers" :items="currentData" :busy="loading">
        <template v-slot:cell(delete)="data">
            <template v-if="tenantId !== data.item.username">
                <b-form-checkbox :key="data.index" v-model="selection" :value="data.item"></b-form-checkbox>
            </template>
        </template>
        <template v-slot:cell(username)="data">
            {{data.value}}
        </template>
        <template v-slot:cell()="data">
            <b-form-checkbox v-model="data.item[data.field.key]" @change="changeItem($event, data.item)" :disabled="tenantId === data.item.username"></b-form-checkbox>
        </template>
    </b-table>

    <div class="w-100 d-flex justify-content-between">
        <div>
            <b-button class="btn green-rflocus mr-1" @click="addUser()">
                <i class="fa fa-plus-square"></i> {{ $t("button.create") }}
            </b-button>
        </div>
        <div>
            <ButtonSave @click="saveUserManage"></ButtonSave>
            <ButtonDelete @click="deleteUser"></ButtonDelete>
        </div>
    </div>

    <b-modal v-model="tenantModal" :title="'新規ユーザー'" @ok="createTenant">
        <form>
            <b-form-group id="name-group" label="ユーザー名:" label-for="name-input" :invalid="!isNameValid">
                <b-form-input id="name-input" v-model="newTenant.username" :class="{ 'is-invalid': !isNameValid }" autocomplete="off"></b-form-input>
                <div slot="invalid-feedback">{{error_user}}</div>
            </b-form-group>

            <b-form-group id="password-group" label="パスワード:" label-for="password-input" :invalid="!isPasswordValid">
                <b-form-input id="password-input" type="password" v-model="newTenant.password" :class="{ 'is-invalid': !isPasswordValid }" autocomplete="new-password" required></b-form-input>
                <div slot="invalid-feedback">{{error_password}}</div>
            </b-form-group>

            <b-form-group id="confirm-password-group" label="パスワードの確認：" label-for="confirm-password-input" :invalid="!isPasswordMatch">
                <b-form-input id="confirm-password-input" type="password" v-model="newTenant.confirmPassword" :class="{ 'is-invalid': !isPasswordMatch }"></b-form-input>
                <div slot="invalid-feedback">{{ error_confirm_password}}</div>
            </b-form-group>

            <b-form-group id="email-group" label="電子メールアドレス：" label-for="email-input">
                <b-form-input id="email-input" type="email" v-model="newTenant.email"></b-form-input>
            </b-form-group>

            <b-form-group id="remark-group" label="備考:" label-for="remark-input">
                <b-form-input id="remark-input" v-model="newTenant.description"></b-form-input>
            </b-form-group>

        </form>
    </b-modal>

</section>
</template>

<script>
// Components

import global from "../../Global.vue";
import * as mutations from "../../graphql/locus_mutations";
import * as queries from "../../graphql/locus_queries";
import {
    createUser
} from "../../graphql/mutations";

import AwsApi from "../../Api";

import {
    API
} from "aws-amplify";

export default {
    components: {},
    data() {
        return {
            tenantId: "",
            selection: [],
            headers: [{
                    key: "delete",
                    label: this.$t("select"),
                },
                {
                    key: "username",
                    label: "ユーザー",
                },

            ],
            allData: [],
            currentData: [],
            tenantModal: false,
            newTenant: {
                "username": "",
                "password": "",
                "email": "",
                "confirmPassword": "",
                "description": ""
            },
            isNameValid: true,
            error_user: "ユーザー名を入力してください。",
            isPasswordValid: true,
            error_password: "パスワードは英数字を含み、8桁以上である必要があります。",
            isPasswordMatch: true,
            error_confirm_password: "パスワードが一致しません。",
            loading: false

        }
    },
    mounted() {
        this.tenantId = sessionStorage.getItem(global.tenantId);
        this.listHeader()
        this.listUser()
    },
    methods: {
        async listHeader() {
            const responseMenu = await API.graphql({
                query: queries.listMenus,
                variables: {
                    tenantId: this.tenantId
                }
            });
            if (responseMenu === undefined) {
                return
            }
            responseMenu.data.listMenus.items.forEach(item => {
                this.headers.push({
                    key: "id" + item.id,
                    label: item.name,
                }, )

            })
        },
        async listUser() {
            this.loading = true
            const response = await API.graphql({
                query: queries.listUsersInGroup,
                variables: {
                    group: this.tenantId
                }
            });
            if (response === undefined) {
                return
            }
            const responseUserManage = await API.graphql({
                query: queries.listUserManages,
                variables: {
                    tenantId: this.tenantId
                }
            });
            const userManages = responseUserManage.data.listUserManages.items === undefined ? [] : responseUserManage.data.listUserManages.items
            const sortUser = response.data.listUsersInGroup.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            this.currentData = sortUser.map(item => {
                const element = {}
                element["username"] = item.username

                if (item.username === this.tenantId) {
                    this.headers.forEach(header => {
                        if (header.key.includes("id") ) {
                            element[header.key] = true
                        }
                    })
                } else {
                    const current = userManages.filter(data => data.username === item.username)
                    element["isUpdate"] = false
                    if (current.length > 0) {
                        const userManage = current[0].menuId
                        userManage.forEach(item => {
                            element["id" + item] = true
                        })
                        element["hasUserManage"] = true
                    } else {
                        element["hasUserManage"] = false
                    }
                }
                return element

            })
            this.loading = false
        },
        async createTenant(bvModalEvent) {

            if (!this.isFormValid()) {
                bvModalEvent.preventDefault()
                return
            }
            var variables = {
                "groupname": sessionStorage.getItem(global.tenantId),
                "username": this.newTenant.username,
                "password": this.newTenant.password,
                "email": this.newTenant.email,
                "description": this.newTenant.description
            }

            const response = await API.graphql({
                query: createUser,
                variables: variables
            })
            this.listUser()

        },
        addUser() {
            this.tenantModal = true
            this.newTenant = {
                "username": "",
                "password": "",
                "email": "",
                "confirmPassword": "",
                "description": ""
            }
        },
        deleteUser() {
            const deletePro = []
            AwsApi.checkSession(() => {
                this.selection.forEach((item, index) => {
                    deletePro.push(API.graphql({
                        query: mutations.deleteUser,
                        variables: {
                            username: item.username
                        }
                    }));
                    if (item.hasUserManage) {
                        var deleteAttribute = {
                            tenantId: this.tenantId,
                            username: item.username
                        }
                        deletePro.push(API.graphql({
                            query: mutations.deleteUserManage,
                            variables: {
                                input: deleteAttribute
                            }
                        }));
                    }
                })
                Promise.all([...deletePro]).then(() => this.listUser())
            }, this)
        },
        saveUserManage() {
            const createPro = []
            const updatePro = []
            AwsApi.checkSession(() => {
                this.currentData.forEach(item => {
                    if (item.isUpdate) {
                        const menuIds = []
                        Object.keys(item).forEach(key => {
                            if (key.includes("id") && item[key]) {
                                menuIds.push(key.substring(2))
                            }
                        })

                        if (item.hasUserManage) {
                            // update
                            updatePro.push(API.graphql({
                                query: mutations.updateUserManage,
                                variables: {
                                    input: {
                                        tenantId: this.tenantId,
                                        username: item.username,
                                        menuId: menuIds,
                                    }
                                }
                            }));
                        } else {
                            // create
                            const input = {
                                accessLevel: 1,
                                group: this.tenantId,
                                menuId: menuIds,
                                tenantId: this.tenantId,
                                username: item.username
                            }
                            createPro.push(API.graphql({
                                query: mutations.createUserManage,
                                variables: {
                                    input: input
                                }
                            }));

                        }
                    }
                })
                Promise.all([...updatePro, ...createPro]).then(() => this.listUser())
            }, this)
        },
        changeItem(value, item) {
            item["isUpdate"] = true;
        },
        isFormValid() {
            if (!this.newTenant.username) {
                this.isNameValid = false
                return false
            }
            this.isNameValid = true

            const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/
            if (!regex.test(this.newTenant.password)) {
                this.isPasswordValid = false
                return false
            }
            this.isPasswordValid = true

            if (this.newTenant.password != this.newTenant.confirmPassword) {
                this.isPasswordMatch = false
                return false
            }
            this.isPasswordMatch = true

            return true

        },
    }
}
</script>
