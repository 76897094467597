<template>
<section :class="{'use-map':storeWarehouse.length !== 0 || backyardWarehouse.length !== 0 }">

    <div class="stock_box">

        <b-card no-body>
            <b-card-header header-tag="nav">

                <b-nav card-header tabs>
                    <b-nav-item style="width:150px" :active="categorie.isActive" @click="setCategorieActive(categorie)" v-bind:key="categorie.id" v-for="categorie of categories">{{ $t(categorie.label) }}
                    </b-nav-item>
                </b-nav>
            </b-card-header>
            <b-card-body>
                <div class="form-row form_container" style="margin-top:10px">
                    <div class="form-group col-auto">
                        <b-form-input style="width:400px" v-model="searchInput" type="search" placeholder="商品名、カテゴリ、ブラント名から探す"></b-form-input>
                    </div>
                    <div class="form-group col-auto">

                        <button @click="searchStocksFun()" class="btn btn-primary btn-rounded w-md waves-effect waves-light" :disabled="working">
                            <template v-if="working">
                                <i class="fa fa-spin fa-hourglass m-r-5"></i>
                                処理中
                            </template>
                            <template v-else>検索</template>
                        </button>
                    </div>
                    <div class="form-group col-auto my-2">
                        <b-form-checkbox v-model="storeModel" @change="storeCheckboxChange">&nbsp;店頭</b-form-checkbox>
                    </div>
                    <div class="form-group col-auto my-2">
                        <b-form-checkbox v-model="backyardModel" @change="backyardCheckboxChange">&nbsp;バックヤード</b-form-checkbox>
                    </div>
                    <div class="form-group col-auto my-2">
                        <span class="table-amount-alert store-header">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>&nbsp;品出しアラート</span>
                    </div>
                    <div class="form-group col-auto my-2">
                        <span class="table-days-alert store-header">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>&nbsp;滞留アラート</span>
                    </div>
                </div>

                <app-table v-if="!tableItems" :loading="loading" :headers="fields" :sort="sortBy" :paginate="paginate" :elements="currentData" :title="title" :value="count" @setModalItem="setModalItem" @sortChange="sortChange" @row-selected="selectRow" @un-row-selected="unSelectRow" :selectable="useMap" :currentCategorie="currentCategorie" @changeItem="changeItem">

                    <template v-slot:paginate>
                        <paginate :paginate="paginate" v-model="page" @updatePage="updatePage">
                        </paginate>
                    </template>

                </app-table>
            </b-card-body>
        </b-card>
        <div class="stock_right">
            <div class="form-row form_container">
                <div class="form-group col-auto">
                    <div class="map_total">TOTAL</div>
                </div>
                <div class="form-group col-auto map-border">
                    <div class="map_count">
                        <div class="map_count_left">SKU</div>
                        <div class="map_count_right">{{getItemCodeAmount()}}</div>
                    </div>
                </div>
                <div class="form-group col-auto map-border">
                    <div class="map_count">
                        <div class="map_count_left">商品数</div>
                        <div class="map_count_right">{{getEpcAmount()}}</div>
                    </div>
                </div>
            </div>
            <div style="display:flex">
                <div v-for="warehouse in storeWarehouse" :key="warehouse.id" style="margin-right:10px">
                    <floor-map-legacy :floormap="warehouse" mode="location_select" :location-id="selected_row" :max-height="400" v-if="warehouse !== null" @selectlocation="selectLocation" @deselectall="selectLocation" style="margin-bottom:30px" :titleData="warehouseTitle.find(i=> i.warehouseId === warehouse.id)" :currentData="allData" :currentCategorie="currentCategorie" @popoverShowLoaction="popoverShowLoaction" :showZoom="true" />
                </div>
            </div>
            <div style="display:flex">
                <div v-for="warehouse in backyardWarehouse" :key="warehouse.id" style="margin-right:10px">
                    <floor-map-legacy :floormap="warehouse" mode="location_select" :location-id="selected_row" :max-height="400" v-if="warehouse !== null" @selectlocation="selectBackyardLocation" @deselectall="selectBackyardLocation" style="margin-bottom:30px" :titleData="warehouseTitle.find(i=> i.warehouseId === warehouse.id)" :currentData="allData" :currentCategorie="currentCategorie" :popoverShowLocation="popoverShowLocation" :showZoom="true" />
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
// Libraries
import {
    BModal
} from 'bootstrap-vue-next'

// Components
import Paginate from '../../components/ThePaginate.vue'
import {
    Warehouse
} from "../../components/modal/Warehouse";

import * as queries from "../../graphql/locus_queries";
import * as mutations from "../../graphql/locus_mutations";
import global from "../../Global.vue";

import AwsApi from "../../Api"
import AppTable from '../../components/table/TableStockPage.vue'
import {
    API
} from "aws-amplify";

export default {
    props: [
        'settings',
        'title',
        'useMap',
    ],
    components: {
        Paginate,
        'b-modal': BModal,
        AppTable,
    },
    data() {
        return {
            tenantId: "",
            searchInput: "",
            menuConfigs: [],
            masterFields: [],
            fields: [],
            fieldsAll: [],
            fieldsAmount: [],
            fieldsDays: [],
            url: null,
            loading: false,
            allData: [],
            filterData: [],
            currentData: [],
            listLocations: [],
            limit: 1000,
            offset: 0,
            paginate: {},
            page: 1,
            count: 30,
            modalCreateInfos: {},
            modalDetailInfos: {},
            sortBy: [],
            displayTitle: null,

            storeWarehouse: [],
            backyardWarehouse: [],
            selected_row: null,
            selecting: false,
            locationOverride: null,
            backyardOverride: null,

            tableItems: false,
            reader: new FileReader(),
            initAmountAlertSelectLocations: [],
            initDaysAlertSelectLocations: [],
            working: false,
            updateCsvPath: "",
            selected: null,
            options: [],
            csvUploadMessage: this.$t("csv_upload_loading"),
            modalCreateJob: false,
            msgModal: false,
            categories: [{
                id: 1,
                label: "stock.label1",
                isActive: true,
            }, {
                id: 2,
                label: "stock.label2",
                isActive: false,
            }, {
                id: 3,
                label: "stock.label3",
                isActive: false,
            }, ],
            currentCategorie: 1,
            storeModel: true,
            backyardModel: true,
            warehouseTitle: [],
            popoverShowLocation: [],
            totalItemCodeAmount: 0,
        }
    },
    async created() {
        this.tenantId = sessionStorage.getItem(global.tenantId);
        await this.fetchAll();
    },
    methods: {
        setCategorieActive(categorieClicked) {
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            if (categorieClicked.label !== currentActiveCategorie.label) {
                this.resetPage()
            } else {
                return
            }
            currentActiveCategorie.isActive = false;
            this.setCategoryAndfetchData(categorieClicked);

        },
        getCurrentActiveCategorie() {
            return this.categories.find(categorie => categorie.isActive === true);
        },

        setCategoryAndfetchData(categorie) {
            categorie.isActive = true;
            this.currentData = []
            this.currentCategorie = categorie.id
            switch (categorie.id) {
                case 1:
                    this.fields = [...this.fieldsAll]
                    break
                case 2:
                    this.fields = [{
                        detailOrder: null,
                        id: '2-999',
                        listOrder: -1,
                        name: '確認済',
                        searchOrder: null,
                        source: 'ItemMaster.properties.amountAlertConfirmed',
                        sourceDefine: null,
                    }, ...this.fieldsAmount]
                    break
                case 3:
                    this.fields = [{
                        detailOrder: null,
                        id: '2-999',
                        listOrder: -1,
                        name: '確認済',
                        searchOrder: null,
                        source: 'ItemMaster.properties.daysAlertConfirmed',
                        sourceDefine: null,
                    }, ...this.fieldsDays]
                    break

            }
            this.defaultFormSearch();
        },
        searchStocksFun() {
            this.defaultFormSearch();
        },
        async fetchAll() {
            if (this.$route.query.warehouse === undefined) {
                return
            }
            this.setUrlData();
            await this.fetchMenuConfigs();
            await this.fetchWarehouseData();
            // search using the form defaults
            this.defaultFormSearch();
        },
        setUrlData() {
            const url = {
                function_id: this.$route.name,
                menu_id: parseInt(this.$route.params.id),
                warehouse_id: this.$route.query.warehouse
            }
            return this.url = url
        },
        async fetchMenuConfigs() {
            const response = await AwsApi.graphql({
                query: queries.getMenu,
                variables: {
                    tenantId: this.tenantId,
                    id: this.url.menu_id
                }
            }, this);
            if (response === undefined) {
                return
            }
            this.displayTitle = response.data.getMenu.name
            this.menuConfigs = response.data.getMenu.configs.items

            if (this.menuConfigs.length === 0) {
                return
            }
            this.setTableFields(this.menuConfigs);
        },
        async fetchWarehouseData() {
            this.storeWarehouse = []
            this.backyardWarehouse = []
            const response = await AwsApi.graphql({
                query: queries.listWarehouses,
                variables: {
                    tenantId: this.tenantId
                }
            }, this);
            if (response === undefined) {
                return
            }

            if (response.data.listWarehouses.items.length !== 0) {
                response.data.listWarehouses.items.forEach(async (item, index) => {

                    if (parseInt(item.width)) {
                        this.listLocations = []
                        this.listLocationTags = []
                        await this.getListLocations(item.id, undefined)
                        await this.getListLocationTags(item.id, undefined)
                        item.locations = this.listLocations

                        item.tags = this.listLocationTags
                        if (item.type === 1) {
                            this.storeWarehouse.push(new Warehouse(item))
                        } else {
                            this.backyardWarehouse.push(new Warehouse(item))
                        }
                    }
                })
            }
        },
        async getListLocations(warehouseId, nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: warehouseId
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }

            this.listLocations = this.listLocations.concat(response.data.queryLocationsByWarehouseId.items);
            if (response.data.queryLocationsByWarehouseId.nextToken !== null) {
                await this.getListLocations(warehouseId, response.data.queryLocationsByWarehouseId.nextToken)
            } else {
                return
            }
        },
        async getListLocationTags(warehouseId, nextTokenValue) {
            const response = await AwsApi.graphql({
                query: queries.queryLocationTagsByWarehouseId,
                variables: {
                    tenantId: this.tenantId,
                    warehouseId: {
                        eq: warehouseId
                    },
                    nextToken: nextTokenValue

                }
            }, this);
            if (response === undefined) {
                return
            }

            this.listLocationTags = this.listLocationTags.concat(response.data.queryLocationTagsByWarehouseId.items);
            if (response.data.queryLocationTagsByWarehouseId.nextToken !== null) {
                await this.getListLocationTags(warehouseId, response.data.queryLocationTagsByWarehouseId.nextToken)
            } else {
                return
            }
        },
        defaultFormSearch() {
            this.resetPage()
            this.updateData()

        },
        setTableFields(menuConfigs) {
            this.masterFields =
                menuConfigs
                .filter(item => item.listOrder && item.listOrder !== -1)
                .sort((a, b) => a.listOrder - b.listOrder)

            this.fieldsAll = [...this.masterFields.filter(item => item.source !== "daysInfo" && item.source !== "ItemMaster.amountThreshold" && item.source !== "ItemMaster.daysThreshold" &&
                item.source !== "ItemMaster.properties.amountAlertConfirmed" && item.source !== "ItemMaster.properties.daysAlertConfirmed")]
            this.fieldsAmount = [...this.masterFields.filter(item => item.source !== "daysInfo" && item.source !== "ItemMaster.daysThreshold" && item.source !== "ItemMaster.properties.daysAlertConfirmed")]
            this.fieldsDays = [...this.masterFields.filter(item => item.source !== "ItemMaster.amountThreshold" && item.source !== "ItemMaster.properties.売価" && item.source !== "ItemMaster.properties.amountAlertConfirmed")]
            const storeCountIndex = this.fieldsDays.findIndex(item => item.source === "storeCount");
            this.fieldsDays.splice(storeCountIndex, 0, {
                detailOrder: null,
                id: "2-999",
                listOrder: 1,
                name: "合計在庫数",
                searchOrder: null,
                source: "totalCount",
                sourceDefine: {
                    inputType: 0,
                    items: null
                }

            });
            this.fields = [...this.fieldsAll]

        },
        resetPage() {
            this.page = 1;
            this.allData = [];
            this.offset = 0
            this.paginate = {}
            this.warehouseTitle = []
            this.tableItems = false
            this.selected_row = null
        },
        async updateData() {
            await this.getListData()
        },
        async getListData() {
            const currentActiveCategorie = this.getCurrentActiveCategorie();
            this.working = true
            const myInit = this.setRequestParam()
            var response = await AwsApi.graphql({
                query: queries.searchAllStocks,
                variables: {
                    conditions: JSON.stringify(myInit)
                }
            }, this)
            const currentActiveCategorie2 = this.getCurrentActiveCategorie();
            if (response === undefined || currentActiveCategorie2.label !== currentActiveCategorie.label) {
                this.working = false
                return
            }
            response = JSON.parse(response.data.searchAllStocks)

            if (response === null || response.length === 0) {
                this.working = false
                return
            }

            this.stockData(response)
            this.setData();
            this.setWarehouseTitle(response)
            if (response.list.length === 1000) {
                this.getALlListData()
            } else {
                this.working = false
            }

        },
        getALlListData() {
            this.offset += this.limit
            const myInit = this.setRequestParam()

            AwsApi.graphql({
                query: queries.searchAllStocks,
                variables: {
                    conditions: JSON.stringify(myInit)
                }
            }, this).then(response => {
                if (response === undefined) {
                    this.working = false
                    return
                }
                response = JSON.parse(response.data.searchAllStocks)
                if (response.list.length !== 0) {
                    this.stockData(response)
                    this.paginate = {
                        total: this.allData.length,
                        per_page: this.count,
                        current_page: this.page
                    };
                    if (response.list.length === 1000) {
                        this.getALlListData()
                    } else {
                        this.working = false
                    }
                } else {
                    this.working = false
                }
            })

        },
        stockData(response) {
            this.allData = this.allData.concat(response.list);
        },
        setRequestParam() {
            const myInit = {
                menuId: this.url.menu_id,
                limit: this.limit,
                offset: this.offset,
            };
            if (this.searchInput !== "") {
                myInit["ItemMaster.properties"] = "%" + this.searchInput + "%"
            }
            return myInit
        },
        setData() {
            this.sortData()
            this.filterData = this.allData.filter(item => {
                if (this.currentCategorie === 1) {
                    return true
                } else {
                    return this.currentCategorie === 3 && item.daysAlertFlag === true || this.currentCategorie === 2 && item.amountAlertFlag === true
                }
            })
            this.filterData = this.filterData.filter(item => {
                if (this.locationOverride) {
                    return item.storeLocationIds.includes(this.locationOverride)
                } else {
                    return true
                }
            })
            this.filterData = this.filterData.filter(item => {
                if (this.backyardOverride) {
                    return item.backyardLocationIds.includes(this.backyardOverride)
                } else {
                    return true
                }
            })
            this.filterData = this.filterData.filter(item => {
                if (this.storeModel === true && this.backyardModel === false) {
                    return item.storeCount !== 0
                } else if (this.storeModel === false && this.backyardModel === true) {
                    return item.backyardCount !== 0
                } else if (this.storeModel === false && this.backyardModel === false) {
                    return item.storeCount === 0 && item.backyardCount === 0
                } else {
                    return true
                }
            })
            this.currentData = this.filterData.slice((this.page - 1) * this.count, (this.page) * this.count)
            this.currentData = this.currentData.map(item => {
                var _cellVariants = {}
                this.fields.forEach(f => {
                    if (this.currentCategorie === 1) {
                        if (item.amountAlertFlag === true) {
                            _cellVariants[f.source] = "amount-alert"
                        } else if (item.daysAlertFlag === true) {
                            _cellVariants[f.source] = "days-alert"
                        }
                    }
                })

                return {
                    ...item,
                    storeLocations: item.storeLocations.join("、"),
                    backyardLocations: item.backyardLocations.join("、"),
                    _cellVariants: _cellVariants
                }
            })
            this.paginate = {
                total: this.filterData.length,
                per_page: this.count,
                current_page: this.page
            };
        },
        sortData() {
            if (this.sortBy.length === 0) {
                return
            }
            this.allData.sort((a, b) => {
                const sort = this.sortBy[0]
                const valA = a[sort.source];
                const valB = b[sort.source];

                if (valA == null && valB == null) return 0;
                if (valA == null) return sort.direction === "asc" ? 1 : -1;
                if (valB == null) return sort.direction === "asc" ? -1 : 1;

                if (typeof valA === "number") {
                if (sort.direction === "asc") {
                    return valA < valB ? -1 : 1;
                } else {
                    return valA > valB ? -1 : 1;
                }
                } else {
                if (sort.direction === "asc") {
                    return valA.localeCompare(valB);
                } else {
                    return valB.localeCompare(valA);
                }
                }
            })
        },
        setWarehouseTitle(response) {
            this.warehouseTitle = this.warehouseTitle.concat(response.summary.store.map(i => {
                return {
                    ...i,
                    type: 1
                }
            }))
            this.warehouseTitle = this.warehouseTitle.concat(response.summary.backyard.map(i => {
                return {
                    ...i,
                    type: 2
                }
            }))
            this.totalItemCodeAmount = response.summary.totalItemCodeAmount
        },
        updatePage(page) {
            this.page = page;
            this.setData()
        },
        sortChange(value) {
            //check if value
            const exist = this.sortBy.map(item => item.source).includes(value);
            // store the new value if not exist or change direction
            this.sortBy = exist ?
                this.sortBy
                .map(item => {
                    if (item.source === value) {
                        item.direction = item.direction === 'asc' ? 'desc' : 'asc'
                    }
                    return item
                }) : [{
                    source: value,
                    direction: 'asc'
                }];
            this.setData();
        },

        backToMainPage() {
            this.tableItems = false
            this.selected_row = null
        },

        setModalItem(item) {
            this.modalDetailInfos = item ? this.getModalItemInfosPerPage(item) : infos;
            this.tableItems = true;
        },
        getModalItemInfosPerPage(item) {
            // build the items for the header of the modal items
            switch (this.title) {
                case 'Stocks':
                    return {
                        'Stock.itemCode': item['Stock.itemCode'],
                            'Stock.locationId': item.locationId
                    };
                case 'Job':
                    const input = {}
                    if (item['Job.id']) {
                        input['Job.id'] = item['Job.id']
                        input['JobDetail.jobId'] = item['Job.id']
                    }
                    if (item['JobDetail.itemCode']) {
                        input['JobDetail.itemCode'] = item['JobDetail.itemCode']
                    }
                    if (item['ItemMaster.itemCode']) {
                        input['JobDetail.itemCode'] = item['ItemMaster.itemCode']
                    }
                    if (item['JobEpc.epc']) {
                        input['JobEpc.epc'] = item['JobEpc.epc']
                    }
                    if (item['JobEpc.locationId']) {
                        input['JobEpc.locationId'] = item.locationId
                    }

                    return input;

            }
        },

        // ----- Settings
        selectLocation(location) {
            this.locationOverride = location !== undefined ? location.id : null
            this.selected_row = null
            this.setData();
        },
        selectBackyardLocation(location) {
            this.backyardOverride = location !== undefined ? location.id : null
            this.selected_row = null
            this.setData();
        },

        selectRow(row, event) {
            // there is an intermittent problem with double-selects
            // this timer makes sure that additional selects are ignored

            if (this.selecting !== false) return
            let _this = this
            setTimeout(_ => {
                _this.selecting = false
            }, 100)

            this.selecting = true

            if (!this.useMap || row === this.selected_row) return

            if (row === undefined) {
                this.selected_row = null
                return
            }
            if (this.selected_row === row) {
                this.selected_row = null
                return
            }
            this.selected_row = row
        },
        unSelectRow(row, event) {
            this.selected_row = null
        },
        storeCheckboxChange(value) {
            this.setData()
        },
        backyardCheckboxChange(value) {
            this.setData()
        },
        getItemCodeAmount() {
            return this.totalItemCodeAmount
        },
        getEpcAmount() {
            var sum = 0
            this.warehouseTitle.forEach(i => {
                sum += i.epcAmount
            })
            return sum
        },
        changeItem(value, item, key) {
            AwsApi.graphql({
                query: queries.queryHeadersWithTable,
                variables: {
                    tenantId: this.tenantId,
                    sourceTable: "ItemMaster"
                }
            }, this).then((header) => {
                if (header === undefined) {
                    return
                }
                const headers = header.data.queryItemDefines.items.map(item => {
                    const datas = item.source.split('.')
                    var itemKey = ""
                    if (datas.length == 2) {
                        itemKey = datas[1]
                    } else if (datas.length == 3) {
                        itemKey = datas[2]
                    }
                    return {
                        key: itemKey, //item.itemKey !== null ? item.itemKey : item.name,
                        label: item.name,
                        id: item.order, //item.id
                        source: item.source,
                        inputType: item.inputType
                    }
                })
                const res = {}
                res['itemCode'] = item["Stock.itemCode"]
                API.graphql({
                    query: queries.getItemMasterList,
                    variables: {
                        conditions: JSON.stringify(res)
                    }
                }).then((response) => {
                    const convertData = response.data.getItemMasterList.map(item => {
                        return JSON.parse(item)
                    })
                    convertData.forEach(i => {
                        const json = this.createSendJson(headers, i, key, value)
                        API.graphql({
                            query: mutations.updateItemMaster,
                            variables: {
                                updateItemMaster: json
                            }
                        })
                    })
                })

            })
        },
        popoverShowLoaction(currentPopoverData) {
            this.popoverShowLocation = currentPopoverData
        },
        createSendJson(headers, item, key, value) {
            item = {
                ...item,
                ...JSON.parse(item['properties'])
            }
            const splitKey = key.split(".")
            const currentKey = splitKey[splitKey.length - 1]
            const json = {}
            headers.forEach((h) => {
                const sources = h.source.split(".")
                if (sources.length == 2) {
                    if (item[sources[1]] !== undefined && item[sources[1]] !== '') {
                        if (currentKey === sources[1]) {
                            json[sources[1]] = value.toString()
                        } else {
                            json[sources[1]] = item[sources[1]]
                        }
                    }

                    const pre = {}
                    json['properties'] = {
                        ...json['properties'],
                        ...pre
                    }
                } else if (sources.length == 3) {
                    const pre = {}
                    if (item[sources[2]] !== undefined && item[sources[2]] !== '') {
                        if (currentKey === sources[2]) {
                            pre[sources[2]] = value.toString()
                        } else {
                            pre[sources[2]] = item[sources[2]]
                        }
                    }

                    json['properties'] = {
                        ...json['properties'],
                        ...pre
                    }

                }
            })
            if (currentKey === "amountAlertConfirmed") {
                json['properties'] = {
                    ...json['properties'],
                    "amountAlertConfirmed": value
                }
            }
            if (currentKey === "daysAlertConfirmed") {
                json['properties'] = {
                    ...json['properties'],
                    "daysAlertConfirmed": value
                }
            }

            if (json['properties'] !== undefined) {
                json['properties'] = JSON.stringify(json['properties'])
            }
            return json
        },

    }
}
</script>

<style scoped>
.upload_csv_btn {
    cursor: default;
    padding: 0 8px;
}

.upload_csv_label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 4px 0;
}

.stock_box {
    display: flex;
    width: 100%;
}

.stock_right {
    margin-left: 20px;
    width: 800px;
}

.map_total {
    width: 100px;
    height: 40px;
    background-color: #cecdcd;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
}

.map_count {
    min-width: 180px;
}

.map_count_left {
    font-size: 14px;
    float: left
}

.map_count_right {
    font-size: 26px;
    font-weight: 600;
    top: 5px;
    position: relative;
    float: right;
}

.map-border {
    border-bottom: 1px solid #cecdcd;
    margin-right: 10px;
}

.table-amount-alert {
    background-color: #FFCF71 !important;
}

.table-days-alert {
    background-color: #FF8B7B !important;
}

.store-header {
    width: 10px;
    height: 10px;
}
</style>
