<template>
  <section :class="{ 'use-map': warehouse !== null }">
    <b-overlay :show="showOverlay" rounded="lg" no-center="true">
      <template #overlay>
        <div class="text-center" style="margin-top: 300px">
          <b-spinner
            v-if="
              csvMessage === $t('csv_upload_loading') ||
              csvMessage === $t('csv_dl_loading')
            "
            style="width: 3rem; height: 3rem; margin-bottom: 1rem"
            label="Large Spinner"
          ></b-spinner>
          <p id="cancel-label">{{ csvMessage }}</p>
          <b-button
            v-if="
              csvMessage !== $t('csv_upload_loading') &&
              csvMessage !== $t('csv_dl_loading')
            "
            class="btn green-rflocus"
            size="sm"
            @click="showOverlay = false"
          >
            OK
          </b-button>
        </div>
      </template>
      <!-- Form Component -->
      <page-header
        :title="displayTitle"
        style="-webkit-user-select: none; /* Safari */"
      >
        <app-form
          ref="appform"
          :title="title"
          :items="formItems"
          :working="working"
          :csvWorking="csvWorking"
          :deleteWorking="deleteWorking"
          @fetchSearch="submitFormSearch"
          :form_select_options="form_select_options"
          @fetchCsv="downloadCSV"
        />
      </page-header>

      <app-table
        v-if="!tableItems"
        :loading="working"
        :headers="fields"
        :sort="sortBy"
        :paginate="paginate"
        :elements="currentData"
        :title="title"
        :stockCount="stockCount"
        :value="count"
        @setModalItem="setModalItem"
        @setModalCreate="setModalCreate"
        @updatePaginateCount="updatePaginateCount"
        @sortChange="sortChange"
        @row-selected="selectRow"
        @un-row-selected="unSelectRow"
        :selectable="jobUseMap"
        :rowClickAble="functionId !== 6"
      >
        <template v-slot:paginate>
          <paginate
            :paginate="paginate"
            v-model="page"
            @updatePage="updatePage"
          >
          </paginate>
        </template>

        <template v-if="title !== 'Stocks' && functionId !== 6" v-slot:button>
          <b-button
            size="sm"
            :variant="working ? '' : 'primary'"
            pill
            :disabled="working"
          >
            <label class="upload_csv_label" for="file">
              <i class="fa fa-cloud-upload m-r-5"></i>
              <span>csvファイル一括登録</span>
            </label>
          </b-button>
          <input
            :disabled="working"
            type="file"
            id="file"
            ref="file"
            accept=".csv, .xlsx, .xlsm"
            v-on:change="handleFileUpload(false)"
            hidden
          />
        </template>
      </app-table>

      <modal-detail-location
        v-else
        :infos="modalDetailInfos"
        :tenantId="tenantId"
        :title="title"
        :url="url"
        :form="form"
        :formItems="formItems"
        @backToMainPage="backToMainPage"
        @row-selected="selectRow"
        @un-row-selected="unSelectRow"
      >
      
      <!-- <template v-if="tableItems" v-slot:buttonfinish>
          <b-button
            size="sm"
            class="m-r-5"
            :variant="working ? '' : 'primary'"
            pill
            :disabled="working"
            @click="msgModalOk"
          >
            <label class="upload_csv_label">
              <span>作業完了</span>
            </label>
          </b-button>
        </template> -->
        <template v-if="tableItems" v-slot:button>
          <b-button
            size="sm"
            :variant="working ? '' : 'primary'"
            pill
            :disabled="working"
          >
            <label class="upload_csv_label" for="file">
              <i class="fa fa-cloud-upload m-r-5"></i>
              <span>テーブルスキャナ登録</span>
            </label>
          </b-button>
          <input
            :disabled="working"
            type="file"
            id="file"
            ref="file"
            accept=".csv, .xlsx, .xlsm"
            v-on:change="handleFileUpload(true)"
            hidden
          />
        </template>
      </modal-detail-location>
    </b-overlay>
    <b-modal :modelValue="msgModal" hide-header @ok="finishJob" @cancel="msgModalCancel">
        作業完了しますか？
    </b-modal>
  </section>
</template>

<script>
// Libraries
import { BModal } from "bootstrap-vue-next";

// Components
import PageHeader from "../../layout/PageHeader.vue";
import AppTable from "../../../components/table/TablePage.vue";
import Form from "../../../components/form/Form.vue";
import ButtonNew from "../../../components/buttons/ButtonNew.vue";
import Paginate from "../../../components/ThePaginate.vue";

import * as queries from "../../../graphql/locus_queries";
import global from "../../../Global.vue";

import ModalDetailJob from "../../../components/modal/ModalDetailJob.vue";
import ModalDetailLocation from "./ModalDetailLocation.vue";
import AwsApi from "../../../Api";
import { onUpdateProcessJob } from "../../../graphql/locus_subscriptions";
import * as mutations from "../../../graphql/locus_mutations";
import { API, graphqlOperation, Storage } from "aws-amplify";

export default {
  props: ["title"],
  components: {
    PageHeader,
    AppTable,
    ButtonNew,
    Paginate,
    "app-form": Form,
    "b-modal": BModal,
    ModalDetailJob,
    ModalDetailLocation,
  },
  data() {
    return {
      jobUseMap: false,
      tenantId: "",
      functionId: 0,
      menuConfigs: [],
      fields: [],
      url: null,
      allData: [],
      currentData: [],
      listLocations: [],
      listWarehouses: [],
      formItems: [],
      form_select_options: {},
      limit: 1000,
      offset: 0,
      paginate: {},
      page: 1,
      count: 30,
      modalDetailInfos: {},
      sortBy: [],
      displayTitle: null,

      warehouse: null,
      selected_row: null,
      selecting: false,

      tableItems: false,
      reader: new FileReader(),
      working: false,
      csvWorking: false,
      deleteWorking: false,
      stockCount: 0,
      updateCsvPath: "",
      selected: null,
      options: [],
      showOverlay: false,
      csvMessage: "",
      msgModal: false,
      work_properties: [],
      csvDetailOffset: 0,
      csvAllData: [],
      csvLimit: 10000,
      subscription: null,
      csvTimer: null,
      form: [],
      tableScannerFlag: false,
      csvKeyData: "",
    };
  },
  async created() {
    this.functionId = parseInt(this.$route.params.functionId);
    this.tenantId = sessionStorage.getItem(global.tenantId);
    await this.fetchAll();
  },
  watch: {
    // call again the method if the route changes
    $route: "routeChangeFetchAll",
  },
  mounted() {
    this.addSubscription();
    this.reader.addEventListener(
      "load",
      function (event) {
        this.showOverlay = true;
        this.csvMessage = this.$t("csv_upload_loading");
        this.uploadCSV(this.reader.result).then((response) => {
          if (response === undefined) {
            this.csvMessage = this.$t("csv_upload_error");
            return;
          }
          var callApiTime = new Date().getTime();
          this.csvTimer = setInterval(() => {
            if (new Date().getTime() - callApiTime >= global.timeout) {
              clearInterval(this.csvTimer);
              this.csvMessage = this.$t("csv_upload_timeout");
            }
          }, 1000);
          this.clearInput();
        });
      }.bind(this),
      false
    );
  },
  unmounted() {
    console.log("unsubscribe");
    if (this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  },
  methods: {
    addSubscription() {
      console.log("subscription");
      this.subscription = API.graphql(
        graphqlOperation(onUpdateProcessJob)
      ).subscribe({
        next: (eventData) => {
          console.log(eventData)
          clearInterval(this.csvTimer);
          if (
            eventData !== undefined &&
            eventData.value !== undefined &&
            eventData.value.data.onUpdateProcessJob !== null
          ) {
            if (eventData.value.data.onUpdateProcessJob.status === 1) {
              if (
                eventData.value.data.onUpdateProcessJob.jobKey ===
                this.csvKeyData
              ) {
                if (this.csvKeyData.includes("public/")) {
                  this.csvKeyData = this.csvKeyData.slice(7);
                }
                Storage.get(this.csvKeyData, { download: true }).then((url) => {
                  this.downloadCSVFun(url);
                  this.csvMessage = this.$t("csv_dl_success");
                });
              } else if (this.updateCsvPath !== "" &&
                eventData.value.data.onUpdateProcessJob.jobKey.indexOf(
                  this.updateCsvPath
                ) !== -1
              ) {
                if (this.$refs.appform) {
                  let f = this.$refs.appform.beforeSubmit();
                  this.defaultFormSearch({
                    form: f,
                  });
                }
                this.csvMessage = this.$t("csv_upload_success");
              }
            } else {
              if (
                eventData.value.data.onUpdateProcessJob.jobKey ===
                this.csvKeyData
              ) {
                this.csvMessage = this.$t("csv_dl_error");
              } else if (this.updateCsvPath !== "" &&
                eventData.value.data.onUpdateProcessJob.jobKey.indexOf(
                  this.updateCsvPath
                ) !== -1
              ) {
                this.csvMessage = this.$t("csv_upload_error");
              }
            }
          }
        },
      });
    },
    async listWarehouse() {
      const response = await AwsApi.graphql(
        {
          query: queries.listWarehouses,
          variables: {
            tenantId: this.tenantId,
          },
        },
        this
      );

      if (response === undefined) {
        return;
      }
      this.listWarehouses = response.data.listWarehouses.items;
    },
    routeChangeFetchAll() {
      this.selected_row = null;
      this.selecting = false;
      this.page = 1;
      this.fetchAll();
    },
    async fetchAll() {
      if (this.$route.query.warehouse === undefined) {
        return;
      }
      this.setUrlData();
      await this.fetchMenuConfigs();
      // search using the form defaults
      if (this.$refs.appform) {
        let f = this.$refs.appform.beforeSubmit();
        this.defaultFormSearch({
          form: f,
        });
      }
    },
    getType(sourceDefine) {
      // get the type
      if (sourceDefine === null || sourceDefine.inputType === null) {
        return "input";
      }
      switch (sourceDefine.inputType) {
        case 1:
          return "multi_select";
        case 2:
          return "date_range";
        case 3:
        case 31:
          return "select";
        case 4:
          return "datetime_range";
        case 5:
          return "checkbox";
        case 6:
          return "checkbox_and_date";
        default:
          return "input";
      }
    },
    async getOption() {
      this.formItems.forEach(async (item) => {
        if (item.sourceDefine === null) {
          return null;
        }
        var options = [];
        if (item.sourceDefine.inputType === 3) {
          this.listLocations = [];
          await this.getListLocations();
          options = this.listLocations.map((item) => ({
            text: item.label,
            value: item.id,
          }));
          options.unshift({
            text: "-- 選択してください --",
            value: null,
          });
        } else if (item.sourceDefine.inputType === 31) {
          this.listWarehouses = [];
          await this.listWarehouse();
          options = this.listWarehouses.map((item) => ({
            text: item.name,
            value: item.id,
          }));
          options.unshift({
            text: "-- 選択してください --",
            value: null,
          });
        } else {
          if (item.sourceDefine.items !== null) {
            options = Object.entries(JSON.parse(item.sourceDefine.items)).map(
              ([key, value]) => ({
                text: value,
                value: key,
              })
            );
          }
        }

        const status = {
          [item.options]: options,
        };
        this.form_select_options = {
          ...this.form_select_options,
          ...status,
        };
      });
    },
    selectRow(row, event) {
      // there is an intermittent problem with double-selects
      // this timer makes sure that additional selects are ignored

      if (this.selecting !== false) return;
      let _this = this;
      setTimeout((_) => {
        _this.selecting = false;
      }, 100);

      this.selecting = true;

      // if (!Array.isArray(row)) {
      //     this.selected_row = row
      //     return
      // }

      // console.log(row[0])
      if (!this.jobUseMap || row === this.selected_row) return;

      if (row === undefined) {
        this.selected_row = null;
        return;
      }
      if (this.selected_row === row) {
        this.selected_row = null;
        return;
      }
      this.selected_row = row;
    },
    unSelectRow(row, event) {
      this.selected_row = null;
    },
    setFormFields(menuConfigs) {
      this.formItems = menuConfigs
        .filter((item) => item.searchOrder > 0)
        .sort((a, b) => a.searchOrder - b.searchOrder)
        .map((item) => {
          return {
            id: item.id,
            title: item.name,
            type: this.getType(item.sourceDefine),
            options: item.source,
            sourceDefine: item.sourceDefine,
          };
        });
      this.formItems.push({
        id: "-1",
        title: "未棚卸",
        type: "checkbox",
        options: "unInventoryFlag",
        sourceDefine: { inputType: 5, items: null },
      });
    },
    async setTableFields(menuConfigs) {
      this.fields = menuConfigs
        .filter((item) => item.listOrder && item.listOrder !== -1)
        .sort((a, b) => a.listOrder - b.listOrder);
    },
    // ----- Data
    setData() {
      this.sortData();
      this.currentData = this.allData.slice(
        (this.page - 1) * this.count,
        this.page * this.count
      );
      this.currentData = this.currentData.map((item) => {
        return {
          ...item,
          locationId:
            item["Stock.locationId"] === undefined
              ? item["JobEpc.locationId"]
              : item["Stock.locationId"],
          "Stock.locationId": item["locationName"],
          "JobEpc.locationId": item["locationName"],
          "Stock.warehouseId": item["warehouseName"],
          "Job.warehouseId": item["warehouseName"],
          "JobEpc.warehouseId": item["warehouseName"],
          "ItemMaster.warehouseId": item["warehouseName"],
        };
      });
      this.paginate = {
        total: this.allData.length,
        per_page: this.count,
        current_page: this.page,
      };
    },
    setUrlData() {
      const url = {
        function_id: this.$route.name,
        menu_id: parseInt(this.$route.params.id),
        warehouse_id: this.$route.query.warehouse,
      };
      return (this.url = url);
    },
    async setWorkProperties(menuConfigs) {
      const response = await AwsApi.graphql(
        {
          query: queries.queryItemDefines,
          variables: {
            sourceTable: {
              eq: "Job",
            },
            tenantId: this.tenantId,
          },
        },
        this
      );
      if (response === undefined) {
        return;
      }
      const filterList = response.data.queryItemDefines.items.filter(
        (item) =>
          !item.source.includes("updatedAt") &&
          !item.source.includes("createdAt") &&
          !item.source.includes("id") &&
          !item.source.includes("status") &&
          !item.source.includes("type")
      );

      this.work_properties = menuConfigs
        .filter((itemA) =>
          filterList.some((itemB) => itemA.source === itemB.source)
        )
        .sort((a, b) => a.listOrder - b.listOrder)
        .map((item) => {
          return {
            name: item.name,
            inputType: item.sourceDefine.inputType,
            items: item.sourceDefine.items,
          };
        });
    },
    async fetchMenuConfigs() {
      const response = await AwsApi.graphql(
        {
          query: queries.getMenu,
          variables: {
            tenantId: this.tenantId,
            id: this.url.menu_id,
          },
        },
        this
      );
      if (response === undefined) {
        return;
      }
      this.displayTitle = response.data.getMenu.name;
      this.deleteStocks = response.data.getMenu.showDeleteButton;
      this.menuConfigs = response.data.getMenu.configs.items;
      this.jobUseMap = true;
      if (this.menuConfigs.length === 0) {
        return;
      }
      this.setFormFields(this.menuConfigs);
      await this.setTableFields(this.menuConfigs);
      this.setWorkProperties(this.menuConfigs);
    },
    updateData() {
      this.getListData();
      this.tableItems = false;
    },
    setRequestParam() {
      const myInit = {
        searchType: 0,
        menuId: this.url.menu_id,
        limit: this.limit,
        offset: this.offset,
        warehouseId: this.url.warehouse_id,
      };
      for (const key in this.form) {
        const hasKey = this.formItems.some((i) => i.options === key);
        const isDate = this.formItems.some(
          (i) =>
            i.sourceDefine &&
            (i.sourceDefine.inputType === 2 ||
              i.sourceDefine.inputType === 4 ||
              i.sourceDefine.inputType === 6) &&
            i.options === key
        );
        if (hasKey) {
          if (this.form[key] !== null && this.form[key] !== "") {
            if (typeof this.form[key] === "boolean") {
              if (this.form[key] === true) {
                myInit[key] = this.form[key];
              }
            } else if (typeof this.form[key] == "string" && !isDate) {
              myInit[key] = "%" + this.form[key] + "%";
            } else {
              if (this.form[key] !== undefined) {
                myInit[key] = this.form[key];
              }
            }
          }
        }
      }
      return myInit;
    },
    async getListData() {
      this.working = true;
      const myInit = this.setRequestParam();
      const response = await AwsApi.graphql(
        {
          query: queries.searchCustomerJobs,
          variables: {
            conditions: JSON.stringify(myInit),
          },
        },
        this
      );
      if (response === undefined) {
        this.working = false;
        return;
      }
      const convertData = response.data.searchCustomerJobs.map((item) => {
        return JSON.parse(item);
      });
      this.allData = this.allData.concat(convertData);
      this.setData();
      this.working = false;
    },

    async getListLocations(nextTokenValue) {
      const response = await AwsApi.graphql(
        {
          query: queries.queryLocationsByWarehouseId,
          variables: {
            tenantId: this.tenantId,
            warehouseId: {
              eq: this.url.warehouse_id,
            },
            nextToken: nextTokenValue,
          },
        },
        this
      );
      if (response === undefined) {
        return;
      }

      this.listLocations = this.listLocations.concat(
        response.data.queryLocationsByWarehouseId.items
      );
      if (response.data.queryLocationsByWarehouseId.nextToken !== null) {
        await this.getListLocations(
          response.data.queryLocationsByWarehouseId.nextToken
        );
      }
    },
    async getListLocationTags(nextTokenValue) {
      const response = await AwsApi.graphql(
        {
          query: queries.queryLocationTagsByWarehouseId,
          variables: {
            tenantId: this.tenantId,
            warehouseId: {
              eq: this.url.warehouse_id,
            },
            nextToken: nextTokenValue,
          },
        },
        this
      );
      if (response === undefined) {
        return;
      }

      this.listLocationTags = this.listLocationTags.concat(
        response.data.queryLocationTagsByWarehouseId.items
      );
      if (response.data.queryLocationTagsByWarehouseId.nextToken !== null) {
        await this.getListLocationTags(
          response.data.queryLocationTagsByWarehouseId.nextToken
        );
      }
    },
    updatePage(page) {
      this.page = page;
      if (this.page * this.count >= this.limit + this.offset) {
        this.offset += this.limit;
        this.getListData();
      } else {
        this.setData();
      }
    },
    updatePaginateCount(value) {
      if (value === "") {
        value = 1;
      }
      // send back to page 1 when changing the count
      this.page = 1;
      this.count = value;
      this.updatePage(this.page);
    },

    // ----- Sort

    sortChange(value) {
      //check if value
      const exist = this.sortBy.map((item) => item.source).includes(value);
      // store the new value if not exist or change direction
      this.sortBy = exist
        ? this.sortBy.map((item) => {
            if (item.source === value) {
              item.direction = item.direction === "asc" ? "desc" : "asc";
            }
            return item;
          })
        : [
            {
              source: value,
              direction: "asc",
            },
          ];
      this.setData();
    },

    sortData() {
      if (this.sortBy.length === 0) {
        return;
      }
      this.allData.sort((a, b) => {
        const sort = this.sortBy[0];

        const valA = a[sort.source];
        const valB = b[sort.source];

        if (valA == null && valB == null) return 0;
        if (valA == null) return sort.direction === "asc" ? 1 : -1;
        if (valB == null) return sort.direction === "asc" ? -1 : 1;

        if (typeof valA === "number") {
          if (sort.direction === "asc") {
            return valA < valB ? -1 : 1;
          } else {
            return valA > valB ? -1 : 1;
          }
        } else {
          if (sort.direction === "asc") {
            return valA.localeCompare(valB);
          } else {
            return valB.localeCompare(valA);
          }
        }
      });
    },
    setModalCreate(item) {
      this.modalDetailInfos = item
        ? this.getModalItemInfosPerPage(item)
        : infos;
      this.tableItems = true;
    },
    getModalItemInfosPerPage(item) {
      const input = {};
      if (item["Job.id"]) {
        input["Job.id"] = item["Job.id"];
      }
      return input;
    },
    setModalItem(item) {},
    async uploadCSV(csvStr) {
      if (this.tableScannerFlag) {
        this.updateCsvPath = `${this.tenantId}/stock/${this.url.warehouse_id}/${
          this.modalDetailInfos["Job.id"]
        }_${global.formatLongDate()}`;
      } else {
        this.updateCsvPath = `${this.tenantId}/job/${this.url.warehouse_id}/${
          this.url.menu_id
        }/${global.formatLongDate()}`;
      }
      if (/\.(xlsx)$/i.test(this.$refs.file.files[0].name)) {
        this.updateCsvPath = this.updateCsvPath + ".xlsx";
        return await Storage.put(this.updateCsvPath, this.$refs.file.files[0]);
      } else if (/\.(xlsm)$/i.test(this.$refs.file.files[0].name)) {
        this.updateCsvPath = this.updateCsvPath + ".xlsm";
        return await Storage.put(this.updateCsvPath, this.$refs.file.files[0]);
      } else {
        this.updateCsvPath = this.updateCsvPath + ".csv";
        return await Storage.put(this.updateCsvPath, csvStr, {
          contentType: "text/csv",
        });
      }
    },
    backToMainPage() {
      this.tableItems = false;
      this.selected_row = null;
    },
    downloadCSV() {
      this.csvDetailOffset = 0;
      this.csvKeyData = "";
      this.showOverlay = true;
      this.csvMessage = this.$t("csv_dl_loading");
      this.getDetailListData().then(() => {
        var callApiTime = new Date().getTime();
        this.csvTimer = setInterval(() => {
          if (new Date().getTime() - callApiTime >= global.timeout) {
            clearInterval(this.csvTimer);
            this.csvMessage = this.$t("csv_dl_timeout");
          }
        }, 1000);
      });
    },
    async getDetailListData() {
      var myInit = {};
      for (const key in this.form) {
        const hasKey = this.formItems.some((i) => i.options === key);
        const isDate = this.formItems.some(
          (i) =>
            i.sourceDefine &&
            (i.sourceDefine.inputType === 2 ||
              i.sourceDefine.inputType === 4 ||
              i.sourceDefine.inputType === 6) &&
            i.options === key
        );
        if (hasKey) {
          if (this.form[key] !== null && this.form[key] !== "") {
            //myInit.body[key] = this.form[key]
            if (typeof this.form[key] === "boolean") {
              if (this.form[key] === true) {
                myInit[key] = this.form[key];
              }
            } else if (typeof this.form[key] == "string" && !isDate) {
              myInit[key] = "%" + this.form[key] + "%";
            } else {
              myInit[key] = this.form[key];
            }
          }
        }
      }
      myInit = {
        ...myInit,
        menuId: this.url.menu_id,
        limit: this.csvLimit,
        offset: this.csvDetailOffset,
        warehouseId: this.url.warehouse_id,
        searchType: 2,
        fileFlag: true,
      };
      const response = await AwsApi.graphql(
        {
          query: queries.searchCustomerJobs,
          variables: {
            conditions: JSON.stringify(myInit),
          },
        },
        this
      );
      if (response === undefined) {
        return;
      }
      if (response.data.searchCustomerJobs.length !== 0) {
        const convertData = response.data.searchCustomerJobs.map((item) => {
          return JSON.parse(item);
        });
        this.csvKeyData = convertData[0];
        console.log(this.csvKeyData);
      }
    },
    async downloadCSVFun(result) {
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(result.Body);
      downloadLink.download = this.csvKeyData.split("/").pop();
      downloadLink.click();
    },

    resetPage() {
      this.page = 1;
      this.allData = [];
      this.offset = 0;
      this.paginate = {
        total: 1,
        per_page: 1,
        current_page: 1,
      };
      this.selected_row = null;
    },
    async submitFormSearch(form) {
      this.resetPage();
      this.form = form.form;
      this.updateData();
      this.form_select_options = {
        ...this.form_select_options,
      };
    },
    async defaultFormSearch(form) {
      this.resetPage();
      this.form = form.form;
      await this.getOption();
      this.updateData();
    },
    clearInput() {
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    },
    handleFileUpload(bool) {
      this.tableScannerFlag = bool;
      this.file = this.$refs.file.files[0];

      /*
              Check to see if the file is not empty.
            */
      if (this.file) {
        if (/\.(csv)$/i.test(this.file.name)) {
          this.reader.readAsText(this.file);
        }
        if (/\.(xlsx|xlsm)$/i.test(this.file.name)) {
          this.reader.readAsArrayBuffer(this.file);
        }
      }
    },
    msgModalOk() {
        this.msgModal = true
    },
    msgModalCancel() {
        this.msgModal = false
    },
    finishJob() {
        var updateJob = {
            tenantId: this.tenantId,
            id: this.modalDetailInfos["Job.id"],
            menuId: this.url.menu_id,
            status: 2
        }
        AwsApi.graphql({
            query: mutations.updateJob,
            variables: {
                updateJob: updateJob
            }
        }, this).then((response) => {
          console.log(response)
            if (response === undefined) {
                return
            }
        }).catch(error => console.log(error))
        this.msgModalCancel()
    },
  },
};
</script>

<style scoped>
.upload_csv_btn {
  cursor: default;
  padding: 0 8px;
}

.upload_csv_label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin: 4px 0;
}
</style>
